import React, { useState } from "react";
// material ui
import { makeStyles } from "@mui/styles";
import { LoadingButton } from "@mui/lab";
import { CircularProgress } from "@mui/material";
import {
  DataGrid,
  GridRow,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarDensitySelector,
} from "@mui/x-data-grid";
import { Button, Typography } from "@mui/material";
// icons
import AddCircleIcon from "@mui/icons-material/AddCircle";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { theme } from "../../theme/Theme";
// ---------------------------------------------------------------------------------

const useStyles = makeStyles(() => ({
  dataGridStyle: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.primary.light,
    },
  },
}));

// ----------------------------------------------------------------------------------

const CustomRow = (props) => {
  const classes = useStyles();
  const { className, index, ...other } = props;
  return <GridRow index={index} className={classes.dataGridStyle} {...other} />;
};

//   ------------------------------------------------------------------------------------

const GridToolbarAddButton = (props) => {
  return (
    <Button
      variant="text"
      startIcon={<AddCircleIcon />}
      onClick={() => props.handleClick()}
    >
      New
    </Button>
  );
};

// ------------------------------------------------------------------------------------------

const GridToolbarEditButton = (props) => {
  return (
    <Button
      variant="text"
      startIcon={<EditIcon />}
      onClick={() => props.handleClick()}
    >
      Edit
    </Button>
  );
};

// ---------------------------------------------------------------------------------------------

const GridToolbarDeleteButton = (props) => {
  return (
    <Button
      variant="text"
      startIcon={<DeleteIcon />}
      onClick={() => props.handleClick()}
    >
      Delete
    </Button>
  );
};

// ----------------------------------------------------------------------------------------------

export default function MuiDataGrid(props, { handleClick }) {
  const classes = useStyles();

  const [selectedRow, setSelectedRow] = useState([]);
  const [selectCheckRowCount, setSelectCheckRowCount] = useState([]);
  const getRowHeight = () => {
    return props?.isRowHeight ? 100 : 52;
  };

  let filterArr = [];
  for (let key of selectCheckRowCount) {
    props?.data?.rows?.find((keyTwo) => {
      if (key === keyTwo.id) {
        filterArr.push(keyTwo);
      }
    });
  }

  let findingApprovedOne =
    filterArr?.length > 0 &&
    filterArr?.some((dataThree) => !dataThree?.isClientApproved)

  // console.log(props?.data.rows)
  // console.log(selectedRow)
  // console.log(filterArr)
  // console.log(findingApprovedOne)
  // console.log(selectCheckRowCount)

  const CustomToolbar = () => {
    console.log(selectedRow)
    return (
      <GridToolbarContainer style={{ display: "block" }}>
        {props.viewAdd && (
          <GridToolbarAddButton handleClick={props.setShowAdd} />
        )}
        {!props?.toolbarColumn && <GridToolbarColumnsButton />}
        {!props?.toolbarFilter && <GridToolbarFilterButton />}
        {!props?.toolbarDensity && <GridToolbarDensitySelector />}
        {!props?.toolbarExport && <GridToolbarExport />}

        { props?.setShowEdit &&selectedRow.length === 1 && selectCheckRowCount.length === 1 && (
          <GridToolbarEditButton handleClick={props.setShowEdit} />
        )}
        {/* {!props.isShowDelete && selectedRow.length === 1 && (
          <GridToolbarDeleteButton handleClick={props.setShowDelete} />
        )} */}

        {selectedRow.length > 0 && findingApprovedOne && (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              alignItems: "center",
              // background:"red"
            }}
          >
            <Typography
              sx={{ m: 2, fontSize: "1.125rem" }}
              gutterBottom
              variant="h9"
              component="div"
            >
              Mark Selected As :
            </Typography>


            <LoadingButton
              //  disabled={params.row.isClientApproved ? "disabled" : null}
              loadingIndicator={<CircularProgress size={20} />}
              loading={props?.loading}
              type="submit"
              size="small"
              variant="contained"
              onClick={props?.onMultiPrivateClick}
            //   className={classes.btnSubmit}
            >
              Private
            </LoadingButton>

            {/* <Button
              sx={{}}
              onClick={props?.onMultiPrivateClick}
              size="small"
              variant="contained"
              color="primary"
            >
              Private
            </Button> */}

            <LoadingButton
              sx={{ ml: 2 }}
              //  disabled={params.row.isClientApproved ? "disabled" : null}
              loadingIndicator={<CircularProgress size={20} />}
              loading={props?.loading}
              type="submit"
              size="small"
              variant="contained"
              onClick={props?.onMultiPublicClick}
            //   className={classes.btnSubmit}
            >
              Public
            </LoadingButton>


            {/* <Button
              sx={{ ml: 2 }}
              onClick={props?.onMultiPublicClick}
              size="small"
              variant="contained"
            >
              Public
            </Button> */}
            <LoadingButton
              sx={{ ml: 2 }}
              //  disabled={params.row.isClientApproved ? "disabled" : null}
              loadingIndicator={<CircularProgress size={20} />}
              loading={props?.loading}
              type="submit"
              size="small"
              variant="contained"
              onClick={props?.onMultiApproveClick}
            //   className={classes.btnSubmit}
            >
              Approve
            </LoadingButton>



            {/* <Button
              sx={{ ml: 2 }}
              onClick={props?.onMultiApproveClick}
              size="small"
              variant="contained"
            >
              Approve
            </Button> */}
          </div>
        )}
      </GridToolbarContainer>
    );
  };

  return (
    // <div
    //   style={{
    //     height: props.height ? props.height : 500,
    //     width: "100%",

    //     "&.superAppTheme": {
    //       "&:nth-of-type(odd)": {
    //         bgcolor: (theme) => theme.palette.primary.light,
    //       },
    //     },
    //   }}
    // >
    //   <DataGrid
    //     autoHeight
    //     {...props.data}
    //     components={{
    //       Toolbar: CustomToolbar,

    //       Row: CustomRow,
    //     }}
    //     onSelectionModelChange={(ids) => {
    //       const selectedRowData =
    //         props.data.rows.length > 0 &&
    //         props.data.rows.filter(
    //           (row) => ids.length > 0 && row.id === ids[0]
    //         );
    //        props.select(selectedRowData);
    //        setSelectedRow(selectedRowData);
    //     }}
    //   />
    // </div>

    <div
      style={{
        width: "100%",
        "&.superAppTheme": {
          "&:nth-of-type(odd)": {
            bgcolor: (theme) => theme.palette.primary.light,
          },
        },
      }}
    >
      <DataGrid
        autoHeight
        checkboxSelection={true}
        {...props.data}
        components={{
          Toolbar: CustomToolbar,
          Row: CustomRow,
        }}
        getRowHeight={getRowHeight}
        onSelectionModelChange={(ids) => {
          // const selectedRowsData =
          //   props.data.rows.length > 0 &&
          //   props.data.rows.filter(
          //     (row) => ids.length > 0 && row.id === ids[0]
          //   );


          let filterArr = ids.filter((dataTwo) =>
            props.data.rows?.find(
              (dataThree) =>
                dataThree?.id === dataTwo && !dataThree?.isClientApproved
            )
          );
          setSelectCheckRowCount(ids);


          props?.selectArr(filterArr);

          const selectedRowData =
            props.data.rows.length > 0 &&
            props.data.rows.filter(
              (row) => ids.length > 0 && row.id === ids[0]
            );
          props.select(selectedRowData);
          setSelectedRow(selectedRowData);

          // setSelectedRow(ids);
        }}
      />
    </div>
  );
}
