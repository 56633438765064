import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

// routes
import {
  GET_ENGAGEMENT_DETAIL,
  GET_CLIENT_ENGAGEMENT_STAGE,
  GET_CURRENT_MENTOR_NAME,
  GET_CLIENT_DOCUMENT,
  UPLOAD_CLIENT_DOCUMENT,
  DELETE_CLIENT_DOCUMENT
} from "../../utils/routes";

// ---------------------------------------------------------------------

export const createEngagementDocuments = createAsyncThunk(
  "engagement/createEngagementDocuments",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(UPLOAD_CLIENT_DOCUMENT, data, {
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getEngagementDocuments = createAsyncThunk(
  "engagement/getEngagementDocuments",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(GET_CLIENT_DOCUMENT, data, {
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteEngagementDocuments = createAsyncThunk(
  "customer/deleteEngagementDocuments",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.delete(GET_ENGAGEMENT_DETAIL, {
        data,
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

// export const getEngagementDocuments = createAsyncThunk(
//   "engagement/getEngagementDocuments",
//   async () => {
//     return axios.get(`${GET_CLIENT_DOCUMENT}`, { withCredentials: true });
//   }
// );

export const getEngagementStage = createAsyncThunk(
  "engagement/getEngagementStage",
  async () => {
    return axios.get(GET_CLIENT_ENGAGEMENT_STAGE, { withCredentials: true });
  }
);

export const getCurrentMentorName = createAsyncThunk(
  "dashboard/getCurrentMentor",
  async () => {
    return axios.get(GET_CURRENT_MENTOR_NAME, { withCredentials: true });
  }
);

export const updateEnagagementDetail = createAsyncThunk(
  "engagement/updateEngagement",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.put(GET_ENGAGEMENT_DETAIL, data, {
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteEngagementDetail = createAsyncThunk(
  "customer/deleteCustomer",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.delete(GET_ENGAGEMENT_DETAIL, {
        data,
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
export const deleteClientDocument = createAsyncThunk(
  "customer/deleteClientDocument",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.delete(DELETE_CLIENT_DOCUMENT, {
        data,
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

const engagementSlice = createSlice({
  name: "engagement",
  initialState: {
    loading: false,
    message: "",
    errMessage: "",
    engagementsDocumentsData: [],
    engagementStageData: [],
    mentorCurrentDetails: {},
    selectedData: {},
  },
  reducers: {
    resetMessage: (state) => {
      state.message = "";
    },
    resetErrMessage: (state) => {
      state.errMessage = "";
    },
    rowSelect: (state, action) => {
      state.selectedData = action?.payload.length > 0 && action?.payload[0];
    },
  },
  extraReducers: {
    [createEngagementDocuments.pending]: (state, action) => {
      state.loading = true;
    },
    [createEngagementDocuments.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [createEngagementDocuments.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },

    [getEngagementDocuments.pending]: (state, action) => {
      state.loading = true;
    },
    [getEngagementDocuments.fulfilled]: (state, action) => {
      console.log(action?.payload?.data);
      state.loading = false;
      state.engagementsDocumentsData = action?.payload?.data;
    },
    [getEngagementDocuments.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.data?.message;
    },

    [getCurrentMentorName.pending]: (state, action) => {
      state.loading = true;
    },
    [getCurrentMentorName.fulfilled]: (state, action) => {
      console.log(action?.payload?.data);
      state.loading = false;
      state.mentorCurrentDetails = action?.payload?.data;
    },
    [getCurrentMentorName.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.data?.message;
    },
    [getEngagementStage.pending]: (state, action) => {
      state.loading = true;
    },
    [getEngagementStage.fulfilled]: (state, action) => {
      console.log(action?.payload?.data);
      state.loading = false;
      state.engagementStageData = action?.payload?.data;
    },
    [getEngagementStage.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.data?.message;
    },
    [updateEnagagementDetail.pending]: (state, action) => {
      state.loading = true;
    },
    [updateEnagagementDetail.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [updateEnagagementDetail.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },
    [deleteEngagementDetail.pending]: (state, action) => {
      state.loading = true;
    },
    [deleteEngagementDetail.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [deleteEngagementDetail.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },
    [deleteClientDocument.pending]: (state, action) => {
      state.loading = true;
    },
    [deleteClientDocument.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [deleteClientDocument.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },

  },
});

export const { resetMessage, resetErrMessage, rowSelect } =
  engagementSlice.actions;
export default engagementSlice.reducer;
