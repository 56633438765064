import React, { useEffect, useState } from 'react'
import { MuiDataGrid, MuiDataGridForLeadership, MuiTooltip } from '../..';
import ConfirmDialog from "../ConfirmDialog";
import { Box, Grid, Stack, Button, IconButton, TextField } from '@mui/material';
import { connect, useDispatch, useSelector } from 'react-redux';
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import AddAttachments from './AddAttachments';
import DeleteIcon from "@mui/icons-material/Delete";
import FileCopyIcon from '@mui/icons-material/FileCopy';
import { BASE_URL } from '../../../utils/config';
import CancelIcon from "@mui/icons-material/Cancel";

import Notifier from "../../../components/common/Notifier";
import ErrorNotifier from "../../../components/common/ErrorNotifier";


// import { getMentorCoachingDocumentsByClient, deleteMentorCoachingDocuments } from "../../features/coaching_focus/coachingSlice";
// import CommonErrorMessage from '../../../../common/error-message/CommonErrorMessage';


import ViewPdf from '../pdf_view/ViewPdf';
// import {
//   HCM_EMP_DOCUMENT_VIEW,
//   HCM_EMP_DOCUMENT_VIEW_DOWNLOAD,
//   HCM_EMP_VIEW_DOWNLOAD_DOCUMENTS,

// } from '../../../../../utils/routes';

// import { HCM_API } from '../../../../../utils/config';

// import { getDocuments } from '../../../../../features/hcm/my_profile/documentsSlice';

// --------------------------------------------------------------------------------------------------------------------------------
export const attachmentTypes = [{
  attachmentType: "Policy"
}, {
  attachmentType: "Guideline"
}, {
  attachmentType: "Procedure"
},]

const Attachments = (props) => {
  // try {

  const [showAdd, setShowAdd] = useState(false);
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });

  const [clientName, setClientName] = useState(
    JSON.parse(localStorage.getItem("clientid"))
  );
  const [showAttachment, setShowAttachment] = useState(false);
  const [url, setUrl] = useState("");
  const [openFile, setOpenFile] = useState(false);
  const dispatch = useDispatch();

  const {
    engagementsDocumentsData
  } = useSelector((state) => ({
    ...state.clientDocuments,
  }));

  // console.log(props)

  const data = {
    rows: props?.viewData ? props?.viewData : [],
    columns: [
      {
        field: "documentName",
        headerName: "Name",
        renderHeader: () => <strong>File Type</strong>,
        width: 300,
        valueGetter: (params) => params?.row?.uploadDocumentType?.documentName || "Past Impact Update for Leader"

      },
      // {
      //   field: "attachmentType",
      //   headerName: "Type",
      //   renderHeader: () => <strong>Type</strong>,
      //   width: 200,
      // },
      {
        field: "fileName",
        headerName: "File Name",
        renderHeader: () => <strong>File Name</strong>,
        width: 600,
        renderCell: (params) => (
          <div style={{ display: "flex", alignItems: "center" }}>
            {(
              <FileCopyIcon color="primary" style={{ marginRight: 8, }} />
            )}
            <span
              style={{

                textDecoration: "underline",
                color: "#003957",
                cursor: "pointer",
              }}
            >
              {params.value}
            </span>
          </div>
        ),
      },
      {
        field: "Delete",
        type: "actions",
        width: 40,
        minWidth: 40,
        maxWidth: 40,
        renderCell: (params) => {
          if (params?.row?.
            uploadDocumentType?.key === props?.fixedKey
          ) {  // Ensure Delete is shown only when isSfixedKeyhowDelete is true

            return (
              <MuiTooltip title="Delete" placement="bottom">
                <IconButton
                  onClick={() =>
                    setConfirmDialog({
                      isOpen: true,
                      title: "Are you sure to delete this record?",
                      subTitle: "You can't undo this operation",
                      onConfirm: () => handleDelete(params?.row),
                    })
                  }
                >
                  <DeleteIcon sx={{ color: "red !important" }} />
                </IconButton>
              </MuiTooltip>
            );
          }
        },
      },
    ],
  };
  // console.log("Data",data)
  const handleSetConfirmDialog = (obj) => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: obj.isOpen,
    });
  };

  const handleCloseConfirmDialog = () => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });
  };

  const handleDelete = (params) => {
    try {
      const data = {
        id: params?.id,
      };

      dispatch(

        props?.deleteDocument(data)
      ).then((res) => {
        if (res?.payload?.data?.ok) {
          setTimeout(() => {

            handleCloseConfirmDialog();
            props?.getDocuments && props.clientEngagementId ?
              dispatch(
                props?.getDocuments({ clientEngagementId: props.clientEngagementId }
                )) :
              dispatch(
                props?.getDocuments({ nominationId: props.nominationId }
                ))
            dispatch(props?.resetMessage())
          }, 2000);
        }
      });
    } catch (err) {
      console.log(err);
      dispatch(props?.resetErrMessage())
    }
  }

  useEffect(() => {
    props?.getDocuments && props.clientEngagementId ?
      dispatch(
        props?.getDocuments({ clientEngagementId: props.clientEngagementId }
        )) :
      dispatch(
        props?.getDocuments({ nominationId: props.nominationId }
        ))

  }, [props.clientEngagementId, props.nominationId])



  // try {
  return (
    <>
      {/* <Stack flexDirection="row" justifyContent="space-between">

            <Box sx={{ width: "100%" }} className="background-color">
              <Grid container sx={{ alignItems: "center" }}>
               
                <Grid item md={12} lg={12} xs={12} xl={8} sm={8}>
                  <h6
                    className="center bold pt-2 "
                    style={{
                      color: "black",
                    }}
                  >
                    Attachments
                  </h6>
                </Grid>
                <Grid item md={2} lg={2} xs={2} xl={2} sm={2}></Grid>
              </Grid>
            </Box>
          </Stack> */}



      <MuiDataGrid
        isAttachmentFormat={true}
        hideFooter={props?.hideFooter}
        viewAdd={!props?.disableAdd ? true : false}
        viewUpdate={!props?.disableUpdate ? true : false}
        data={data}
        height="38vh"
        isShowEdit={true}

        select={(data) => {
          if (data && data?.length > 0) {
            setUrl(data[0]);
            setOpenFile(true);
          }
        }}
        setShowAdd={() => setShowAdd(true)}
        toolbarFilter={true}
        toolbarColumn={true}
        toolbarDensity={true}
        toolbarExport={true}
        isShowDelete={true}
      // setShowEdit={false}
      // hideFooter={true}
      />

      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={(obj) => handleSetConfirmDialog(obj)}
      />
      {showAdd && (
        <AddAttachments
          selectedDocumentType={props?.selectedDocumentType}
          nominationId={props?.nominationId}
          loading={props?.loading}
          format={props?.format}
          typeKey={props?.typeKey}
          clientEngagementId={props?.clientEngagementId}
          attachmentType={props?.attachmentType}
          createDocument={props?.createDocument}
          getDocuments={props?.getDocuments}
          resetMessage={props?.resetMessage}
          resetErrMessage={props?.resetErrMessage}
          // dynamicPath={props?.dynamicPath}
          showAdd={showAdd}
          close={() => setShowAdd(false)} />
      )}



      {openFile && (
        <ViewPdf
          showAttachment={openFile}
          setShowAttachment={setOpenFile}
          havingViewUrl={`${BASE_URL}${props?.dynamicPath}${url?.id}`}
        // variableDownload={`${HCM_EMP_VIEW_DOWNLOAD_DOCUMENTS}/${url.id}`}
        />
        // downloadUrl={VIEW_BACKLOG_ATTACHMENTS_DOWNLOAD}
      )}



      {props?.errMessage && <ErrorNotifier message={props?.errMessage} />}
      {props?.message && <Notifier message={props?.message} />}


    </>
  );
  //   } catch (err) {
  //     console.log(err);
  //     return <CommonErrorMessage />;
  //   }
  // } catch (err) {
  //   console.log(err);
  //   return <CommonErrorMessage />;
  // }
}

const mapStateToProps = (state) => ({
  empManagement: state.empManagement,
  documents: state.documents,
  projectBacklog: state.projectBacklog,
  backlogAttachments: state.backlogAttachments,
  permissions: state.permissions,
  auth: state.auth,
});

export default connect(mapStateToProps, {

})(Attachments);