import { useFormik } from "formik";
import React, { useState } from "react";
import { connect, useDispatch } from "react-redux";

import * as Yup from "yup";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  FormGroup,
  IconButton,
  Grid,
  Stack,
  TextField
} from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import { PopUpModel, MuiAutocomplete, FormSubmitButton, CommonCurdPopUp } from "../..";
import { createEngagementDocuments, getEngagementDocuments, resetErrMessage, resetMessage } from '../../../features/dashboard/engagementDocumentSlice';
import { CoPresentOutlined } from "@mui/icons-material";
// import { fileUploadPlan, getMentorCoachingDocumentsByClient,resetErrMessage,resetMessage } from "../../features/coaching_focus/coachingSlice";

// import {
//   textChecker,
// } from "../../../../common/form_validation/regularExpressions";
// import {
//   textFieldErr,
// } from "../../../../common/form_validation/validationErrMessages";
// import { timeoutCall } from "../../../../common/timeoutCall/timeoutCall";
// import CommonErrorMessage from "../../../../common/error-message/CommonErrorMessage";
// import {
//   addRcPolicyAttachments,
//   getRcPolicyAttachments,
// } from "../../../../../../features/project/projectConfig/rc360/rcPolicyAttachmentsSlice";

// import {
//   uploadBacklogAttachments,
//   getBacklogAttachments,
//   deleteBacklogAttachments,
// } from "../../../../../../features/project/requirementsManagement/projectBacklog/backlogAttachmentsSlice";



// -------------------------------------------------------------------------------------------

// import {addleadAttachment, getleadAttachment } from "../../../../../features/crm/leadAttachmentSlice";

const validationSchema = Yup.object({
  attachmentName: Yup.string()
    .required("Attachment Name is required")

    // .matches(textChecker, textFieldErr)
    .test(
      "noEmptySpaces",
      " Field cannot be only empty spaces",
      function (value) {
        return value === undefined || value.trim().length > 0;
      }
    )
    .max(100, "Attachment Name Should Not Exceed 100 Characters"),
  // attachmentType: Yup.string()
  //   // .matches(textChecker, textFieldErr)
  //   .test(
  //     "noEmptySpaces",
  //     " Field cannot be only empty spaces",
  //     function (value) {
  //       return value === undefined || value.trim().length > 0;
  //     }
  //   )
  //   .max(50, "Attachment Name Should Not Exceed 50 Characters"),
  fileName: Yup.string().required("File Name is required").nullable(),
});
// -------------------------------------------------------------------------------------------

const AddAttachments = (props) => {
  const [clientName, setClientName] = useState(
    JSON.parse(localStorage.getItem("clientid"))
  );
  const dispatch = useDispatch();

  console.log(props?.typeKey)

  const formik = useFormik({
    initialValues: {
      attachmentName: props?.attachmentType ? props?.attachmentType : "",
      attachmentType: "",
      fileName: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      console.log("values", values)
      try {
        const formData = new FormData();
        // formData.append("document", values?.fileName)
        // formData.append("documentName", values?.fileName?.name)

        // formData.append(
        //   "fileType",
        //   values?.attachmentType
        // );
        props?.clientEngagementId && formData.append("clientEngagementId", props?.clientEngagementId);
        formData.append(`${props?.typeKey}`, values?.fileName);



        dispatch(props?.createDocument(formData)).then((res) => {
          if (res?.payload?.data?.ok) {
            setTimeout(() => {
              dispatch(props?.resetMessage());
              dispatch(props?.resetErrMessage());

              dispatch(props?.getDocuments({ clientEngagementId: props.clientEngagementId }))
              props.close()
            }, 2000);
          }
          if (res?.payload?.error) {
            setTimeout(() => {
              dispatch(props?.resetErrMessage());
            }, 3000);
          }
        })

          .
          catch((err) => {
            console.log("err ", err);
          });
      } catch (err) {
        console.log(err);
      }
    },
  });

  const handleFileChange = (event) => {
    const fileName = event.target.files[0];
    formik.setFieldValue("fileName", fileName);
  };
  const handleClear = (event) => {
    formik.setFieldValue("fileName", "");
  };

  console.log(formik?.errors)
  console.log(formik?.values)

  // try {
  return (
    <PopUpModel open={props.showAdd}>
      <Card
        className="add-pop-up "
        style={{ marginLeft: "22%", width: "53.25rem" }}
      >
        <CardHeader
          title={
            <h4 style={{ textAlign: "center" }} className="center bold pt-2 header-font-size">
              Add Attachment
            </h4>
          }
          action={
            <IconButton aria-label="cancel" onClick={() => props.close()}>
              <CancelIcon color="red" />
            </IconButton>
          }
          sx={{
            paddingBottom: "0px!important",
          }}
        />
        <CardContent>
          <form noValidate>
            <Grid container>
              <Grid item xs={12} sm={12} md={1}></Grid>
              <Grid item xs={12} sm={12} md={10}>
                <Stack sx={{ textAlign: "end", fontSize: "0.8rem" }}>Accepted Formats({props?.format})</Stack>
                <TextField
                  disabled
                  label="Attachment Name"
                  type="text"
                  name="attachmentName"
                  fullWidth={true}
                  required={true}
                  margin="normal"
                  id="attachmentName"
                  value={formik.values.attachmentName}
                  onChange={formik.handleChange}
                  onBlur={formik?.handleBlur}
                  error={
                    formik.touched.attachmentName &&
                    Boolean(formik.errors.attachmentName)
                  }
                  helperText={
                    formik.touched.attachmentName &&
                    formik.errors.attachmentName
                  }
                  sx={{
                    "& .MuiInputLabel-asterisk": {
                      color: "red", // Set the asterisk color to red
                    },
                  }}
                />
                {/* <TextField
                  label="File Type"
                  type="text"
                  name="attachmentType"
                  fullWidth={true}
                  required={false}
                  margin="normal"
                  id="attachmentType"
                  value={formik.values.attachmentType}
                  onChange={formik.handleChange}
                  onBlur={formik?.handleBlur}
                  error={
                    formik.touched.attachmentType &&
                    Boolean(formik.errors.attachmentType)
                  }
                  helperText={
                    formik.touched.attachmentType &&
                    formik.errors.attachmentType
                  }
                /> */}

              </Grid>
              <Grid item xs={12} sm={12} md={1}></Grid>

              <Grid item xs={12} sm={12} md={1}></Grid>
              <Grid item xs={3} sm={3} md={3} lg={3}>
                <FormGroup className="file-browse-btn-div">
                  <label style={{ padding: "0.5rem" }} className="btn label-sm file-browse-btn">
                    Select File *
                    <TextField
                      key={12345}
                      type="file"
                      name="l1MenuIcon"
                      style={{
                        display: "none",
                        marginTop: "27px",
                        marginLeft: "-28px",
                      }}
                      className="form-control-sm file-input"
                      onChange={handleFileChange}
                      required={true}
                      size="small"
                      value=""
                    />
                  </label>
                </FormGroup>
              </Grid>
              <Grid
                item
                xs={7}
                sm={7}
                md={7}
                lg={7}
                style={{ marginTop: "1.2rem", marginLeft: "1.5rem" }}
              >
                {formik.values?.fileName?.name ?? ""}
                {formik.values?.fileName?.name && (
                  <IconButton onClick={handleClear}>
                    <CancelIcon color="error" />
                  </IconButton>
                )}
              </Grid>
              <Grid item xs={12} sm={12} md={1}></Grid>

              <Grid item xs={12} sm={12} md={1}></Grid>
            </Grid>
            <Box textAlign="center" sx={{ mt: "20px" }}>
              <FormSubmitButton
                variant="contained"
                color="primary"
                onClick={formik.handleSubmit}
                loading={props?.backlogAttachments?.loading}
              >
                Submit

              </FormSubmitButton>
            </Box>
          </form>
        </CardContent>
      </Card>
    </PopUpModel>
  );
  // } catch (err) {
  //   console.log(err);
  //   return <CommonErrorMessage />;
  // }
};

const mapStateToProps = (state) => ({
  crmLeads: state.crmLeads,
  crmLeadAttachment: state.crmLeadAttachment,
  backlogAttachments: state.backlogAttachments,
  projectBacklog: state.projectBacklog,
});

export default connect(mapStateToProps, {

})(AddAttachments);
