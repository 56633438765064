import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

// routes
import {
  GET_ENGAGEMENT_DETAIL,
  GET_CLIENT_ENGAGEMENT_STAGE,
  GET_CURRENT_MENTOR_NAME,
  GET_CLIENT_DOCUMENT,
  UPLOAD_CLIENT_DOCUMENT,
  CHR_ENGAGEMENT_UPLOAD_DOCUMENTS,
  CHR_ENGAGEMENT_GET_DOCUMENTS,
  CHR_ENGAGEMENT_DELETE_DOCUMENTS
} from "../../../utils/routes";

// ---------------------------------------------------------------------

export const createChrEngagementDocuments = createAsyncThunk(
  "engagement/createChrEngagementDocuments",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(CHR_ENGAGEMENT_UPLOAD_DOCUMENTS, data, {
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getChrEngagementDocuments = createAsyncThunk(
  "engagement/getChrEngagementDocuments",
  async (data) => {
    return axios.post(CHR_ENGAGEMENT_GET_DOCUMENTS,data, {
      withCredentials: true,
    });
  }
);

export const deleteCHRDocuments = createAsyncThunk(
  "engagement/deleteCHRDocuments",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.delete(CHR_ENGAGEMENT_DELETE_DOCUMENTS, {
        data,
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getEngagementStage = createAsyncThunk(
  "engagement/getEngagementStage",
  async () => {
    return axios.get(GET_CLIENT_ENGAGEMENT_STAGE, { withCredentials: true });
  }
);

export const getCurrentMentorName = createAsyncThunk(
  "dashboard/getCurrentMentor",
  async () => {
    return axios.get(GET_CURRENT_MENTOR_NAME, { withCredentials: true });
  }
);

export const updateEnagagementDetail = createAsyncThunk(
  "engagement/updateEngagement",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.put(GET_ENGAGEMENT_DETAIL, data, {
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteEngagementDetail = createAsyncThunk(
  "customer/deleteCustomer",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.delete(GET_ENGAGEMENT_DETAIL, {
        data,
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

const engagementSlice = createSlice({
  name: "engagement",
  initialState: {
    loading: false,
    documentMessage: "",
    documentErrMessage: "",
    engagementsDocumentsData: [],
    engagementStageData: [],
    mentorCurrentDetails: {},
    selectedData: {},
  },
  reducers: {
    resetMessage: (state) => {
      state.documentMessage = "";
    },
    resetErrMessage: (state) => {
      state.documentErrMessage = "";
    },
    rowSelect: (state, action) => {
      state.selectedData = action?.payload.length > 0 && action?.payload[0];
    },
  },
  extraReducers: {
    [createChrEngagementDocuments.pending]: (state, action) => {
      state.loading = true;
    },
    [createChrEngagementDocuments.fulfilled]: (state, action) => {
      state.loading = false;
      state.documentMessage = action?.payload?.data?.message;
    },
    [createChrEngagementDocuments.rejected]: (state, action) => {
      state.loading = false;
      state.documentErrMessage = action?.payload?.message;
    },

    [getChrEngagementDocuments.pending]: (state, action) => {
      state.loading = true;
    },
    [getChrEngagementDocuments.fulfilled]: (state, action) => {
      console.log(action?.payload?.data);
      state.loading = false;
      state.engagementsDocumentsData = action?.payload?.data;
    },
    [getChrEngagementDocuments.rejected]: (state, action) => {
      state.loading = false;
      state.documentErrMessage = action?.payload?.data?.message;
    },

    [getCurrentMentorName.pending]: (state, action) => {
      state.loading = true;
    },
    [getCurrentMentorName.fulfilled]: (state, action) => {
      console.log(action?.payload?.data);
      state.loading = false;
      state.mentorCurrentDetails = action?.payload?.data;
    },
    [getCurrentMentorName.rejected]: (state, action) => {
      state.loading = false;
      state.documentErrMessage = action?.payload?.data?.message;
    },
    [getEngagementStage.pending]: (state, action) => {
      state.loading = true;
    },
    [getEngagementStage.fulfilled]: (state, action) => {
      console.log(action?.payload?.data);
      state.loading = false;
      state.engagementStageData = action?.payload?.data;
    },
    [getEngagementStage.rejected]: (state, action) => {
      state.loading = false;
      state.documentErrMessage = action?.payload?.data?.message;
    },
    [updateEnagagementDetail.pending]: (state, action) => {
      state.loading = true;
    },
    [updateEnagagementDetail.fulfilled]: (state, action) => {
      state.loading = false;
      state.documentMessage = action?.payload?.data?.message;
    },
    [updateEnagagementDetail.rejected]: (state, action) => {
      state.loading = false;
      state.documentErrMessage = action?.payload?.message;
    },
    [deleteCHRDocuments.pending]: (state, action) => {
      state.loading = true;
    },
    [deleteCHRDocuments.fulfilled]: (state, action) => {
      state.loading = false;
      state.documentMessage = action?.payload?.data?.message;
    },
    [deleteCHRDocuments.rejected]: (state, action) => {
      state.loading = false;
      state.documentErrMessage = action?.payload?.message;
    },
  },
});

export const { resetMessage, resetErrMessage, rowSelect } =
  engagementSlice.actions;
export default engagementSlice.reducer;
