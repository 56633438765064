import React, { useState, useEffect } from "react";
// components
import Layout from "../../../layout/Layout";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import Paper from "@mui/material/Paper";
import { makeStyles } from "@mui/styles";
import StepLabel from "@mui/material/StepLabel";
import { MuiDataGrid } from "../../../components";
import { FormSubmitButton } from "../../../components";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { PageHeader } from "@excoleadershipui/pionweblib";
import { DataGrid, GridToolbar, GridRow } from "@mui/x-data-grid";
import { useSelector, useDispatch } from "react-redux";
import MenuItem from "@mui/material/MenuItem";
import { MuiAutocomplete } from "../../../components";
import { getToggleUserData } from "../../../features/auth/authSlice";
import {
  getClientForHr,
  resetMessage,
  getClientForManager,
  getManagerCoachingFocusById,
  getManagerPurpose,
} from "../../../features/manager/coachingFocus/coachingManagerSlice";
import { GridCellExpand } from "../../../components";
import { Grid } from "@mui/material";
import { format } from "date-fns";

const steps = ["Na", "Matching", "Chemistry", "Preparation", "Mentoring"];

const Assesment = () => {
  const [accountName, setAccountName] = useState("");
  const [clientName, setClientName] = useState(
    JSON.parse(localStorage.getItem("clientid"))
  );
  const [allValue, setAllValue] = useState("");
  const [holder, setHolder] = useState("");
  const dispatch = useDispatch();

  const {
    loading,
    message,
    errMessage,
    leadershipStrengthData,
    getManagerPurposeData,
    areaFocusData,
    getAllClientData,
    selectedData,
  } = useSelector((state) => ({ ...state.ManagerCoachingFocus }));

  const { toggleUserData } = useSelector((state) => ({
    ...state.auth,
  }));


  const handleChange = (e) => {
    let clinetId = e.target.value.split(",")[0];
    let account = e.target.value.split(",")[1];
    let holderName = e.target.value.split(",")[2];
    setAllValue(e.target.value);
    setClientName(clinetId);
    setHolder(holderName);
    setAccountName(account);
    dispatch(getManagerCoachingFocusById(clinetId));
  };

  function renderCellExpand(params) {
   
    return (
      <GridCellExpand
        value={params.value || ""}
        width={params.colDef.computedWidth}
      />
    );
  }

  const mentorGetDevValue = (params) => {

    return params?.row?.developmentAreas
      ?.developmentAreaName;
  };

  const mentorGetCoachingValue = (params) => {
    return params?.row?.developmentAreasCoachingFocus?.
      developmentAreaCoachingFocusName
};
const getCreatedOnDate = (params) => {
  return params.row.createdOn
      ? format(new Date(params.row.createdOn), "MM-dd-yyyy")
      : "";
};

const getUpdatedOnDate = (params) => {
  return params.row.updatedOn
    ? format(new Date(params.row.updatedOn), "MM-dd-yyyy")
    : ""

};

  const data = {
    rows: areaFocusData,
    columns: [
      {
        field: "developmentArea",
        headerName: "Leadership Dimension",
        width: 200,
        renderCell: renderCellExpand,
        valueGetter: mentorGetDevValue,
      },
     
        {
          field: "focus",
          headerName: "Sub-Category",
          width: 200,
          renderCell: renderCellExpand,
          valueGetter: mentorGetCoachingValue,
          hide :   toggleUserData?.length > 0 &&
          toggleUserData[0]?.subCategory ? false :  true
         
        },

      {
        field: "otherDevelopmentAreaCoachingFocus",
        headerName: "Focus Area",
        width: 150,
        // valueGetter: mentorGetCoachingValue,
        renderCell: renderCellExpand,
      },
        {
          field: "expectedOutcome",
          headerName: "Expected Outcome",
          width: 300,
          renderCell: renderCellExpand,
          hide :   toggleUserData?.length > 0 &&
          toggleUserData[0]?.expectedOutcome ? false :  true
        },
        {
          field: "anticipatedStartDate",
          headerName: "Anticipated Start Date",
          width: 300,
          renderCell: renderCellExpand,
          hide :   toggleUserData?.length > 0 &&
          toggleUserData[0]?.anticipatedStartDate ? false :  true
        },

      {
        field: "isDateAdded",
        headerName: "Date Added",

        width: 150,
        valueGetter: getCreatedOnDate
      },
      {
        field: "dateUpdated",
        headerName: "Date Updated",
        width: 150,

        valueGetter: getUpdatedOnDate,
      },
    ],
  };

  const dataTwo = {
    rows: getManagerPurposeData,
    columns: [
      {
        width: 900,
        field: "purposeOfEngagment",
        headerName: "Engagement Purpose Description",
        renderCell: renderCellExpand,
      },
      {
        field: "isDateAdded",
        headerName: "Date Added",
        width: 150,
        valueGetter: getCreatedOnDate,

      },
      {
        field: "dateUpdated",
        headerName: "Date Updated",
        width: 150,
        valueGetter: getUpdatedOnDate,

      },

    ],
  };

  useEffect(() => {
    clientName && dispatch(getManagerCoachingFocusById(clientName?.id));
    dispatch(getManagerPurpose(clientName?.id));
  }, [clientName]);

  useEffect(() => {
    dispatch(getClientForManager());
  }, []);

  useEffect(() => {
   
    dispatch(getToggleUserData(clientName?.id));
  }, []);
  return (
    <Layout>
      <Stack sx={{ marginTop: "1rem" }}>
      <PageHeader variant="pageHeader">
      Purpose of Engagement for{" "}
            {clientName &&
              `${clientName?.firstName} ${clientName?.lastName}`}{" "}
          </PageHeader>
        {/* </Grid> */}
      </Stack>

      

      <Stack sx={{ marginTop: "0.5rem" }}>
        <MuiDataGrid    toolbarColumn={true}
              toolbarFilter={true}
              toolbarDensity={true}
              toolbarExport={true}
              isRowHeight={true}   data={dataTwo} />
      </Stack>
      {clientName && (
        <Stack
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "row",
          fontFamily: "arial",
          marginTop: "1rem",
          paddingBottom: "1rem",
        }}
      >
       <PageHeader variant="pageHeader">
          {" "}
          Focus Areas for {clientName?.firstName} {clientName?.lastName}{" "}
        </PageHeader>
      </Stack>

      )}
      {clientName && (
        <Stack sx={{ marginTop: "1rem" }}>
          <MuiDataGrid data={data} />
        </Stack>
      )}
    </Layout>
  );
};

export default Assesment;
