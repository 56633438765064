import logo from "./logo.svg";
import { CssBaseline } from "@mui/material";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";
import ExcoLogin from "./components/excoLogin";
import { ConfirmAccount } from "./components";
import {
  SideBarMenu,
  HrEngagements,
  DashboardExco,
  Assesment,
  Coaching,
  Rating,
  ClientReports,
  MyProfile,
  HrDash,


  HrExec,
  HrAsses,
  HrRatin,
  HrReports,
  HrCoaching,
  ManagerDashboard,
  ManagerEnagagement,
  ManagerCoachingStatus,
  ManagerAssesment,
  ManagerRatings,
  ManagerReports,
  AdminhrDashboard,
  AdminhrEngagementDetails,
  AdminhrFocusArea,
  AdminhrLeadership,
  AdminhrReports,
  AdminhrNomination,

 PrivatefeedbackChr,
 PrivatefeedbackHr,
 PrivatefeedbackManager
} from "./pages";

import { ForgetPassword, ForgotPassReset } from "./components";
import PageNotFound from "./pages/page_not_found/PageNotFound";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import axios from "axios";
import { CUSTOMER_NAME } from "./utils/routes";
import { getCustomerUserId } from "./features/auth/authSlice";

// material ui

function App() {
  const dispatch = useDispatch();
  useEffect(() => {
    console.log("useeffect calling");
    dispatch(getCustomerUserId())
      .then((res) => {
        console.log(res?.payload?.data?.micrositeUiTitle);
        if (res?.payload?.data?.micrositeUiTitle) {
          document.title = res?.payload?.data?.micrositeUiTitle;
        } else {
          document.title = "ExCo Client";
        }
      })
      .catch((err) => {
        console.log(err);
      });
    // axios.get(`${CUSTOMER_NAME}`, { withCredentials: true })
    // .then(response => {
    //   console.log(response)
    //   document.title = `ExCo - ${response.data}` })
    //   .catch(error=> document.title='ExCo Client')
  }, []);
  return (
    <>
      <CssBaseline />
      <Router>
        <Routes>
          <Route path="/" element={<ExcoLogin />} />
          <Route path="/activation" element={<ConfirmAccount />} />
          <Route path="forgot-password" element={<ForgetPassword />} />
          <Route path="forgot-password/reset" element={<ForgotPassReset />} />
          <Route path="/exco" element={<SideBarMenu />}>
            <Route path="my-profile" element={<MyProfile />} />
            <Route path="dashboard" element={<DashboardExco />} />
            <Route path="leadership-profile" element={<Assesment />} />
            <Route path="focus-area" element={<Coaching />} />
            <Route path="client/impact-updates" element={<Rating />} />
            <Route path="client/progress-summary" element={<ClientReports />} />
            <Route path="hr/dashboard" element={<HrDash />} />
            <Route path="hr/engagement-details" element={<HrEngagements />} />
            <Route path="hr/engagements" element={<HrEngagements />} />
            <Route path="hr/executive" element={<HrExec />} />
            <Route path="hr/leadership-profile" element={<HrAsses />} />
            <Route path="hr/progress-summary" element={<HrReports />} />
            <Route path="hr/impact-updates" element={<HrRatin />} />
            <Route path="hr/focus-area" element={<HrCoaching />} />
            <Route
              path="hr/private-feedback"
              element={<PrivatefeedbackHr />}
            />
            {/* <Route path="hr/reports" element={<HrReports/>} /> */}
            <Route path="manager/dashboard" element={<ManagerDashboard />} />
            <Route path="manager/engagement-details" element={<ManagerEnagagement />} />
            <Route path="manager/leadership-profile" element={<ManagerAssesment />} />
            <Route
              path="manager/focus-area"
              element={<ManagerCoachingStatus />}
            />
              <Route
              path="manager/private-feedback"
              element={<PrivatefeedbackManager />}
            />
            <Route path="manager/impact-updates" element={<ManagerRatings />} />
            <Route path="manager/progress-summary" element={<ManagerReports />} />

            <Route path="chr/dashboard" element={<AdminhrDashboard />} />
            <Route path="chr/engagement-details" element={<AdminhrEngagementDetails />} />
            <Route path="chr/leadership-profile" element={<AdminhrLeadership />} />
            <Route path="chr/focus-area" element={<AdminhrFocusArea />} />
            <Route
              path="chr/private-feedback"
              element={< PrivatefeedbackChr />}
            />
            <Route path="chr/progress-summary" element={<AdminhrReports />} />
            <Route path="chr/nomination" element={<AdminhrNomination />} />
          </Route>
          <Route path="E_EXCO_CUSTOMER_CLIENT_NOT_FOUND" element={<PageNotFound />} />
        </Routes>
      </Router>
    </>
  );
}

export default App;
