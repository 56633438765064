import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

// routes
import {
  GET_REPORTS_AREA_FOCUS,
  GET_REPORTS_ENGAGEMENT_STATUS,
  GET_ENGAGEMENT_DETAIL,
  GET_ALL_CLIENTS_CHR,
  CHR_ENGAGEMENT_REPORTS,
  CHR_LATEST_SUMMARY,
  CHR_PREVIOUS_SUMMARY_DATA,
  CHR_PREVIOUS_SUMMARY_DATA_BY_ID,
  CHR_GENERATE_SUMMARY,
  CHR_VIEW_DOCUMENTS_IMPACT_SUMMARY,
} from "../../../utils/routes";

// ---------------------------------------------------------------------

export const getAreaOfFocus = createAsyncThunk(
  "reports/getAreaOfFocus",
  async () => {
    return axios.get(GET_REPORTS_AREA_FOCUS, { withCredentials: true });
  }
);

export const getAllClientForCHR = createAsyncThunk(
  "client/getAllClient",
  async () => {
    return axios.get(GET_ALL_CLIENTS_CHR, { withCredentials: true });
  }
);

export const getViewDocumentsImpactSummaryCHR = createAsyncThunk(
  "reports/getViewDocumentsImpactSummaryCHR",
  async (data) => {
    return axios.post(CHR_VIEW_DOCUMENTS_IMPACT_SUMMARY,data, {
      withCredentials: true,
    });
  }
);

export const getAllReportsChrSummary = createAsyncThunk(
  "chr/getSummaryRecords",
  async (data, { rejectWithValue }) => {
    let body = {
      excoCustomerUserId: data,
    };

    const response = await axios.post(CHR_LATEST_SUMMARY, body, {
      withCredentials: true,
    });
    return response;
  }
);

export const getGenerateChrSummary = createAsyncThunk(
  "reports/geGenerateChrSummary",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(CHR_GENERATE_SUMMARY, data, {
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

// export const getFullEnagegementsReports = createAsyncThunk(
//   "reports/getFullReportsCHR",
//   async () => {
//     return axios.get(CHR_ENGAGEMENT_REPORTS, { withCredentials: true });
//   }
// );

export const getFullEnagegementsReports = createAsyncThunk(
  "reports/getFullReportsCHR",
  async (data, { rejectWithValue }) => {
    let body = {
      excoCustomerUserId: data,
    };
    try {
      const response = await axios.post(CHR_ENGAGEMENT_REPORTS, body, {
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getEngagementNotes = createAsyncThunk(
  "reports/getEngagement",
  async () => {
    return axios.get(GET_REPORTS_ENGAGEMENT_STATUS, { withCredentials: true });
  }
);

export const getPreviousSummaryForCHR = createAsyncThunk(
  "summary/previousForCHR",
  async (data, { rejectWithValue }) => {
    let body = {
      excoCustomerUserId: data,
    };
    try {
      const response = await axios.post(CHR_PREVIOUS_SUMMARY_DATA, body, {
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const submitPreviousSummaryForCHR = createAsyncThunk(
  "rating/clientOutcomes",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(CHR_PREVIOUS_SUMMARY_DATA_BY_ID, data, {
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateEnagagementDetail = createAsyncThunk(
  "engagement/updateEngagement",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.put(GET_ENGAGEMENT_DETAIL, data, {
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteEngagementDetail = createAsyncThunk(
  "customer/deleteCustomer",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.delete(GET_ENGAGEMENT_DETAIL, {
        data,
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

const engagementSlice = createSlice({
  name: "reports",
  initialState: {
    loading: false,
    message: "",
    errMessage: "",
    areaFocusData: [],
    engagementFocusData: [],
    getAllClientData: [],
    previousSummaryDataForCHR: [],
    generateChrSummaryData: {},
    selectedData: {},
    dateErrorMessageData: "",
  },
  reducers: {
    resetMessage: (state) => {
      state.message = "";
    },
    resetErrMessage: (state) => {
      state.errMessage = "";
    },
    rowSelect: (state, action) => {
      state.selectedData = action?.payload.length > 0 && action?.payload[0];
    },
    emptyObject: (state, action) => {
      state.generateChrSummaryData = {};
    },
    dateErrorMessage: (state, action) => {
      state.dateErrorMessageData = action.payload;
    },
  },
  extraReducers: {
    [getAreaOfFocus.pending]: (state, action) => {
      state.loading = true;
    },
    [getAreaOfFocus.fulfilled]: (state, action) => {
      state.loading = false;
      state.areaFocusData = action?.payload?.data;
    },
    [getAreaOfFocus.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.data?.message;
    },

    [getAllReportsChrSummary.pending]: (state, action) => {
      state.loading = true;
    },
    [getAllReportsChrSummary.fulfilled]: (state, action) => {
      state.loading = false;
      state.generateChrSummaryData = action?.payload?.data;
    },
    [getAllReportsChrSummary.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },

    [getGenerateChrSummary.pending]: (state, action) => {
      state.loading = true;
    },
    [getGenerateChrSummary.fulfilled]: (state, action) => {
      state.loading = false;
      state.generateChrSummaryData = action?.payload?.data;
    },
    [getGenerateChrSummary.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
      state.generateChrSummaryData = {};
    },

    [submitPreviousSummaryForCHR.pending]: (state, action) => {
      state.loading = true;
    },
    [submitPreviousSummaryForCHR.fulfilled]: (state, action) => {
      state.loading = false;
      state.generateChrSummaryData = action?.payload?.data;
    },
    [submitPreviousSummaryForCHR.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },
    [getPreviousSummaryForCHR.pending]: (state, action) => {
      state.loading = true;
    },
    [getPreviousSummaryForCHR.fulfilled]: (state, action) => {
      state.loading = false;
      state.previousSummaryDataForCHR = action?.payload?.data;
    },
    [getPreviousSummaryForCHR.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },

    [getFullEnagegementsReports.pending]: (state, action) => {
      state.loading = true;
    },
    [getFullEnagegementsReports.fulfilled]: (state, action) => {
      state.loading = false;
      state.generateChrSummaryData = action?.payload?.data;
    },
    [getFullEnagegementsReports.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
      state.generateChrSummaryData = [];
    },
    [getAllClientForCHR.pending]: (state, action) => {
      state.loading = true;
    },
    [getAllClientForCHR.fulfilled]: (state, action) => {
      state.loading = false;
      state.getAllClientData = action?.payload?.data;
    },
    [getAllClientForCHR.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.data?.message;
    },

    [getViewDocumentsImpactSummaryCHR.pending]: (state, action) => {
      state.loading = true;
    },
    [getViewDocumentsImpactSummaryCHR.fulfilled]: (state, action) => {
      state.loading = false;
      state.areaFocusData = action?.payload?.data;
    },
    [getViewDocumentsImpactSummaryCHR.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.data?.message;
    },

    [getEngagementNotes.pending]: (state, action) => {
      state.loading = true;
    },
    [getEngagementNotes.fulfilled]: (state, action) => {
      state.loading = false;
      state.engagementFocusData = action?.payload?.data;
    },
    [getEngagementNotes.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.data?.message;
    },
    [updateEnagagementDetail.pending]: (state, action) => {
      state.loading = true;
    },
    [updateEnagagementDetail.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [updateEnagagementDetail.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },
    [deleteEngagementDetail.pending]: (state, action) => {
      state.loading = true;
    },
    [deleteEngagementDetail.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [deleteEngagementDetail.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },
  },
});

export const {
  resetMessage,
  resetErrMessage,
  dateErrorMessage,
  rowSelect,
  emptyObject,
} = engagementSlice.actions;
export default engagementSlice.reducer;
