import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

// routes
import {
  GET_ENGAGEMENT_DETAIL,
  GET_CLIENT_ENGAGEMENT_STAGE,
  GET_CURRENT_MENTOR_NAME,
  GET_CLIENT_DOCUMENT,
  UPLOAD_CLIENT_DOCUMENT,
  MANAGER_ENGAGEMENT_DOCUMENTS_GET,
} from "../../../utils/routes";

// ---------------------------------------------------------------------

export const createManagerEngagementDocuments = createAsyncThunk(
  "engagement/createManagerEngagementDocuments",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(UPLOAD_CLIENT_DOCUMENT, data, {
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getManagerEngagementDocuments = createAsyncThunk(
  "engagement/getManagerEngagementDocuments",
  async () => {
    return axios.get(`${MANAGER_ENGAGEMENT_DOCUMENTS_GET}`, {
      withCredentials: true,
    });
  }
);

export const getCurrentMentorName = createAsyncThunk(
  "dashboard/getCurrentMentor",
  async () => {
    return axios.get(GET_CURRENT_MENTOR_NAME, { withCredentials: true });
  }
);

export const updateEnagagementDetail = createAsyncThunk(
  "engagement/updateEngagement",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.put(GET_ENGAGEMENT_DETAIL, data, {
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteEngagementDetail = createAsyncThunk(
  "customer/deleteCustomer",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.delete(GET_ENGAGEMENT_DETAIL, {
        data,
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

const engagementSlice = createSlice({
  name: "engagement",
  initialState: {
    loading: false,
    message: "",
    errMessage: "",
    engagementsDocumentsData: [],
    engagementStageData: [],
    mentorCurrentDetails: {},
    selectedData: {},
  },
  reducers: {
    resetMessage: (state) => {
      state.message = "";
    },
    resetErrMessage: (state) => {
      state.errMessage = "";
    },
    rowSelect: (state, action) => {
      state.selectedData = action?.payload.length > 0 && action?.payload[0];
    },
  },
  extraReducers: {
    [createManagerEngagementDocuments.pending]: (state, action) => {
      state.loading = true;
    },
    [createManagerEngagementDocuments.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [createManagerEngagementDocuments.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },

    [getManagerEngagementDocuments.pending]: (state, action) => {
      state.loading = true;
    },
    [getManagerEngagementDocuments.fulfilled]: (state, action) => {
      console.log(action?.payload?.data);
      state.loading = false;
      state.engagementsDocumentsData = action?.payload?.data;
    },
    [getManagerEngagementDocuments.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.data?.message;
    },

    [getCurrentMentorName.pending]: (state, action) => {
      state.loading = true;
    },
    [getCurrentMentorName.fulfilled]: (state, action) => {
      console.log(action?.payload?.data);
      state.loading = false;
      state.mentorCurrentDetails = action?.payload?.data;
    },
    [getCurrentMentorName.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.data?.message;
    },

    [updateEnagagementDetail.pending]: (state, action) => {
      state.loading = true;
    },
    [updateEnagagementDetail.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [updateEnagagementDetail.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },
    [deleteEngagementDetail.pending]: (state, action) => {
      state.loading = true;
    },
    [deleteEngagementDetail.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [deleteEngagementDetail.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },
  },
});

export const { resetMessage, resetErrMessage, rowSelect } =
  engagementSlice.actions;
export default engagementSlice.reducer;
