import React,{useEffect} from "react";
import { TextField, Autocomplete } from "@mui/material";
import { styled } from "@mui/material/styles";

// --------------------------------------------------------------------

// Styled wrapper for scoped styles

const CustomAutocompleteWrapper = styled('div')({  // red color for all asterisk throug out all the component 
  '.MuiInputLabel-asterisk': {
    color: 'red',
  },
});


const MuiAutocomplete = ({
  name,
  options,
  setValue,
  value,
  optionValue,
  optionSecValue,
  label,
  required,
  error,
  helperText,
  innerLevel,
  isNominate
}) => {

 
  // const autoChange = (event,newValue) =>{
  //   if(!value){
  //     fullFunc()
  //   }
  //  return setValue(name, newValue)
  
  // }

  return (
    <CustomAutocompleteWrapper>
    <Autocomplete
      name={name}
      options={options}
      getOptionLabel={innerLevel? 
        (option) =>  `${option?.excoCustomerUser?.excoCustomerUserDetail?.firstName} ${option?.excoCustomerUser?.excoCustomerUserDetail?.lastName}`
        :(option) => optionSecValue ? 
        `${option[optionValue]}-${option[optionSecValue]}` : option[optionValue]
      
      }
      value={value ? value : null}
      // onChange={autoChange}
      onChange={(event, newValue) => setValue(name, newValue)}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          required={required}
          error={error}
          helperText={helperText}
        />
      )}
     sx={{ marginTop: isNominate ?  "1rem":"0rem"}}
      disabled={options.length === 0}
    />
   </CustomAutocompleteWrapper>
  );
};

export default MuiAutocomplete;
