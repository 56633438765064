import {
  createSlice,
  createAsyncThunk,
  combineReducers,
  configureStore,
} from "@reduxjs/toolkit";
import axios from "axios";
// routes
import {
  EMAIL_VERIFY,
  LOGIN_ACCOUNT,
  LOGOUT_ACCOUNT,
  CREATE_ACCOUNT,
  GET_ACCOUNTS,
  ACTIVATE_ACCOUNT,
  GET_ALL_ROLES,
  IS_LOGGED_IN,
  CURRENT_ROLES,
  LOGGED_IN_ROLES,
  UPLOAD_PROFILE_PIC,
  CHECKING_CLIENT_IS_APPROVED,
  GET_TOGGLE_USER_DATA,
  LOGGED_IN_MICROSOFT,
  LOGGED_IN_GOOGLE,
  GET_OAUTH_PROVIDERS
} from "../../utils/routes";

import { CUSTOMER_LOGO, BASE_URL, MICRO_SITE_URL } from "../../utils/config";
import { getToogleData } from "../ratings/ratingSlice";

// ---------------------------------------------------------------------------------

export const verifyEmail = createAsyncThunk(
  "auth/verifyEmail",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(EMAIL_VERIFY, data, {
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getToggleUserData = createAsyncThunk(
  "toggle/toogleUser",
  async (data) => {
    return axios.get(`${  GET_TOGGLE_USER_DATA}/${data}`, { withCredentials: true });
  }
);

export const getOauthProviders = createAsyncThunk(
  "toggle/getOauthProviders",
  async (data) => {
    return axios.get(`${GET_OAUTH_PROVIDERS}`, { withCredentials: true });
  }
);



export const login = createAsyncThunk(
  "auth/login",
  async (data, { rejectWithValue }) => {
    
    let authRQ = data.rq;
    delete data.rq;
    try {
      const response = await axios.post(LOGIN_ACCOUNT, data, {
        headers: {
          Authorization: `Bearer ${authRQ}`,
          "Content-Type": "application/json",
        },
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const isLoggedIn = createAsyncThunk("login/isLoggedIn", async () => {
  return axios.get(IS_LOGGED_IN, { withCredentials: true });
});

export const isLoggedMicrsoft = createAsyncThunk("login/isLoggedMicrosoft", async (data) => {
  return axios.post(LOGGED_IN_MICROSOFT,data, { withCredentials: true });
});

export const isLoggedGoogle = createAsyncThunk("login/isLoggedGoogle", async (data) => {
  return axios.post(LOGGED_IN_GOOGLE,data, { withCredentials: true });
});

export const isLoggedVerifyRoles = createAsyncThunk(
  "login/isLoggedInVerifyRoles",
  async () => {
    return axios.get(LOGGED_IN_ROLES, { withCredentials: true });
  }
);

export const isCheckingRoles = createAsyncThunk("auth/checkRole", async () => {
  return axios.get(CURRENT_ROLES, { withCredentials: true });
});

export const logout = createAsyncThunk(
  "auth/logout",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(LOGOUT_ACCOUNT, data, {
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getAccount = createAsyncThunk(
  "auth/getAccount",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(GET_ACCOUNTS, { withCredentials: true });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const activateAccount = createAsyncThunk(
  "auth/activateAccount",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(ACTIVATE_ACCOUNT, data, {
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getAllRoles = createAsyncThunk(
  "microsite/roles",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(GET_ALL_ROLES, {
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const uploadProfilePic = createAsyncThunk(
  "auth/uploadProfilePic",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(UPLOAD_PROFILE_PIC, data, {
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const saveToLocalStorage = createAsyncThunk(
  "auth/saveLocalStorage",
  async (state) => {
  
    try {
      localStorage.setItem("state", JSON.stringify(state));
    } catch (e) {
      console.error(e);
    }
  }
);

export const loadFromLocalStorage = createAsyncThunk(
  "auth/loadFromLocal",
  async () => {
    try {
      const stateStr = localStorage.getItem("state");
      return stateStr ? JSON.parse(stateStr) : undefined;
    } catch (e) {
      console.error(e);
      return undefined;
    }
  }
);

export const getCustomerUserId = createAsyncThunk(
  "customerId/auth",
  async (_, { rejectWithValue }) => {
  
    try {
      const result = await axios.get(
        `${MICRO_SITE_URL}/microsite/${CUSTOMER_LOGO}`,
        {
          withCredentials: true,
        }
      );
      return result;
    } catch (err) {
      rejectWithValue(err.response.data);
    }
  }
);



export const getClientIsApprpved = createAsyncThunk(
  "clientId/clientApproved",
  async (_, { rejectWithValue }) => {
  
    try {
      const result = await axios.get(CHECKING_CLIENT_IS_APPROVED, {
        withCredentials: true,
      });
      return result;
    } catch (err) {
      rejectWithValue(err.response.data);
    }
  }
);

// export const saveToLocalStorage = () => {
//   try {
//     localStorage.setItem('state', JSON.stringify("chandra"));
//   } catch (e) {
//     console.error(e);
//   }
// };

// const loadFromLocalStorage = () => {
//   try {
//     const stateStr = localStorage.getItem('state');
//     return stateStr ? JSON.parse(stateStr) : undefined;
//   } catch (e) {
//     console.error(e);
//     return undefined;
//   }
// };

// const rootReducer = combineReducers({
//   list: listReducer
// });

const persistedStore = loadFromLocalStorage();
persistedStore();

// const store = configureStore(rootReducer, persistedStore);

// store.subscribe(() => {
//   saveToLocalStorage(store.getState());
// });

// ---------------------------------------------------------------------------------

const authSlice = createSlice({
  name: "auth",
  initialState: {
    loading: false,
    loginToken: "",
    rq: "",
    data: [],
    accountsData: [],
    getAllRolesData: [],
    rolesApiData: [],
    allRoleData: [],
    customerTitleData: [],
    clientApprovedData: [],
    oauthProvidersData:[],
    toggleUserData:[],
    rolesErrMessage: "",
    customerCustomMessage: "",
    localStoreRoleData: "",
    custMessage: "",
    logginRolesData: "",
    rolesData: "",
    message: "",
    errMessage: "",
  },
  reducers: {
    resetMessage: (state) => {
      state.message = "";
    },
    resetErrMessage: (state) => {
      state.errMessage = "";
    },

    localStoreRoleFunc: (state, action) => {
      state.localStoreRoleData = action.payload;
    },

    rolesErrMessageFunc: (state) => {
      state.rolesErrMessage = "Roles should not be empty";
    },

    rolesErrMessageEmptyFunc: (state) => {
      state.rolesErrMessage = "";
    },

    customerLoggedInFunc: (state) => {
      state.customerCustomMessage = state.custMessage;
    },

    customerLoggedInEmptyFunc: (state) => {
      state.customerCustomMessage = "";
    },

    isEmptyApprovedData: (state, action) => {
      state.clientApprovedData = [];
    },
  },
  extraReducers: {
    [getOauthProviders.pending]: (state, action) => {
      state.loading = true;
    },
    [getOauthProviders.fulfilled]: (state, action) => {
      state.loading = false;
      state.oauthProvidersData = action?.payload?.data;
    },
    [getOauthProviders.rejected]: (state, action) => {
      state.oauthProvidersData =[]
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },


    [getClientIsApprpved.pending]: (state, action) => {
      state.loading = true;
    },
    [getClientIsApprpved.fulfilled]: (state, action) => {
      state.loading = false;
      state.clientApprovedData = action?.payload?.data;
    },
    [getClientIsApprpved.rejected]: (state, action) => {
      state.clientApprovedData =[]
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },

    [getToggleUserData.pending]: (state, action) => {
      state.loading = true;
    },
    [getToggleUserData.fulfilled]: (state, action) => {
      state.loading = false;
      state.toggleUserData = action?.payload?.data;
    },
    [getToggleUserData.rejected]: (state, action) => {
      state.toggleUserData =[]
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },

    [uploadProfilePic.pending]: (state, action) => {
      state.loading = true;
    },
    [uploadProfilePic.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [uploadProfilePic.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },

    [getCustomerUserId.pending]: (state, action) => {
      state.loading = true;
    },
    [getCustomerUserId.fulfilled]: (state, action) => {
      state.loading = false;
      state.customerTitleData = action?.payload?.data;
    },
    [getCustomerUserId.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },

    [getAccount.pending]: (state, action) => {
      state.loading = true;
    },
    [getAccount.fulfilled]: (state, action) => {
      state.loading = false;
      state.customerTitleData = action?.payload?.data;
    },
    [getAccount.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    [isLoggedVerifyRoles.pending]: (state, action) => {
      state.loading = true;
    },
    [isLoggedVerifyRoles.fulfilled]: (state, action) => {
      state.loading = false;
      state.logginRolesData = action?.payload?.data;
    },
    [isLoggedVerifyRoles.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    [isCheckingRoles.pending]: (state, action) => {
      state.loading = true;
    },
    [isCheckingRoles.fulfilled]: (state, action) => {
      state.loading = false;
      state.rolesApiData = action?.payload?.data;
    },
    [isCheckingRoles.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    [isLoggedIn.pending]: (state, action) => {
      state.loading = true;
    },
    [isLoggedIn.fulfilled]: (state, action) => {
      state.loading = false;
      state.loggedIn = action?.payload?.data;
    },
    [isLoggedIn.rejected]: (state, action) => {
      state.loading = true;
    },
    [getAllRoles.pending]: (state, action) => {
      state.loading = true;
    },
    [getAllRoles.fulfilled]: (state, action) => {
      state.loading = false;
      state.getAllRolesData = action?.payload?.data;
    },
    [getAllRoles.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    [verifyEmail.pending]: (state, action) => {
      // state.loading = true;
    },
    [verifyEmail.fulfilled]: (state, action) => {
      state.loading = false;
      state.loginToken = action?.payload?.data?.data?.loginToken;
      state.rq = action?.payload?.data?.data?.rq;
      state.message = action?.payload?.data?.message;
    },
    [verifyEmail.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },
    [login.pending]: (state, action) => {
      state.loading = true;
    },
    [login.fulfilled]: (state, action) => {
     
      state.rolesData =
        action?.payload?.data?.data?.getExcoCustomerUserRoles[0].role;
      state.allRoleData = action?.payload?.data?.data?.getExcoCustomerUserRoles;
      state.loading = false;
      state.custMessage = action?.payload?.data?.message;
      state.message = action?.payload?.data?.message;
    },
    [login.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },
    [logout.pending]: (state, action) => {
      state.loading = true;
    },
    [logout.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [logout.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },
    [activateAccount.pending]: (state, action) => {
      state.loading = true;
    },
    [activateAccount.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [activateAccount.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },
  },
});

export const {
  resetMessage,
  resetErrMessage,
  rolesErrMessageFunc,
  rolesErrMessageEmptyFunc,
  customerLoggedInEmptyFunc,
  customerLoggedInFunc,
  localStoreRoleFunc,
  isEmptyApprovedData
} = authSlice.actions;

export default authSlice.reducer;
