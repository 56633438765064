import React from "react";
// material ui
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  Button,
  IconButton,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { NotListedLocation } from "@mui/icons-material";

// ------------------------------------------------------------------------

const useStyles = makeStyles((theme) => ({
  dialog: {
    padding: theme.spacing(2),
    position: "absolute",
    top: theme.spacing(5),
  },
  dialogTitle: {
    textAlign: "center",
  },
  dialogContent: {
    textAlign: "center",
  },
  dialogAction: {
    justifyContent: "center",
  },
  titleIcon: {
    backgroundColor: theme.palette.grey.light,
    color: theme.palette.grey.main,
    "&:hover": {
      backgroundColor: theme.palette.grey.light,
      cursor: "default",
    },
    "& .MuiSvgIcon-root": {
      fontSize: "8rem",
    },
  },
}));

//   -------------------------------------------------------------------------------

const ConfirmDialog = (props) => {
  const { confirmDialog, setConfirmDialog } = props;

  const classes = useStyles();
  return (
    <Dialog open={confirmDialog.isOpen} classes={{ paper: classes.dialog }}>
      <DialogTitle className={classes.dialogTitle}>
        <IconButton disableRipple className={classes.titleIcon}>
          <NotListedLocation />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <Typography variant="h6">{confirmDialog.title}</Typography>
        <Typography variant="subtitle2">{confirmDialog.subTitle}</Typography>
      </DialogContent>
      <DialogActions sx={{justifyContent:"center"}} className={classes.dialogAction}>
        <Button
          inputProps={{ tabIndex: "1" }}
          variant="contained"
          color="primary"
          onClick={() => props.setConfirmDialog({ isOpen: false })}
        >
          No
        </Button>
        <Button
          inputProps={{ tabIndex: "2" }}
          variant="contained"
          color="red"
          onClick={confirmDialog.onConfirm}
        >
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDialog;
