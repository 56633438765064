import { Box, Link } from "@mui/material";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { ModalCloseBtn, PopUpModel} from "../../../components";
// import { VIEW_ATTACHMENTS } from "../../../utils/routes";
// --------------------------------------------------------------------------------------------------------------
const style = {
  position: "absolute",
  top: "50%",
  left: "53.5%",
  transform: "translate(-50%, -50%)",
  width: "76.5vw",
  height: 570,
  bgcolor: "background.paper",
  borderRadius: "5px",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
};
// ------------------------------------------------------------------------
// function ViewPdf(props) {
//   const [download, setDownload] = useState(false);
//   var variable = "";
//   var variableDownload = "";
//   try {
//     if (props?.havingViewUrl) {
//       variable = props?.havingViewUrl;
//     } else {
//       variable = `${props.viewUrl}?${props.from}=${
//         props.params.id ? props.params.id : props.params
//       }${props?.attachmentNo ? `&testStepNo=${props?.attachmentNo}` : ""}${
//         props?.repId ? `&testReportId=${props.repId}` : ""
//       }&file_name=${props.url.fileName ? props.url.fileName : props?.url}`;
//     }

//     if (props?.variableDownload) {
//       variableDownload = props?.variableDownload;
//     } else {
//       variableDownload = `${props.downloadUrl}?${props.from}=${
//         props.params.id ? props.params.id : props.params
//       }${props?.attachmentNo ? `&testStepNo=${props?.attachmentNo}` : ""}${
//         props?.repId ? `&testReportId=${props.repId}` : ""
//       }&file_name=${props.url.fileName ? props.url.fileName : props?.url}`;
//     }
//   } catch (err) {
//     console.log("err ", err);
//   }

//   // on clicking the download button we are calling this function
//   const handleDownload = () => {
//     setDownload(true);
//     setTimeout(() => {
//       setDownload(false);
//     }, [2000]);
//   };

//   return (
//     <>
//       < PopUpModel open={props.showAttachment}>
//       <Box
//           className="res_tcTestData"
//           sx={{
//             ...style,
//           }}
//           noValidate
//         >
//           <div>
//             <Box>
//               <h5
//                 style={{ fontWeight: 400, fontSize: "24px" }}
//                 className="center pt-2 margin_right_5"
//               >
//                 {props?.fileName}
//               </h5>
//               <div
//                 className="res_right_20"
//                 style={{
//                   width: "14%",
//                   position: "absolute",
//                   top: "0.65rem",
//                   right: "9%",
//                   height: "1.9rem",
//                   padding: "0.1rem",
//                 }}
//               >
//                 {!props?.noDownload && (
//                   <button onClick={handleDownload}>Download</button>
//                 )}
//               </div>
//               <ModalCloseBtn
//                 close={() => {
//                   props.setShowAttachment(false);
//                   // formRefresh();
//                 }}
//               />

//               <iframe
//                 src={props?.noDownload ? `${variable}#toolbar=0` : variable}
//                 // src={variable}
//                 style={{ width: "76.5vw", height: "500px" }}
//                 // maxHeight="600"
//                 className="res_tcTestData"
//               />
//               {download && (
//                 <iframe
//                   src={variableDownload}
//                   // src={
//                   //   Boolean(props.from == "testCaseId") && Boolean(props?.repId)
//                   //     ? manualReportVariableDownload
//                   //     : Boolean(props.from == "testCaseId")
//                   //     ? manualVariableDownload
//                   //     : variableDownload
//                   // }
//                   style={{ display: "none" }}
//                   sandbox="allow-scripts allow-downloads"
//                 />
//               )}
//             </Box>
//           </div>
//         </Box>
//       </ PopUpModel>
//     </>
//   );
// }
function ViewPdf(props) {
  const [download, setDownload] = useState(false);
  const [fileError, setFileError] = useState(false); // Tracks if the file is missing
  const [variable, setVariable] = useState("");
  const [variableDownload, setVariableDownload] = useState("");

  useEffect(() => {
    let viewUrl = "";
    let downloadUrl = "";

    try {
      // Check if the required props are present
      if (props?.havingViewUrl) {
        viewUrl = props.havingViewUrl;
        setFileError(false); // File is present
      } else if (props.viewUrl && props.url) {
        viewUrl = `${props.viewUrl}?${props.from}=${
          props.params.id ? props.params.id : props.params
        }${props?.attachmentNo ? `&testStepNo=${props?.attachmentNo}` : ""}${
          props?.repId ? `&testReportId=${props.repId}` : ""
        }&file_name=${props.url.fileName ? props.url.fileName : props?.url}`;
        setFileError(false); // File is present
      } else {
        setFileError(true); // No valid file URL
      }

      // Similar logic for download URL
      if (props?.variableDownload) {
        downloadUrl = props.variableDownload;
        setFileError(false); // File is present
      } else if (props.downloadUrl && props.url) {
        downloadUrl = `${props.downloadUrl}?${props.from}=${
          props.params.id ? props.params.id : props.params
        }${props?.attachmentNo ? `&testStepNo=${props?.attachmentNo}` : ""}${
          props?.repId ? `&testReportId=${props.repId}` : ""
        }&file_name=${props.url.fileName ? props.url.fileName : props?.url}`;
        setFileError(false); // File is present
      }

      // Update the URLs in the state
      setVariable(viewUrl);
      setVariableDownload(downloadUrl);
    } catch (err) {
      console.error("Error constructing file URLs:", err);
      setFileError(true); // Set error state if an exception occurs
    }
  }, [props]); // Runs whenever props change

  const handleDownload = () => {
    setDownload(true);
    setTimeout(() => {
      setDownload(false);
    }, 2000);
  };

  return (
    <>
      <PopUpModel open={props.showAttachment}>
        <Box
          className="res_tcTestData"
          sx={{
            ...style,
          }}
          noValidate
        >
          <div>
            <Box>
              <h5
                style={{ fontWeight: 400, fontSize: "24px" }}
                className="center pt-2 margin_right_5"
              >
                {props?.fileName}
              </h5>
              <div
                className="res_right_20"
                style={{
                  width: "14%",
                  position: "absolute",
                  top: "0.65rem",
                  right: "9%",
                  height: "1.9rem",
                  padding: "0.1rem",
                }}
              >
                {!props?.noDownload && !fileError && (
                  <button onClick={handleDownload}>Download</button>
                )}
              </div>
              <ModalCloseBtn
                close={() => {
                  props.setShowAttachment(false);
                }}
              />

              {fileError ? (
                // Display this message if no file is attached
                <h2 style={{ color: "red" }}>No File Attached</h2>
              ) : (
                <iframe
                  src={props?.noDownload ? `${variable}#toolbar=0` : variable}
                  style={{ width: "76.5vw", height: "500px" }}
                  className="res_tcTestData"
                />
              )}

              {download && !fileError && (
                <iframe
                  src={variableDownload}
                  style={{ display: "none" }}
                  sandbox="allow-scripts allow-downloads"
                />
              )}
            </Box>
          </div>
        </Box>
      </PopUpModel>
    </>
  );
}

const mapStateToProps = (state) => ({
  backlogAttachments: state.backlogAttachments,
});

export default connect(mapStateToProps, {})(ViewPdf);
