import React, { useState } from "react";
import { Grid, Box, Typography, IconButton, Divider } from "@mui/material";
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

const HowToUse = ({ HowToUseContent, PrivateAndPublicContent,HowToUseDescription,HowToUseDescription1 }) => {
  // State for expanding/collapsing sections
  const [expandedSections, setExpandedSections] = useState({
    howToUse: true, // Initially expanded
    privateAndPublic: false, // Initially collapsed
  });

  // Toggle function for section
  const toggleSection = (section) => {
    setExpandedSections((prev) => ({
      ...prev,
      [section]: !prev[section], // Toggle the section state
    }));
  };

  return (
    <Box
      sx={{
        border: '1px solid #000', // Light gray border
        // borderRadius: 2, // Rounded corners
        // padding: 2, // Inner padding
        marginTop: 2, // Spacing from top
      }}
    >
        <Grid container>
          <Grid item md={12}>
            <Box sx={{ height: "2rem" }} display="flex" justifyContent="space-between" alignItems="center">
              <Typography variant="h6" fontWeight="bold" gutterBottom style={{marginLeft:"1.25rem",marginTop:"15px"}}>
                How to Use
              </Typography>
              <IconButton onClick={() => toggleSection("howToUse")}>
                {expandedSections.howToUse ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </IconButton>

            </Box>
            <Divider sx={{ marginTop: 1,borderColor: '#000000'}} /> {/* Divider below the title, removed margin bottom from the styles */}
          </Grid>

          {expandedSections.howToUse && <Grid item md={12}>
            <Typography style={{marginLeft:"1.25rem",marginBottom:"1rem",marginTop:"1rem",marginRight:"1.25rem"}}>
              {HowToUseContent}
            </Typography>
          </Grid>}
          {/* {expandedSections.howToUse && <Grid item md={12}>
            {PrivateAndPublicContent.map((item, index) => (
              <Box
                key={index}
                display="flex"
                alignItems="center"
                mb={1} // Add spacing between lines
              >
              
                  <Typography  variant="subtitle1" fontWeight="bold">
                    {item.title}:
                  </Typography>
             
                <Typography fontSize={"1rem"} variant="body2" >
                  {item.description}
                </Typography>
              </Box>
            ))}
          </Grid>}
        </Grid>
      </Box> */}
 {/* Private and Public Content */}
 {expandedSections.howToUse && PrivateAndPublicContent && PrivateAndPublicContent.length > 0 && (
          <Grid item md={12}>
            {PrivateAndPublicContent.map((item, index) => (
              <Box
                key={index}
                display="flex"
                alignItems="center"
                mb={1} // Add spacing between lines
              >
                <li style={{ listStyle: "none", marginLeft: "35px" }}>
                  <FiberManualRecordIcon style={{ fontSize: "0.625rem" }} /> <span style={{ fontWeight: "900" }}>{item.title}: </span>
                  <span>{item.description}</span>
                </li>
              </Box>
            ))}
          </Grid>
        )}
       {expandedSections.howToUse && HowToUseDescription && (
         <Grid item md={12}>
           <Typography style={{ marginLeft: "1.25rem", marginBottom: "10px" }}>
             {HowToUseDescription}
           </Typography>
         </Grid>
       )}

       {expandedSections.howToUse && HowToUseDescription1 && (
         <Grid item md={12}>
           <Typography style={{ marginLeft: "1.25rem", marginBottom: "10px" }}>
             {HowToUseDescription1}
           </Typography>
         </Grid>
       )}

      </Grid>
    </Box>
  );
};
export default HowToUse;
