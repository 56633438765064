export const UploadedDocumentTypes = [
    { id: 1, key: 'DUE_DILIGENCE_MATERIALS', label: 'Due Diligence Materials', format: ".doc, .docx, .pdf, .ppt, .pptx, .xls, .xlsx, .csv, .jpg, .jpeg, .png" },
    { id: 2, key: 'STAKEHOLDER_INTERVIEW_LIST', label: 'Stakeholder Interview List', format: ".doc, .docx, .pdf, .ppt, .pptx, .xls, .xlsx, .csv, .jpg, .jpeg, .png" },
    { id: 3, key: 'PAST_IMPACT_UPDATES', label: 'Past Impact Updates', format: ".pdf " },

    { id: 5, key: 'LEADER_CONTEXT_MATERIALS', label: 'Leader Context Materials', format: ".doc, .docx, .pdf, .ppt, .pptx,  .jpg, .jpeg, .png" },
    { id: 6, key: 'LEADERS_COACHING_ACTION_PLAN', label: 'Leaders Coaching Action Plan', format: ".doc .docx .pdf .ppt .pptx" },
    { id: 7, key: 'CLOSEOUT_CALL_NOTES', label: 'Closeout Call Notes', format: ".doc, .docx, .pdf" },
    { id: 8, key: 'CHECKIN_CALL_NOTES', label: 'Check-In Call Notes', format: ".doc, .docx, .pdf" },
    { id: 9, key: 'SCOPING_NOTES_AUDIO', label: 'Scoping Notes Audio', format: ".doc, .docx, .pdf, .mp3, .mp4, .m4a" },
    { id: 10, key: 'MATCHING_NOTES_AUDIO', label: 'Matching Notes Audio', format: ".doc, .docx, .pdf, .mp3, .mp4, .m4a" },
    { id: 11, key: 'ACCOUNT_BRIEFING_PACKET', label: 'Account Briefing Packet', format: ".doc, .docx, .pdf, .ppt, .pptx, " }
];
