import React, { useState, useEffect } from "react";
// components
import Layout from "../../../layout/Layout";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import Paper from "@mui/material/Paper";
import { makeStyles } from "@mui/styles";
import StepLabel from "@mui/material/StepLabel";
import {
  MuiDataGrid,
  PopUpModel,
  MuiDataGridForSummary,
  EyeInfo,
} from "../../../components";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import { PieChart, Pie, Sector, Cell, ResponsiveContainer } from "recharts";
import FilledInput from "@mui/material/FilledInput";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import ModalCloseBtn from "../../../components/common/ModalCloseBtn";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Chart from "react-apexcharts";
import Notifier from "../../../components/common/Notifier";
import ErrorNotifier from "../../../components/common/ErrorNotifier";

import { PDFExport } from "@progress/kendo-react-pdf";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import { Chart as ChartJS, ArcElement, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
// import { DateRangePicker, DateRange } from '@mui/x-date-pickers-pro/DateRangePicker';
// import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
// import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { FormSubmitButton } from "../../../components";
import InputAdornment from "@mui/material/InputAdornment";
import { PageHeader } from "@excoleadershipui/pionweblib";
import { DataGrid, GridToolbar, GridRow } from "@mui/x-data-grid";
import { MuiAutocomplete } from "../../../components";
import { GridCellExpand } from "../../../components";

import { DateTime } from "luxon";
import { format } from "date-fns";
//redux-toolkit
import { useDispatch, useSelector } from "react-redux";
import {
  getFullEnagegementsReports,
  getLatestClientSummary,
} from "../../../features/reports/reportsSlice";
import {
  getAllClientForHr,
  getAllReportsSummary,
  getPreviousSummaryForHr,
  submitPreviousSummaryForHr,
  emptyObject,
  errMessage,
  resetErrMessage,
  dateErrorMessage,
  resetMessage,
  getGenerateChrSummary,
  getAllReportsChrSummary,
} from "../../../features/chr/reports/reportsSlice";
import { getToggleUserData } from "../../../features/auth/authSlice";
import { Grid, useMediaQuery } from "@mui/material";
import {
  SummaryTableAdditionalCommentaryPdf,
  SummaryTableEngagementNotesPdf,
  SummaryTableLevelOfEngagementPdf,
  SummaryTableTouchPointDatesPdf,
} from "../../hr/Reports/SummaryTablePdf";

import { getViewDocumentsImpactSummaryCHR } from "../../../features/chr/reports/reportsSlice";

import HowToUse from "../../../components/common/HowToUse";
import Attachments from "../../../components/common/upload_documents/Attachments";

const DonutMemo = React.memo(({ dataReports }) => {
  return <Doughnut data={dataReports} options={dataReports.options} />;
});

const Reports = () => {
  const [clientName, setClientName] = useState(
    JSON.parse(localStorage.getItem("clientid"))
  );
  const [accountName, setAccountName] = useState("");
  const [checkInRating, setCheckinRating] = useState(null);
  const [allValue, setAllValue] = useState("");
  const [holder, setHolder] = useState("");
  const [reviewValue, setCheckinValue] = useState("");
  const [showModelGenerate, setShowModelGenerate] = useState("");
  const [showAdd, setShowAdd] = useState(false);
  const [showUpdate, setShowUpdate] = useState(false);
  const [showCoachingAdd, setShowCoachingAdd] = useState(false);
  const [showUpdateCoachingAdd, setShowUpdateCoachingAdd] = useState(false);
  const [generateDateValue, setGenerateDateValue] = useState("");
  const [generateRowId, setGenerateRowId] = useState(null);
  const [generateTwoRowId, setGenerateTwoId] = useState(false);
  const [commentId, setCommentId] = useState(null);
  const [submitCheck, setSubmitCheck] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [deleteShow, setDeleteShow] = useState(false);
  const [toggleDate, setToggleDate] = useState(false);
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });

  const [isPdfView, setIsPdfView] = useState(false);
  const dispatch = useDispatch();

  const pdfExportComponent = React.useRef(null);
  const hiddenContentRef = React.useRef(null);

  const {
    generateChrSummaryData,
    dateErrorMessageData,
    previousSummaryDataForHr,
    message,
    errMessage,
  } = useSelector((state) => ({ ...state.engagementCHRReports }));

  const { toggleUserData } = useSelector((state) => ({
    ...state.auth,
  }));

  ChartJS.register(ArcElement, Legend);

  const dataReports = {
    datasets: [
      {
        data: [300, 300, 300],

        needleValue: checkInRating,
        // Change This for Meter
        backgroundColor: ["red", "#FFCE56", "lightgreen"],
        hoverBackgroundColor: ["red", "#FFCE56", "lightgreen"],
      },
    ],
    options: {
      layout: {},
      rotation: -95,
      circumference: 60 * Math.PI,
      legend: {
        display: false,
      },
      cutoutPercentage: 70,
    },
  };

  const mactchesReport = useMediaQuery((theme) => theme.breakpoints.down("md"));
  let fixHeight = mactchesReport ? 200 : 180;

  const {areaFocusData } = useSelector(
    (state) => ({ ...state.CHRReports })
  );

  ChartJS.register({
    id: "doughnut",
    afterDraw: (chart, args, opts) => {
      var needleValue = chart.config.data.datasets[0].needleValue;
      var dataTotal = chart.config.data.datasets[0].data.reduce(
        (a, b) => a + b,
        0
      );
      var angle = Math.PI + (1 / dataTotal) * needleValue * Math.PI;
      var ctx = chart.ctx;
      var cw = chart.canvas.offsetWidth;
      var ch = chart.canvas.offsetHeight - fixHeight;

      var cx = cw / 2;
      var cy = ch + 95;
      ctx.translate(cx, cy);
      ctx.rotate(angle);
      ctx.beginPath();
      ctx.moveTo(0, -3);
      ctx.lineTo(ch - 5, 0);
      ctx.lineTo(0, 3);
      ctx.fillStyle = "rgb(0, 0, 0)";
      ctx.fill();
      ctx.rotate(-angle);
      ctx.translate(-cx, -cy);
      ctx.beginPath();
      ctx.arc(cx, cy, 5, 0, Math.PI * 2);
      ctx.fill();
    },
  });

  const style = {
    position: "absolute",
    top: "50%",
    left: "60%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    borderRadius: "5px",
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  };

  const data = [
    { name: "Group A", value: 400 },
    { name: "Group B", value: 400 },
    { name: "Group C", value: 400 },
  ];

  const useStyles = makeStyles((theme) => ({
    cell: {
      whiteSpace: "none !important",
    },
    root: {
      "& > *": {
        margin: theme.spacing(1),
      },
    },
    responsive_btn: {
      [theme.breakpoints.down("md")]: {
        marginRight: "18rem",
        marginTop: "2rem",
      },
    },
    responsive_width: {
      [theme.breakpoints.down("md")]: {
        width: "19.875rem !important",
        overflowX: "scroll",
      },
    },
    datediv: {
      [theme.breakpoints.down("md")]: {
        marginTop: "1rem !important",
      },
    },

    previousSummaryClass: {
      [theme.breakpoints.down("md")]: {
        width: "15rem !important",
        marginTop: "1rem",
      },
      [theme.breakpoints.up("md")]: {
        width: "50% !important",
        marginTop: "1rem",
      },
    },

    responsive_img: {
      [theme.breakpoints.down("md")]: {
        marginTop: "1px !important",
      },
    },
    desk_width: {
      [theme.breakpoints.up("md")]: {
        width: "12.25rem",
      },
    },
    desk_textbox: {
      [theme.breakpoints.up("md")]: {
        marginLeft: "2.625rem !important",
      },
    },
    previousDiv: {
      [theme.breakpoints.down("md")]: {
        marginTop: "2rem !important",
        marginLeft: "7rem !important",
        whiteSpace: "nowrap !important",
      },
    },
    dateParentDiv: {
      [theme.breakpoints.down("md")]: {
        display: "flex",
        flexDirection: "column",
        height: "3.5rem",
      },
      [theme.breakpoints.up("md")]: {
        display: "flex",
        flexDirection: "column",
        height: "3.5rem",
        marginLeft: "0.5rem",
      },
    },
  }));
  const classes = useStyles();

  function renderCellExpand(params) {
    return (
      <GridCellExpand
        value={params.value || ""}
        width={params.colDef.computedWidth}
      />
    );
  }

  const getCreatedOnDate = (params) => {
    return params.row.createdOn
      ? format(new Date(params.row.createdOn), "MM-dd-yyyy")
      : "";
  };

  const getUpdatedOnDate = (params) => {
    return params.row.updatedOn
      ? format(new Date(params.row.updatedOn), "MM-dd-yyyy")
      : "";
  };

  const dataTwo = {
    rows:
      Object.keys(generateChrSummaryData).length > 0
        ? generateChrSummaryData.checkin
        : [],
    columns: [
      {
        field: "Rating By Mentor/Coach ",
        headerName: "Rating By Mentor/Coach",
        width: 450,
        renderCell: (params) => {
          return (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <div
                style={{
                  backgroundColor:
                    (params?.row?.engagementCheckInRating === 1 && "red") ||
                    (params?.row?.engagementCheckInRating === 2 && "yellow") ||
                    (params?.row?.engagementCheckInRating === 3 && "green"),
                  width: "20px",
                  height: "20px",
                  borderRadius: "100%",

                  display: "inline-block",
                }}
              ></div>

              <div style={{ display: "inline-block", marginLeft: "1rem" }}>
                {(params?.row?.engagementCheckInRating === 1 && "Red") ||
                  (params?.row?.engagementCheckInRating === 2 && "Yellow") ||
                  (params?.row?.engagementCheckInRating === 3 && "Green")}
              </div>
            </div>
          );
        },

        valueGetter: (params) => "red",
        cellClassName: (params) => {
          return classes.cell;
        },
      },
      {
        field: "dateAdded",
        headerName: "Date Added",
        width: 200,
        renderCell: renderCellExpand,
        valueGetter: getCreatedOnDate,
        cellClassName: (params) => {
          return classes.cell;
        },
      },
      {
        field: "dateUpdated",
        headerName: "Date Updated",
        width: 200,
        renderCell: renderCellExpand,
        valueGetter: getUpdatedOnDate,
        cellClassName: (params) => {
          return classes.cell;
        },
      },
    ],
  };

  const dataOne = {
    rows:
      Object.keys(generateChrSummaryData).length > 0
        ? generateChrSummaryData.cfa
        : [],
    columns: [
      {
        field: "progressDescription",
        headerName: "Description",
        renderCell: renderCellExpand,
        width: 980,
      },
      {
        field: "dateAdded",
        headerName: "Date Added",
        width: 200,
        renderCell: renderCellExpand,
        valueGetter: getCreatedOnDate,
        cellClassName: (params) => {
          return classes.cell;
        },
      },
      {
        field: "dateUpdated",
        headerName: "Date Updated",
        width: 200,
        renderCell: renderCellExpand,
        valueGetter: getUpdatedOnDate,
        cellClassName: (params) => {
          return classes.cell;
        },
      },
    ],
  };

  const dataThree = {
    rows:
      Object.keys(generateChrSummaryData).length > 0
        ? generateChrSummaryData.notes
        : [],
    columns: [
      {
        field: "engagementNote",
        headerName: "Notes from Mentor/Coach",
        renderCell: renderCellExpand,
        width: 450,
      },
      {
        field: "dateAdded",
        headerName: "Date Added",
        width: 200,
        renderCell: renderCellExpand,
        valueGetter: getCreatedOnDate,
        cellClassName: (params) => {
          return classes.cell;
        },
      },
      {
        field: "dateUpdated",
        headerName: "Date Updated",
        width: 200,
        renderCell: renderCellExpand,
        valueGetter: getUpdatedOnDate,
        cellClassName: (params) => {
          return classes.cell;
        },
      },
    ],
  };

  const getLastMeetingDate = (params) => {
    return params?.row?.dateOfLastMeeting
      ? format(new Date(params?.row?.dateOfLastMeeting), "MM-dd-yyyy")
      : "";
  };

  const getLastHRTouchPoint = (params) => {
    return params?.row?.dateOfLastHrTouchPoint
      ? format(new Date(params?.row?.dateOfLastHrTouchPoint), "MM-dd-yyyy")
      : "";
  };

  const getLastManagerTouchPoint = (params) => {
    return params?.row?.dateOfLastManagerTouchPoint
      ? format(new Date(params?.row?.dateOfLastManagerTouchPoint), "MM-dd-yyyy")
      : "";
  };

  const HowToUseContent = `
  Select a date range above and click the "Generate" button to display a history of all the leadership 
  coaching impact updates that have been submitted within the selected time period.
  `;

  const touchPointDatesData = {
    rows:
      Object.keys(generateChrSummaryData).length > 0
        ? generateChrSummaryData.checkin
        : [],
    columns: [
      {
        field: "lastMeeting",
        headerName: "Last Meeting",
        valueGetter: getLastMeetingDate,
        width: 350,
      },
      {
        field: "lastHRTouchPoing",
        headerName: "Last HR Touch Point",
        width: 200,
        valueGetter: getLastHRTouchPoint,
      },
      {
        field: "lastManagerTouchPoint",
        headerName: "Last Manager Touch Point",
        width: 200,
        valueGetter: getLastManagerTouchPoint,
      },
    ],
  };

  const matches2 = useMediaQuery((theme) => theme.breakpoints.down("md"));

  const isMappingGrpahPdf = () => {
    if (Object.keys(generateChrSummaryData).length > 0) {
      let data = [];
      const items = [];
      const focus = [];
      for (const res of generateChrSummaryData?.ratings) {
        focus.push(res.focus);
        let coachingFocusName;
        for (const res1 of res.mentorToClientRating) {
          coachingFocusName = res?.otherDevelopmentAreaCoachingFocus;

          const date = new Date(res1?.updatedOn);
          const transformDate =
            date.getMonth() +
            1 +
            "-" +
            date.getDate() +
            "-" +
            date.getFullYear() +
            " UTC";

          let rate;
          if (res1.rating === 150) rate = 3;
          if (res1.rating === 100) rate = 2;
          if (res1.rating === 50) rate = 1;
          if (res1.rating === 1) rate = 0;

          data.push({
            x: transformDate,
            y: rate,
          });
        }

        const options = {
          colors: ["#000"],
          tooltip: {
            enabled: false,
          },
          chart: {
            height: 380,
            width: "100%",
            type: "line",
            animations: {
              initialAnimation: {
                enabled: true,
              },
            },
          },
          series: [
            {
              data,
            },
          ],
          markers: {
            size: 5,
            colors: "#000000",
            strokeColors: "#111111",
            strokeWidth: 15,
            strokeOpacity: 0.2,
            fillOpacity: 0.7,
          },
          xaxis: {
            type: "datetime",
          },
          yaxis: {
            labels: {
              formatter: function (val, index) {
                if (val === -3) return "N/A";
                return val;
              },
            },

            min: 0,
            max: 3,

            tickAmount: 3,
          },
        };
        data = [];

        let finalWidth = matches2 ? "250" : "450";

        items.push({
          id: res.id,
          name: coachingFocusName,
          val: (
            <Chart
              options={options}
              // key={uuidv4()}
              series={options.series}
              type="line"
              width={finalWidth}
              height="200"
            />
          ),
        });
      }

      return (
        <>
          {items.map((item, index) => {
            return (
              <Grid container>
                {toggleUserData?.length > 0 &&
                  toggleUserData[0]?.progressRatingScale && (
                    <Grid item xs={12} sm={12} md={6}>
                      {" "}
                      <Stack style={{ width: "100%" }} key={item?.id}>
                        <Typography
                          sx={{
                            fontSize: "1rem",
                            fontWeight: "bold",
                            marginTop: index !== 0 && "2rem",
                          }}
                        >
                          {item.name}
                        </Typography>
                        <Paper>{item.val}</Paper>
                      </Stack>
                    </Grid>
                  )}
                <Grid item xs={12} sm={12} md={6}>
                  {generateChrSummaryData &&
                    generateChrSummaryData?.ratings?.map((data, index) => {
                      if (item?.id === data?.id) {
                        return (
                          <Stack>
                            {toggleUserData?.length > 0 &&
                              toggleUserData[0]?.progressRatingScale && (
                                <Typography
                                  sx={{
                                    fontSize: "1rem",
                                    marginTop: "1.5rem",
                                    fontWeight: "bold",
                                    marginLeft: "1rem",
                                  }}
                                >
                                  {data.otherDevelopmentAreaCoachingFocus}
                                </Typography>
                              )}

                            <Paper
                              key={data.id}
                              sx={{
                                height: "auto",
                                // minHeight: 215,
                                marginTop:
                                  index === 0 ||
                                  (toggleUserData?.length > 0 &&
                                    toggleUserData[0]?.progressRatingScale)
                                    ? "1.5rem"
                                    : "3.4rem",
                                // overflow: "auto",
                                marginLeft: "1rem",
                              }}
                              elevation={3}
                            >
                              <Stack
                                sx={{
                                  display: "flex",
                                  flexDirection: "row",
                                  justifyContent: "center",
                                  backgroundColor: "#008B00",
                                  color: "#fff",
                                  padding: "0.5rem",
                                }}
                              >
                                <Typography
                                  variant="pageSecHeader"
                                  sx={{ fontFamily: '"Arial", sans-serif' }}
                                >
                                  PROGRESS UPDATE
                                </Typography>
                              </Stack>
                              <Grid container>
                                <Grid item xs={12} sm={12} md={6}>
                                  <Typography
                                    sx={{
                                      fontSize: "1rem",
                                      fontWeight: "bold",
                                      paddingLeft: "1rem",
                                      paddingRight: "1rem",
                                      display: "flex",
                                      justifyContent: "center",
                                      backgroundColor: "#d8f0db",
                                    }}
                                    gutterBottom
                                    variant="h9"
                                    component="div"
                                  >
                                    Comments
                                  </Typography>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6}>
                                  <Typography
                                    sx={{
                                      fontSize: "1rem",
                                      fontWeight: "bold",
                                      paddingLeft: "1rem",
                                      paddingRight: "1rem",
                                      display: "flex",
                                      justifyContent: "center",
                                      backgroundColor: "#d8f0db",
                                    }}
                                    gutterBottom
                                    variant="h9"
                                    component="div"
                                  >
                                    Date Added
                                  </Typography>
                                </Grid>
                              </Grid>

                              {data?.mentorToClientRating?.length > 0 &&
                                data?.mentorToClientRating?.map((dataTwo) => (
                                  <Grid
                                    container
                                    key={dataTwo?.comment}
                                    sx={{ borderBottom: "1px solid gray" }}
                                  >
                                    <Grid item xs={12} sm={12} md={6}>
                                      <Typography
                                        style={{
                                          display: "flex",
                                          justifyContent: "center",
                                          textAlign: "justify",
                                          fontFamily: '"Arial", sans-serif',
                                          fontSize: "0.625rem",
                                        }}
                                      >
                                        {dataTwo?.comment}
                                      </Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6}>
                                      <Typography
                                        sx={{
                                          mt: "0.5rem",
                                          display: "flex",
                                          justifyContent: "center",
                                          fontFamily: '"Arial", sans-serif',
                                          fontSize: "0.625rem",
                                        }}
                                      >
                                        {format(
                                          new Date(dataTwo?.updatedOn),
                                          "MM-dd-yyyy"
                                        )}
                                      </Typography>
                                    </Grid>
                                  </Grid>
                                ))}
                              <Stack
                                sx={{
                                  paddingLeft: "1rem",
                                  paddingRight: "1rem",
                                }}
                              >
                                {/* {commentId != data.id && (
                            <Stack sx={{ paddingBottom: "1rem" }}>
                              <Accordion>
                                <AccordionDetails>
                                  <Typography>
                                    {data.mentorToClientRating.length > 0 &&
                                      data.mentorToClientRating?.at(0)
                                        .comment}
                                  </Typography>
                                </AccordionDetails>
                              </Accordion>
                              <Typography sx={{ mt: "0.5rem" }}>
                                {data.mentorToClientRating.length > 0 &&
                                  format(
                                    new Date(
                                      data.mentorToClientRating?.at(
                                        0
                                      ).updatedOn
                                    ),
                                    "MM-dd-yyyy"
                                  )}
                              </Typography>
                            </Stack>
                          )} */}
                                {/* running code commented */}
                                {/* {commentId != data.id &&
                                  data.mentorToClientRating.length > 1 && (
                                    <Stack
                                      sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "flex-end",
                                        marginTop: "0.5rem",
                                        paddingBottom: "1rem",
                                      }}
                                    >
                                      <Button
                                        onClick={() =>
                                          handlViewMoreClick(data.id)
                                        }
                                        size="small"
                                        variant="contained"
                                      >
                                        View More
                                      </Button>
                                    </Stack>
                                  )}
                                {commentId === data.id &&
                                  data.mentorToClientRating.length > 0 &&
                                  data.mentorToClientRating.map((dataTwo) => {
                                    return (
                                      <Stack>
                                        <Accordion sx={{ marginTop: "1rem" }}>
                                          <AccordionDetails>
                                            <Typography>
                                              {dataTwo?.comment}
                                            </Typography>
                                          </AccordionDetails>
                                        </Accordion>

                                        <Typography>
                                          {format(
                                            new Date(dataTwo?.updatedOn),
                                            "MM-dd-yyyy"
                                          )}
                                        </Typography>
                                      </Stack>
                                    );
                                  })} */}
                                {/* running code comment end */}
                              </Stack>
                            </Paper>
                          </Stack>
                        );
                      }
                    })}
                </Grid>
              </Grid>
            );
          })}
        </>
      );
    }
  };

  const isMappingGrpah = () => {
    if (Object.keys(generateChrSummaryData).length > 0) {
      let data = [];
      const items = [];
      const focus = [];
      for (const res of generateChrSummaryData?.ratings) {
        focus.push(res.focus);
        let coachingFocusName;
        for (const res1 of res.mentorToClientRating) {
          coachingFocusName = res?.otherDevelopmentAreaCoachingFocus;

          const date = new Date(res1.updatedOn);
          const transformDate =
            date.getMonth() +
            1 +
            "-" +
            date.getDate() +
            "-" +
            date.getFullYear() +
            " UTC";

          let rate;
          if (res1.rating === 150) rate = 3;
          if (res1.rating === 100) rate = 2;
          if (res1.rating === 50) rate = 1;
          if (res1.rating === 1) rate = 0;

          data.push({
            x: transformDate,
            y: rate,
          });
        }

        const options = {
          colors: ["#000"],
          tooltip: {
            enabled: false,
          },
          chart: {
            height: 380,
            width: "100%",
            type: "line",
            animations: {
              initialAnimation: {
                enabled: true,
              },
            },
          },
          series: [
            {
              data,
            },
          ],
          markers: {
            size: 5,
            colors: "#000000",
            strokeColors: "#111111",
            strokeWidth: 15,
            strokeOpacity: 0.2,
            fillOpacity: 0.7,
          },
          xaxis: {
            type: "datetime",
          },
          yaxis: {
            labels: {
              formatter: function (val, index) {
                if (val === -3) return "N/A";
                return val;
              },
            },

            min: 0,
            max: 3,

            tickAmount: 3,
          },
        };
        data = [];

        let finalWidth = matches2 ? "250" : "600";

        items.push({
          id: res.id,
          name: coachingFocusName,
          val: (
            <Chart
              options={options}
              // key={uuidv4()}
              series={options.series}
              type="line"
              width={finalWidth}
              height="200"
            />
          ),
        });
      }
      return (
        <>
          {items.map((data, index) => {
            return (
              <Stack style={{ width: "100%" }} key={data?.id}>
                <Typography
                  sx={{
                    fontSize: "1rem",
                    fontWeight: "bold",
                    // marginTop: index !== 0 && "2rem",
                    marginTop: "1rem",
                    marginBottom: "1rem",
                  }}
                >
                  {data.name}
                </Typography>
                <Paper>{data.val}</Paper>
              </Stack>
            );
          })}
        </>
      );
    }
  };

  const type = "linear";

  const handleStartChange = (e) => {
    setStartDate(e.target.value);
  };

  const handleEndChange = (e) => {
    setEndDate(e.target.value);
  };

  const handlViewMoreClick = (id) => {
    setCommentId(id);
  };

  const handleGenerateMentorReports = (id) => {
    const dateStart = DateTime.fromISO(startDate).set({
      hour: 0,
      minute: 0,
      second: 0,
      millisecond: 0,
    });
    const dateEnd = DateTime.fromISO(endDate).set({
      hour: 0,
      minute: 0,
      second: 0,
      millisecond: 0,
    });
    const engageDate = DateTime.fromISO(clientName.engagementStartDate).set({
      hour: 0,
      minute: 0,
      second: 0,
      millisecond: 0,
    });

    const localIsoStringStart = dateStart.toISO();
    const localIsoStringEnd = dateEnd.toISO();
    const localEngagementDate = engageDate.toISO();

    const body = {
      excoCustomerUserId: clientName?.id,
      startDate: localIsoStringStart,
      endDate: localIsoStringEnd,
    };

    if (startDate && endDate) {
      // if (Date.parse(dateStart) >= Date.parse(localEngagementDate)) {
      if (Date.parse(endDate) < Date.parse(startDate)) {
        dispatch(
          dateErrorMessage(
            "Start date should be less than or equal to End date"
          )
        );
        setTimeout(() => {
          dispatch(dateErrorMessage(""));
        }, 2000);
      } else {
        dispatch(dateErrorMessage(""));
        dispatch(getGenerateChrSummary(body)).then((res) => {
          if (res?.payload?.data?.ok) {
            setGenerateTwoId(false);
            setTimeout(() => {
              dispatch(resetMessage());

              // dispatch(getAssesmentImprovement());
            }, 1000);
          }
          if (res?.payload?.error) {
            setTimeout(() => {
              dispatch(resetErrMessage());
            }, 2000);
          }
        });
      }
      // } else {

      //   dispatch(
      //     dateErrorMessage(
      //       "Start date should greater than or equal to  engagement start date"
      //     )
      //   );
      //   setTimeout(() => {
      //     dispatch(dateErrorMessage(""));
      //   }, 2000);
      // }
    } else {
      dispatch(dateErrorMessage("Please enter date"));
      setTimeout(() => {
        dispatch(dateErrorMessage(""));
      }, 2000);
    }
  };

  useEffect(() => {
    if (
      !endDate &&
      Object.keys(generateChrSummaryData).length > 0 &&
      generateChrSummaryData?.checkin[0]?.ratingFromDate
    ) {
      setStartDate(
        generateChrSummaryData &&
          generateChrSummaryData?.checkin[0]?.ratingFromDate
          ? format(
              new Date(
                generateChrSummaryData &&
                  generateChrSummaryData?.checkin[0]?.ratingFromDate
              ),
              "yyyy-MM-dd"
            )
          : ""
      );
    } else if (!endDate && clientName?.engagementStartDate) {
      setStartDate(
        clientName?.engagementStartDate
          ? format(new Date(clientName?.engagementStartDate), "yyyy-MM-dd")
          : ""
      );
    }
    if (!endDate && Object.keys(generateChrSummaryData).length > 0) {
      setEndDate(
        generateChrSummaryData &&
          generateChrSummaryData?.checkin[0]?.ratingToDate
          ? format(
              new Date(
                generateChrSummaryData.checkin[0]?.ratingToDate &&
                  generateChrSummaryData?.checkin[0]?.ratingToDate
              ),
              "yyyy-MM-dd"
            )
          : ""
      );
    }
  }, [generateChrSummaryData]);

  useEffect(() => {
    setStartDate("");
    setEndDate("");
    dispatch(emptyObject());
    dispatch(getAllReportsChrSummary(clientName?.id));
    dispatch(getToggleUserData(clientName?.id));
  }, []);

  console.log("engagementId",clientName)

  return (
    <Layout>
      <Grid mt={4} container>
        {/* <Grid item xs={12} sm={12} md={12}>
          <Attachments  disableAdd={true} hideFooter={true} />
        </Grid> */}
                <Grid item xs={12} sm={12} md={12}>
                  <Stack
                    sx={{
                      paddingTop: "1rem",
                    }}
                  >
                    {/* <PageHeader variant="pageHeader">
                      {" "}
                      View Documents {" "}

                    </PageHeader> */}
                  </Stack>
                  <Attachments
                    hideFooter={true}
                    typeKey="leadersContextMaterials"
                    attachmentType="Leaders Bio,Org Charts & Context Materials"
                    clientEngagementId={clientName?.engagementId}
                    // createDocument={createHrEngagementDocuments}
                    getDocuments={getViewDocumentsImpactSummaryCHR}
                    // deleteDocument={deleteHRDocuments}
                    // resetMessage={resetMessage}
                    // resetErrMessage={resetErrMessage}
                    // message={message}
                    // errMessage={errMessage}
                    disableAdd={true}  
                    disableUpdate={true}
                    dynamicPath="/file-acl/chr/view/common/"         
                    isShowDelete={false}  
                    viewData={areaFocusData}
                  />
                </Grid>

      </Grid>
      <Box ref={hiddenContentRef} style={{ display: "none" }}>
        {/* <Box ref={hiddenContentRef}> */}
        <PDFExport
          keepTogether="p"
          paperSize="A2"
          margin="2cm"
          ref={pdfExportComponent}
        >
          <Box>
            <Stack sx={{ backgroundColor: "#757474" }}>
              <Box sx={{ padding: "1rem" }}>
                <Typography
                  variant="h4"
                  component="h4"
                  mb={1}
                  sx={{ color: "#fff" }}
                >
                  Coaching Progress Summary for {clientName?.firstName}{" "}
                  {clientName?.lastName}
                </Typography>
                <Typography variant="h5" component="h5" sx={{ color: "#fff" }}>
                  <strong> Date Range :</strong>{" "}
                  {startDate && format(new Date(startDate), "MM-dd-yyyy")} to{" "}
                  {endDate && format(new Date(endDate), "MM-dd-yyyy")}
                </Typography>
              </Box>
            </Stack>
            {clientName && (
              <Stack
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  marginTop: "2rem",
                }}
              >
                <Grid container columnSpacing={2}>
                  <Grid item xs={12} sm={12} md={5}>
                    {clientName && (
                      <Stack
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "center",
                          backgroundColor: "#00468B",
                          color: "#fff",
                          padding: "0.5rem",
                        }}
                      >
                        <Typography
                          variant="pageSecHeader"
                          sx={{ fontFamily: '"Arial", sans-serif' }}
                        >
                          LEVEL OF ENGAGEMENT
                        </Typography>
                      </Stack>
                    )}
                    <Stack sx={{}}>
                      {clientName && (
                        // <MuiDataGridForSummary
                        //   select={(dataThree) => dispatch(rowSelect(dataThree))}
                        //   data={dataTwo}
                        //   hidePagination={true}
                        // />
                        <SummaryTableLevelOfEngagementPdf data={dataTwo} />
                      )}
                    </Stack>
                  </Grid>

                  <Grid item xs={12} sm={12} md={7}>
                    {clientName && (
                      <Stack
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "center",
                          backgroundColor: "#00468B",
                          color: "#fff",
                          padding: "0.5rem",
                        }}
                      >
                        <Typography
                          variant="pageSecHeader"
                          sx={{ fontFamily: '"Arial", sans-serif' }}
                        >
                          ENGAGEMENT NOTES
                        </Typography>
                      </Stack>
                    )}
                    <Stack sx={{}}>
                      {clientName && (
                        // <MuiDataGridForSummary
                        //   data={dataThree}
                        //   hidePagination={true}
                        // />
                        <SummaryTableEngagementNotesPdf data={dataThree} />
                      )}
                    </Stack>
                  </Grid>
                </Grid>
              </Stack>
            )}
            {/* touch point table data commented */}
            {clientName &&
              toggleUserData?.length > 0 &&
              toggleUserData[0]?.dateOfLastMeeting && (
                <Stack
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    marginTop: "2rem",
                  }}
                >
                  <Grid container>
                    <Grid item xs={12} sm={12}>
                      {clientName && (
                        <Stack
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                            backgroundColor: "#00468B",
                            color: "#fff",
                            padding: "0.5rem",
                          }}
                        >
                          <Typography
                            variant="pageSecHeader"
                            sx={{ fontFamily: '"Arial", sans-serif' }}
                          >
                            TOUCH POINT DATES
                          </Typography>
                        </Stack>
                      )}
                      <Stack sx={{}}>
                        {clientName && (
                          <SummaryTableTouchPointDatesPdf
                            data={touchPointDatesData}
                          />
                        )}
                      </Stack>
                    </Grid>
                  </Grid>
                </Stack>
              )}
            {clientName && (
              <Stack
                sx={{
                  // display: "flex",
                  // flexDirection: "row",
                  // justifyContent: "space-between",
                  mt: "2rem",
                }}
              >
                <Grid container>
                  <Grid
                    md={5}
                    sx={{
                      background: "#ccd4e0",
                      padding: "0.5rem",
                      marginRight: "2rem",
                    }}
                  >
                    <Typography
                      sx={{ fontSize: "1.125rem", fontWeight: "bold" }}
                      gutterBottom
                      variant="h9"
                      component="div"
                    >
                      Engagement Key
                    </Typography>
                    <Stack
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        fontFamily: "arial",
                        gap: "0.5rem",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "0.90rem",
                          display: "flex",
                          alignItems: "center",
                          gap: "1rem",
                        }}
                        gutterBottom
                        variant="h9"
                        component="div"
                      >
                        <div
                          style={{
                            backgroundColor: "green",
                            width: "20px",
                            height: "20px",
                            borderRadius: "100%",

                            display: "inline-block",
                          }}
                        ></div>
                        <p style={{ margin: 0 }}>
                          Highly engaged with coach/set meetings
                          regulary/leaning in.
                        </p>
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "0.90rem",
                          display: "flex",
                          alignItems: "center",
                          gap: "1rem",
                        }}
                        gutterBottom
                        variant="h9"
                        component="div"
                      >
                        <div
                          style={{
                            backgroundColor: "yellow",
                            width: "20px",
                            height: "20px",
                            borderRadius: "100%",

                            display: "inline-block",
                          }}
                        ></div>
                        <p style={{ margin: 0 }}>
                          Engaged when meeting but challenging to schedule with.
                        </p>
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "0.90rem",
                          display: "flex",
                          alignItems: "center",
                          gap: "1rem",
                        }}
                        gutterBottom
                        variant="h9"
                        component="div"
                      >
                        <div
                          style={{
                            backgroundColor: "red",
                            width: "20px",
                            height: "20px",
                            borderRadius: "100%",

                            display: "inline-block",
                          }}
                        ></div>
                        <p style={{ margin: 0 }}>
                          Goes long period without meeting with coach/not
                          engaged.
                        </p>
                      </Typography>
                    </Stack>
                  </Grid>
                  {toggleUserData?.length > 0 &&
                    toggleUserData[0]?.progressRatingScale && (
                      <Grid
                        container
                        item
                        md={6}
                        sx={{ background: "#ccd4e0", padding: "0.5rem" }}
                      >
                        <Grid item md={12} sm={12} xs={12}>
                          <Typography
                            sx={{
                              fontSize: "1.125rem",
                              fontWeight: "bold",
                            }}
                            gutterBottom
                            variant="h9"
                            component="div"
                          >
                            Progress Scale Rating
                          </Typography>
                        </Grid>

                        <Grid item md={12} sm={12} xs={12}>
                          <Typography
                            sx={{ fontSize: "0.75rem" }}
                            gutterBottom
                            variant="h9"
                            component="div"
                          >
                            <span
                              style={{ fontSize: "1rem", fontWeight: "bold" }}
                            >
                              0
                            </span>
                            &nbsp;&nbsp;&nbsp;&nbsp; Baseline - No progress
                          </Typography>
                          <Typography
                            sx={{ fontSize: "0.90rem" }}
                            gutterBottom
                            variant="h9"
                            component="div"
                          >
                            <span
                              style={{ fontSize: "1rem", fontWeight: "bold" }}
                            >
                              1
                            </span>
                            &nbsp;&nbsp;&nbsp;&nbsp; Work in Progress
                          </Typography>
                          <Typography
                            sx={{ fontSize: "0.90rem" }}
                            gutterBottom
                            variant="h9"
                            component="div"
                          >
                            <span
                              style={{ fontSize: "1rem", fontWeight: "bold" }}
                            >
                              2
                            </span>
                            &nbsp;&nbsp;&nbsp;&nbsp; Significant Improvement
                          </Typography>
                          <Typography
                            sx={{ fontSize: "0.90rem" }}
                            gutterBottom
                            variant="h9"
                            component="div"
                          >
                            <span
                              style={{ fontSize: "1rem", fontWeight: "bold" }}
                            >
                              3
                            </span>
                            &nbsp;&nbsp;&nbsp;&nbsp; Completed or Maintaining
                            Successful Execution
                          </Typography>
                        </Grid>
                      </Grid>
                    )}
                </Grid>
              </Stack>
            )}
            {clientName && generateChrSummaryData?.ratings?.length > 0 && (
              <Stack
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "start",
                }}
              >
                <Stack sx={{ marginTop: "1.5rem", background: "#000" }}>
                  <Typography
                    variant="h5"
                    component="h5"
                    sx={{
                      fontWeight: "bold",
                      color: "#fff",
                      paddingX: "1rem",
                      paddingY: "0.5rem",
                      fontFamily: '"Arial", sans-serif',
                    }}
                  >
                    Impact of Coaching
                  </Typography>
                </Stack>

                {/* <Stack sx={{ marginLeft: "1rem", marginTop: "1.5rem" }}>
            <EyeInfo eyeText="Please note that the information below in this section is pulling directly from the data you provided in the “outcomes” section of this platform. Only public items are shared. There is no action required of you on this page." />
          </Stack> */}
              </Stack>
            )}
            {clientName && generateChrSummaryData?.ratings?.length > 0 && (
              <Stack
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  marginTop: "2rem",
                }}
              >
                <Grid container>
                  {/* {toggleUserData?.length > 0 &&
                    toggleUserData[0]?.progressRatingScale && ( */}
                  <Grid container>
                    <Stack
                      sx={{
                        // display: "flex",
                        // flexDirection: "row",
                        width: "100%",
                      }}
                    >
                      {isMappingGrpahPdf()}
                    </Stack>
                  </Grid>
                  {/* )} */}
                </Grid>
              </Stack>
            )}
            <Stack sx={{ marginTop: "2rem" }}>
              {clientName && (
                <Stack
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    marginTop: "2rem",
                    backgroundColor: "#00468B",
                    color: "#fff",
                    padding: "0.5rem",
                  }}
                >
                  <Typography
                    variant="pageSecHeader"
                    sx={{ fontFamily: '"Arial", sans-serif' }}
                  >
                    ADDITIONAL COMMENTARY
                  </Typography>
                </Stack>
              )}

              {clientName && (
                <SummaryTableAdditionalCommentaryPdf data={dataOne} />
              )}
            </Stack>
          </Box>
        </PDFExport>
      </Box>
      {/* <PDFExport
        keepTogether="p"
        paperSize="A1"
        margin="2cm"
        ref={pdfExportComponent}
      > */}
      <Grid mt={4} container>
        <Grid item xs={12} sm={12} md={4}>
          <Typography
            sx={{
              fontSize: "1.50rem",
              fontWeight: "700",
              fontFamily: "dinBold,sans-serif",
              marginTop: "0.7rem",
            }}
            gutterBottom
            variant="h9"
            component="div"
          >
            Impact Summary for {clientName?.firstName} {clientName?.lastName}
          </Typography>
        </Grid>
        {clientName && (
          <Grid item xs={12} sm={12} md={8}>
            <Grid container>
              <Grid item xs={12} sm={12} md={4}>
                <TextField
                  enableReinitialize
                  sx={{ width: "100%" }}
                  inputProps={{
                    min:
                      clientName?.engagementStartDate &&
                      format(
                        new Date(clientName?.engagementStartDate),
                        "yyyy-MM-dd"
                      ),
                  }}
                  type="date"
                  name="startDate"
                  id="summary-start-date"
                  required={true}
                  value={startDate}
                  onChange={handleStartChange}
                  // sx={{width:"9rem"}}
                />
              </Grid>

              <Grid
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                item
                xs={12}
                sm={12}
                md={1}
              >
                <Typography
                  sx={{ fontSize: "1rem", paddingLeft: "0.2rem" }}
                  gutterBottom
                  variant="h9"
                  component="div"
                >
                  To
                </Typography>
              </Grid>

              <Grid item xs={12} sm={12} md={4}>
                <TextField
                  enableReinitialize
                  sx={{ paddingLeft: "0.2rem", width: "100%" }}
                  type="date"
                  name="endDate"
                  id="summary-end-date"
                  required={true}
                  value={endDate}
                  onChange={handleEndChange}
                />
              </Grid>
              {/* <Grid item xs={12} sm={12} md={4}></Grid> */}

              <Grid
                item
                xs={12}
                sm={12}
                md={2}
                sx={{ marginLeft: "2rem", mt: "0.5rem" }}
              >
                <FormSubmitButton
                  sx={{
                    textTransform: "none",
                    marginLeft: "0.5rem",
                    justifyContent: "center",
                  }}
                  type="submit"
                  variant="contained"
                  size="large"
                  onClick={handleGenerateMentorReports}
                >
                  Generate
                </FormSubmitButton>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>

      <Box sx={{ marginBottom: "1rem" }}>
        <HowToUse HowToUseContent={HowToUseContent} />
      </Box>

      {clientName && (
        <Stack
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            marginTop: "1rem",
          }}
        >
          <Grid container columnSpacing={2}>
            <Grid item xs={12} sm={12} md={6}>
              {clientName && (
                <Stack
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "start",
                    marginTop: "1rem",
                  }}
                >
                  <PageHeader variant="pageSecHeader">
                    {" "}
                    Level Of Engagement
                  </PageHeader>
                </Stack>
              )}
              <Stack sx={{}}>
                {clientName && <MuiDataGridForSummary data={dataTwo} />}
              </Stack>
            </Grid>

            <Grid item xs={12} sm={12} md={6}>
              {clientName && (
                <Stack
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "start",
                    marginTop: "1rem",
                  }}
                >
                  <PageHeader variant="pageSecHeader">
                    Engagement Notes
                  </PageHeader>
                </Stack>
              )}
              <Stack sx={{}}>
                {clientName && <MuiDataGridForSummary data={dataThree} />}
              </Stack>
            </Grid>
          </Grid>
        </Stack>
      )}

      <Grid sx={{ mt: "1rem" }} container spacing={2}>
        {toggleUserData?.length > 0 && toggleUserData[0]?.dateOfLastMeeting && (
          <Grid item xs={12} sm={12} md={6}>
            <Typography
              sx={{ fontSize: "1.125rem", fontWeight: "bold" }}
              gutterBottom
              variant="h9"
              component="div"
            >
              Date of Last Meeting:
              <span style={{ fontWeight: 400, paddingLeft: "0.6rem" }}>
                {Object.keys(generateChrSummaryData)?.length > 0 &&
                generateChrSummaryData?.checkin[0]?.dateOfLastMeeting
                  ? format(
                      new Date(
                        generateChrSummaryData?.checkin[0]?.dateOfLastMeeting
                      ),
                      "MM-dd-yyyy"
                    )
                  : ""}
              </span>
            </Typography>
          </Grid>
        )}

        {toggleUserData?.length > 0 &&
          toggleUserData[0]?.dateOfLastHrTouchpoint && (
            <Grid item xs={12} sm={12} md={6}>
              <Typography
                sx={{ fontSize: "1.125rem", fontWeight: "bold" }}
                gutterBottom
                variant="h9"
                component="div"
              >
                Date of Last HR Touch Point:
                <span style={{ fontWeight: 400, paddingLeft: "0.6rem" }}>
                  {Object.keys(generateChrSummaryData)?.length > 0 &&
                  generateChrSummaryData?.checkin[0]?.dateOfLastHrTouchPoint
                    ? format(
                        new Date(
                          generateChrSummaryData?.checkin[0]?.dateOfLastHrTouchPoint
                        ),
                        "MM-dd-yyyy"
                      )
                    : ""}
                </span>
              </Typography>
            </Grid>
          )}

        {toggleUserData?.length > 0 &&
          toggleUserData[0]?.dateOfLastManagerTouchpoint && (
            <Grid item xs={12} sm={12} md={6}>
              <Typography
                sx={{ fontSize: "1.125rem", fontWeight: "bold" }}
                gutterBottom
                variant="h9"
                component="div"
              >
                Date of Last Manager Touch Point:
                <span style={{ fontWeight: 400, paddingLeft: "0.6rem" }}>
                  {Object.keys(generateChrSummaryData)?.length > 0 &&
                  generateChrSummaryData?.checkin[0]
                    ?.dateOfLastManagerTouchPoint
                    ? format(
                        new Date(
                          generateChrSummaryData?.checkin[0]?.dateOfLastManagerTouchPoint
                        ),
                        "MM-dd-yyyy"
                      )
                    : ""}
                </span>
              </Typography>
            </Grid>
          )}
      </Grid>

      {clientName && (
        <Stack
          sx={{
            // display: "flex",
            // flexDirection: "row",
            // justifyContent: "space-between",
            mt: "2rem",
          }}
        >
          <Grid container>
            <Grid md={6}>
              <Stack
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  fontFamily: "arial",
                }}
              >
                <Typography
                  sx={{ fontSize: "1.125rem", fontWeight: "bold" }}
                  gutterBottom
                  variant="h9"
                  component="div"
                >
                  Engagement Key
                </Typography>
                <Typography
                  sx={{ fontSize: "0.90rem" }}
                  gutterBottom
                  variant="h9"
                  component="div"
                >
                  <span style={{ fontSize: "1rem", fontWeight: "bold" }}>
                    Green
                  </span>{" "}
                  Highly engaged with coach/set meetings regulary/leaning in.
                </Typography>
                <Typography
                  sx={{ fontSize: "0.90rem" }}
                  gutterBottom
                  variant="h9"
                  component="div"
                >
                  <span style={{ fontSize: "1rem", fontWeight: "bold" }}>
                    Yellow
                  </span>{" "}
                  Engaged when meeting but challenging to schedule with.
                </Typography>
                <Typography
                  sx={{ fontSize: "0.90rem" }}
                  gutterBottom
                  variant="h9"
                  component="div"
                >
                  <span style={{ fontSize: "1rem", fontWeight: "bold" }}>
                    Red
                  </span>{" "}
                  Goes long period without meeting with coach/not engaged.
                </Typography>
              </Stack>
            </Grid>

            {toggleUserData?.length > 0 &&
              toggleUserData[0]?.progressRatingScale && (
                <Grid container item md={6}>
                  <Grid item md={12} sm={12} xs={12}>
                    <Typography
                      sx={{
                        fontSize: "1.125rem",
                        fontWeight: "bold",
                        fontWeight: "bold",
                      }}
                      gutterBottom
                      variant="h9"
                      component="div"
                    >
                      Progress Scale Rating
                    </Typography>
                  </Grid>

                  <Grid item md={4} sm={12} xs={12}>
                    <Typography
                      sx={{ fontSize: "0.90rem" }}
                      gutterBottom
                      variant="h9"
                      component="div"
                    >
                      <span style={{ fontSize: "1rem", fontWeight: "bold" }}>
                        0
                      </span>{" "}
                      Baseline - No progress
                    </Typography>
                  </Grid>
                  <Grid item md={8} sm={12} xs={12}>
                    <Typography
                      sx={{ fontSize: "0.90rem" }}
                      gutterBottom
                      variant="h9"
                      component="div"
                    >
                      <span style={{ fontSize: "1rem", fontWeight: "bold" }}>
                        2
                      </span>{" "}
                      Significant Improvement
                    </Typography>
                  </Grid>

                  <Grid item md={4} sm={12} xs={12}>
                    <Typography
                      sx={{ fontSize: "0.90rem" }}
                      gutterBottom
                      variant="h9"
                      component="div"
                    >
                      <span style={{ fontSize: "1rem", fontWeight: "bold" }}>
                        1
                      </span>{" "}
                      Work in Progress
                    </Typography>
                  </Grid>

                  <Grid item md={8} sm={12} xs={12}>
                    <Typography
                      sx={{ fontSize: "0.90rem" }}
                      gutterBottom
                      variant="h9"
                      component="div"
                    >
                      <span style={{ fontSize: "1rem", fontWeight: "bold" }}>
                        3
                      </span>{" "}
                      Completed or Maintaining Successful Execution
                    </Typography>
                  </Grid>
                </Grid>
              )}
          </Grid>
        </Stack>
      )}
      {clientName && generateChrSummaryData?.ratings?.length > 0 && (
        <Stack
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
          }}
        >
          <Stack sx={{ marginTop: "1.5rem" }}>
            <PageHeader variant="pageHeader">Impact of Coaching</PageHeader>
          </Stack>
          <Stack sx={{ marginLeft: "1rem", marginTop: "1rem" }}>
            <EyeInfo
              eyeText="Please utilize the table below to leave private feedback for the leader’s Mentor regarding the ongoing engagement. Your feedback and any direct response that the Mentor provides will ONLY be visible to you and the Mentor. Neither the leader (mentee) nor any other stakeholders (including the ExCo account management team) will have access or visibility into the messages recorded below."
              isShow={true}
            />
          </Stack>
        </Stack>
      )}
      {clientName && generateChrSummaryData?.ratings?.length > 0 && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            width: "100%",
            "& > :not(style)": {
              // width: 450,
              minHeight: 200,
            },
          }}
        >
          <Grid container>
            {toggleUserData?.length > 0 &&
              toggleUserData[0]?.progressRatingScale && (
                <Grid item xs={12} sm={12} md={6}>
                  <Stack
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      width: "100%",
                    }}
                  >
                    {isMappingGrpah()}
                  </Stack>
                </Grid>
              )}
            <Grid sx={{ marginTop: "2rem" }} item xs={12} sm={12} md={6}>
              {!isPdfView
                ? generateChrSummaryData &&
                  generateChrSummaryData?.ratings?.map((data, index) => {
                    return (
                      <Stack>
                        {toggleUserData?.length > 0 &&
                          !toggleUserData[0]?.progressRatingScale && (
                            <Typography
                              sx={{
                                fontSize: "1rem",
                                marginTop: "1rem",
                                fontWeight: "bold",
                                marginLeft: "1rem",
                              }}
                            >
                              {data.otherDevelopmentAreaCoachingFocus}
                            </Typography>
                          )}
                        <Paper
                          key={data.id}
                          sx={{
                            height: 215,
                            marginTop:
                              index === 0 ||
                              (toggleUserData?.length > 0 &&
                                !toggleUserData[0]?.progressRatingScale)
                                ? "1.5rem"
                                : "3.4rem",
                            overflow: "auto",
                            marginLeft: "1rem",
                          }}
                          elevation={3}
                        >
                          <Typography
                            sx={{
                              fontSize: "1rem",
                              fontWeight: "bold",
                              paddingLeft: "1rem",
                              paddingRight: "1rem",
                            }}
                            gutterBottom
                            variant="h9"
                            component="div"
                          >
                            Comments
                          </Typography>

                          <Stack
                            sx={{ paddingLeft: "1rem", paddingRight: "1rem" }}
                          >
                            {commentId != data.id && (
                              <Stack sx={{ paddingBottom: "1rem" }}>
                                <Accordion sx={{ paddingTop: "1rem" }}>
                                  <AccordionDetails>
                                    <Typography>
                                      {data.mentorToClientRating.length > 0 &&
                                        data.mentorToClientRating?.at(0)
                                          .comment}
                                    </Typography>
                                  </AccordionDetails>
                                </Accordion>
                                <Typography
                                  sx={{ mt: "0.5rem", fontWeight: "bold" }}
                                >
                                  {data.mentorToClientRating.length > 0 &&
                                    format(
                                      new Date(
                                        data.mentorToClientRating?.at(
                                          0
                                        ).updatedOn
                                      ),
                                      "MM-dd-yyyy"
                                    )}
                                </Typography>
                              </Stack>
                            )}
                            {commentId != data.id &&
                              data.mentorToClientRating.length > 1 && (
                                <Stack
                                  sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "flex-end",
                                    marginTop: "0.5rem",
                                    paddingBottom: "1rem",
                                  }}
                                >
                                  <Button
                                    onClick={() => handlViewMoreClick(data.id)}
                                    size="small"
                                    variant="contained"
                                  >
                                    View More
                                  </Button>
                                </Stack>
                              )}
                            {commentId === data.id &&
                              data.mentorToClientRating.length > 0 &&
                              data.mentorToClientRating.map((dataTwo) => {
                                return (
                                  <Stack>
                                    <Accordion
                                      sx={{
                                        marginTop: "1rem",
                                        paddingTop: "1rem",
                                      }}
                                    >
                                      <AccordionDetails>
                                        <Typography>
                                          {dataTwo?.comment}
                                        </Typography>
                                      </AccordionDetails>
                                    </Accordion>

                                    <Typography sx={{ fontWeight: "bold" }}>
                                      {format(
                                        new Date(dataTwo?.updatedOn),
                                        "MM-dd-yyyy"
                                      )}
                                    </Typography>
                                  </Stack>
                                );
                              })}
                          </Stack>
                        </Paper>
                      </Stack>
                    );
                  })
                : generateChrSummaryData &&
                  generateChrSummaryData?.ratings?.map((dataTwo) => {
                    return (
                      <Stack>
                        <Stack sx={{ marginTop: "1rem", color: "blue" }}>
                          {
                            dataTwo?.mentorToClientRating[0]
                              ?.developmentAreasCoachingFocus
                              ?.developmentAreaCoachingFocusName
                          }
                        </Stack>
                        <Paper
                          key={dataTwo.id}
                          sx={{
                            // height: 215,
                            minHeight: 215,

                            // overflow: "auto",
                            marginLeft: "1rem",
                          }}
                          elevation={3}
                        >
                          {dataTwo.mentorToClientRating.length > 0 &&
                            dataTwo.mentorToClientRating.map((dataThree) => {
                              return (
                                <Stack>
                                  <Accordion sx={{ marginTop: "1rem" }}>
                                    <AccordionDetails>
                                      <Typography>
                                        {dataThree?.comment}
                                      </Typography>
                                    </AccordionDetails>
                                  </Accordion>

                                  <Typography>
                                    {format(
                                      new Date(dataThree?.createdOn),
                                      "MM-dd-yyyy"
                                    )}
                                  </Typography>
                                </Stack>
                              );
                            })}
                        </Paper>
                      </Stack>
                    );
                  })}
            </Grid>
          </Grid>
        </Box>
      )}
      {/* {clientName && (
      

        
      )} */}

      <Stack sx={{ marginTop: "2rem" }}>
        {clientName && (
          <Stack
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box sx={{ paddingTop: "1rem" }}>
              <PageHeader variant="pageSecHeader">
                Coaching Summary – Additional Commentary
              </PageHeader>
            </Box>
            <Stack sx={{ marginLeft: "1rem" }}>
              <EyeInfo
                eyeText="Please utilize the table below to leave private feedback for the leader’s Mentor regarding the ongoing engagement. Your feedback and any direct response that the Mentor provides will ONLY be visible to you and the Mentor. Neither the leader (mentee) nor any other stakeholders (including the ExCo account management team) will have access or visibility into the messages recorded below."
                isShow={true}
              />
            </Stack>
          </Stack>
        )}

        {clientName && <MuiDataGridForSummary data={dataOne} />}
      </Stack>

      {errMessage && <ErrorNotifier message={errMessage} />}
      {dateErrorMessageData && <ErrorNotifier message={dateErrorMessageData} />}

      {message && <Notifier message={message} />}
      {/* </PDFExport> */}

      <Box textAlign="center">
        <Button
          variant="contained"
          sx={{ margin: "1rem" }}
          onClick={async () => {
            // await setIsPdfView(true);
            if (pdfExportComponent.current) {
              // Make the hidden content visible just for PDF generation
              hiddenContentRef.current.style.display = "block";
              // Allow some time for the content to be fully rendered before generating the PDF
              setTimeout(() => {
                pdfExportComponent.current.save();

                // Hide the content again after PDF is generated
                hiddenContentRef.current.style.display = "none";
              }, 500);
            }
            // setIsPdfView(false);
          }}
          // onClick={() => onButtonClick()}
        >
          Download PDF
        </Button>
        {/* <a
            href={`${ASSESSMENT_BASE_URL}/v1/api/excel-parser/full-report?assessmentTrackingId=${location?.state?.clientSelectedData?.id}`}
            target="_blank"
            style={{
              textDecoration: 'none'
            }}
          > */}
        {/* <Button variant="contained" sx={{ margin: "1rem" }} onClick={() => Export2Word('report-content') }>
              Download DOCX
            </Button> */}
        {/* </a> */}
      </Box>
    </Layout>
  );
};

export default Reports;
