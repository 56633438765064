import React, { useState, useEffect } from "react";
// components
import Layout from "../../../layout/Layout";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import Paper from "@mui/material/Paper";
import { makeStyles } from "@mui/styles";
import StepLabel from "@mui/material/StepLabel";
import { MuiDataGrid } from "../../../components";
import { FormSubmitButton } from "../../../components";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import { PageHeader } from "@excoleadershipui/pionweblib";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import { useSelector, useDispatch } from "react-redux";
import MenuItem from "@mui/material/MenuItem";
import { GridCellExpand } from "../../../components";
import { MuiAutocomplete } from "../../../components";
import { DataGrid, GridToolbar, GridRow } from "@mui/x-data-grid";
import { getToggleUserData } from "../../../features/auth/authSlice";

import {
  getClientForCCHR,
  resetMessage,
  getClientFocus,
  getClientFocusPurpose,
} from "../../../features/chr/coaching_focus/ChrFocusSlice";
import { Grid } from "@mui/material";
import { format } from "date-fns";

const steps = ["Na", "Matching", "Chemistry", "Preparation", "Mentoring"];

const Assesment = () => {
  const [accountName, setAccountName] = useState("");
  const [clientName, setClientName] = useState(
    JSON.parse(localStorage.getItem("clientid"))
  );
  const [holder, setHolder] = useState("");
  const [allValue, setAllValue] = useState("");

  const {
    loading,
    message,
    errMessage,
    leadershipStrengthData,
    leadershipImprovementData,
    clientFocusPurposeCHRData,
    clientFocusData,
    clientCHRIdData,
    selectedData,
  } = useSelector((state) => ({ ...state.CHrCoachingFocus }));

  const { toggleUserData } = useSelector((state) => ({
    ...state.auth,
  }));


  const dispatch = useDispatch();

  function renderCellExpand(params) {
   
    return (
      <GridCellExpand
        value={params.value || ""}
        width={params.colDef.computedWidth}
      />
    );
  }
  const mentorGetDevValue = (params) => {

    return params?.row?.developmentAreas
      ?.developmentAreaName;
  };

  const mentorGetCoachingValue = (params) => {
    return params?.row?.developmentAreasCoachingFocus?.
      developmentAreaCoachingFocusName
  };


  const getCreatedOnDate = (params) => {
    return params.row.createdOn
        ? format(new Date(params.row.createdOn), "MM-dd-yyyy")
        : "";
  };
  
  const getUpdatedOnDate = (params) => {
    return params.row.updatedOn
      ? format(new Date(params.row.updatedOn), "MM-dd-yyyy")
      : ""
  
  };

  const data = {
    rows: clientCHRIdData,
    columns: [
      {
        field: "developmentArea",
        headerName: "Leadership Dimension",
        width: 200,
        renderCell: renderCellExpand,
        valueGetter: mentorGetDevValue,
      },
     
        {
          field: "focus",
          headerName: "Sub-Category",
          width: 200,
          renderCell: renderCellExpand,
          valueGetter: mentorGetCoachingValue,
          hide :   toggleUserData?.length > 0 &&
          toggleUserData[0]?.subCategory ? false :  true
        },

      {
        field: "otherDevelopmentAreaCoachingFocus",
        headerName: "Focus Area",
        width: 150,
        // valueGetter: mentorGetCoachingValue,
        renderCell: renderCellExpand,
      },

     

             {
          field: "expectedOutcome",
          headerName: "Expected Outcome",
          width: 300,
          renderCell: renderCellExpand,
          hide :   toggleUserData?.length > 0 &&
          toggleUserData[0]?.expectedOutcome ? false :  true
          
        },
        {
          field: "anticipatedStartDate",
          headerName: "Anticipated Start Date",
          width: 300,
          renderCell: renderCellExpand,
          hide :   toggleUserData?.length > 0 &&
          toggleUserData[0]?.anticipatedStartDate ? false :  true
        },
      {
        field: "date",
        headerName: "Date Added",
        width: 150,
        renderCell: renderCellExpand,
        valueGetter: getCreatedOnDate,
      },
      {
        field: "dateUpdated",
        headerName: "Date Updated",
        width: 150,
        valueGetter: getUpdatedOnDate,
      },


    ],
  };
  const dataTwo = {
    rows: clientFocusPurposeCHRData,
    columns: [
      {
        width: 900,
        field: "purposeOfEngagment",
        headerName: "Engagement Purpose Description",
        renderCell: renderCellExpand,
      },
      {
        field: "isDateAdded",
        headerName: "Date Added",
        width: 150,
        valueGetter: getCreatedOnDate,
      },
      {
        field: "dateUpdated",
        headerName: "Date Updated",
        width: 150,
        valueGetter: getUpdatedOnDate,
      },
    ],
  };
  useEffect(() => {
    clientName && dispatch(getClientForCCHR(clientName?.id));
    clientName && dispatch(getClientFocusPurpose(clientName?.id));
  }, [clientName]);

  useEffect(() => {
    dispatch(getClientFocus());
  }, []);

  useEffect(() => {
   
    dispatch(getToggleUserData(clientName?.id));
  }, []);


  return (
    <Layout>
      <Stack sx={{ marginTop: "1rem" }}>
        <PageHeader variant="pageHeader">
          Purpose of Engagement for{" "}
          {clientName && `${clientName?.firstName} ${clientName?.lastName}`}{" "}
        </PageHeader>

        {/* <Grid item xs={11} sm={11} md={6}>
            <Stack sx={{width:"13rem",marginLeft:"1rem"}}>
            <MuiAutocomplete
              name="clientName"
              options={clientFocusData .length> 0 ? clientFocusData:[] }
              setValue={(name, newValue) => setClientName(newValue)}
              value={clientName}
            
              innerLevel={true}
              
          />
            </Stack>
            </Grid> */}
      </Stack>
      {clientName && (
        <Stack>
          <Stack sx={{ marginTop: "0.5rem" }}>
            <MuiDataGrid
              toolbarColumn={true}
              toolbarFilter={true}
              toolbarDensity={true}
              toolbarExport={true}
              isRowHeight={true}
              data={dataTwo}
            />
          </Stack>

          <Stack
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "row",
              fontFamily: "arial",
              marginTop: "1rem",
              paddingBottom: "1rem",
            }}
          >
            <PageHeader variant="pageHeader">
              {" "}
              Focus Areas for {clientName?.firstName} {clientName?.lastName}{" "}
            </PageHeader>
          </Stack>

          <Stack sx={{ marginTop: "0.5rem" }}>
            <MuiDataGrid data={data} />
          </Stack>
        </Stack>
      )}
    </Layout>
  );
};

export default Assesment;
