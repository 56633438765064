import React, { useState, useEffect, useRef } from "react";

// components
import Layout from "../../layout/Layout";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import Paper from "@mui/material/Paper";
import { makeStyles } from "@mui/styles";
import StepLabel from "@mui/material/StepLabel";
import DownloadIcon from "@mui/icons-material/Download";

import CancelIcon from "@mui/icons-material/Cancel";
import {
  MuiDataGridLeadership,
  ModalCloseBtn,
  PopUpModel,
} from "../../components";

import { Grid, Card, CardHeader, CardContent, ListItem } from "@mui/material";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { PageHeader } from "@excoleadershipui/pionweblib";
import Notifier from "../../components/common/Notifier";
import ErrorNotifier from "../../components/common/ErrorNotifier";
import IconButton from "@mui/material/IconButton";


import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { BASE_URL } from "../../utils/config";
import CloseIcon from "@mui/icons-material/Close";
import * as Yup from "yup";
import { useFormik, Formik } from "formik";
import Stack from "@mui/material/Stack";
import { DataGrid, GridToolbar, GridRow } from "@mui/x-data-grid";
import { format } from "date-fns";
import { LoadingButton } from "@mui/lab";
import { CircularProgress } from "@mui/material";
import PreviewIcon from "@mui/icons-material/Preview";
import DriveFolderUploadIcon from "@mui/icons-material/DriveFolderUpload";
import PermIdentityOutlinedIcon from "@mui/icons-material/PermIdentityOutlined";
import ListItemIcon from "@mui/material/ListItemIcon";
import { MuiTooltip, CommonCurdPopUp, FormSubmitButton } from "../../components";


import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import { PDFExport } from "@progress/kendo-react-pdf";

import { GridCellExpand } from "../../components";

import HowToUse from "../../components/common/HowToUse";
import { Tooltip } from "@material-ui/core";
import LockIcon from "@mui/icons-material/Lock";
import VisibilityIcon from "@mui/icons-material/Visibility";

// redux
import { useSelector, useDispatch } from "react-redux";
import {
  getAssesmentImprovement,
  getAssesmentStrength,
  updateApproveStrength,
  rowSelect,
  updateApproveImprovement,
  resetErrMessage,
  getAssesmentHrButton,
  getAssesmentManagerButton,
  updatePrivateStrength,
  updatePrivateImprovement,
  updateImprovement,
  updateStrength,
  shareWithHr,
  shareWithManager,
  resetMessage,
  getLvaFileId,
  updatePrivateCoreValues,
  updateApproveCoreValues,
  getCoreValues,
  strengthRowFunc,
  improvemntRowFunc,
  updateStrengthMultiSelectPublic,
  updateStrengthMultiSelectApprove,
  updateStrengthMultiSelectPrivate,
  updateImprovementMultiSelectApprove,
  updateImprovementMultiSelectPrivate,
  updateImprovementMultiSelectPublic,
} from "../../features/assesment/assesmentSlice";

const steps = ["Na", "Matching", "Chemistry", "Preparation", "Mentoring"];

const validationStrengthSchema = Yup.object({
  strength: Yup.string().required("Strength is required"),
  description: Yup.string().required("Description is required"),
});

const validationImprovementSchema = Yup.object({
  areasOfimprovement: Yup.string().required(
    "Areas for Development  is required"
  ),
  Improvedescription: Yup.string().required("Description is required"),
});


const Assesment = ({ theme }) => {
  const {
    loading,
    message,
    errMessage,
    assesmentStrengthData,
    assesmentImprovementData,
    coreValuesData,
    assesmentButtonHrToggle,
    getLvaData,
    assesmentButtonManagerToggle,
    strengthRowSelectedData,
    improvementRowSelectedData,
    selectedData,
    publicPrivateStrengthLoading,
    publicPrivateImprovementLoading,
    strengthApproveLoading,
    improvementApproveLoading,
  } = useSelector((state) => ({ ...state.clientAssesment }));

  const dispatch = useDispatch();
  const pdfExportComponent = React.useRef(null);

  const [showStrengthEdit, setShowStrengthEdit] = useState(false);
  const [rowId, setRowId] = useState(null);
  const [showAssessmentEdit, setShowAssessmentEdit] = useState(false);
  const [showStrengthUpdate, setShowStrengthUpdate] = useState(false);
  const [showImprovementUpdate, setShowImprovementUpdate] = useState(false);
  const [showCoreValueEdit, setShowCoreValueEdit] = useState(false);
  const [isPrivateStrength, setIsPrivateStrength] = useState(false);
  const [isPrivateImprovement, setIsPrivateImprovement] = useState(false);
  const [isPrivateCoreValues, setIsPrivateCoreValues] = useState(false);
  const [showActiveButton, setShowActiveButton] = useState(true);
  const [isViewShow, setIsViewShow] = useState(false);
  const [isLeaderShipView, setIsLeaderShipView] = useState(false);

  const useStyles = makeStyles((theme) => ({
    previousSummaryClass: {
      [theme.breakpoints.down("md")]: {
        width: "15rem!important",
        marginTop: "1rem",
      },
      [theme.breakpoints.up("md")]: {
        width: "50% !important",
        marginTop: "1rem",
      },
    },
    responsive_width: {
      [theme.breakpoints.down("md")]: {
        width: "16.875rem !important",
        overflowX: "scroll",
      },
    },
    private_margin: {
      [theme.breakpoints.down("md")]: {
        marginLeft: "0 !important",
      },
    },
    root: {
      "& > *": {
        margin: theme.spacing(1),
      },
    },
  }));

  const prinatbleArea = useRef(null);

  const handleStrengthApproveClick = (data) => {
    const body = {
      strengthId: data,
    };
    dispatch(updateApproveStrength(body)).then((res) => {
      dispatch(getAssesmentStrength());
      if (res?.payload?.data?.ok) {
        setTimeout(() => {
          dispatch(resetMessage());
          dispatch(getAssesmentManagerButton());
          dispatch(getAssesmentHrButton());
        }, 0);
      }
      if (res?.payload?.error) {
        setTimeout(() => {
          dispatch(resetErrMessage());
        });
      }
    });
  };

  const handleImproventApproveClick = (data) => {
    const body = {
      areaOfImprovementId: data,
    };
    dispatch(updateApproveImprovement(body)).then((res) => {
      dispatch(getAssesmentImprovement());
      if (res?.payload?.data?.ok) {
        setTimeout(() => {
          dispatch(resetMessage());

          dispatch(getAssesmentManagerButton());
          dispatch(getAssesmentHrButton());
        }, 0);
      }
      if (res?.payload?.error) {
        setTimeout(() => {
          dispatch(resetErrMessage());
        });
      }
    });
  };

  const handleCoreValuesApproveClick = (data) => {
    const body = {
      leadershipCoreValueId: data,
    };
    dispatch(updateApproveCoreValues(body)).then((res) => {
      dispatch(getCoreValues());
      if (res?.payload?.data?.ok) {
        setTimeout(() => {
          dispatch(resetMessage());

          dispatch(getAssesmentManagerButton());
          dispatch(getAssesmentHrButton());
        }, 0);
      }
      if (res?.payload?.error) {
        setTimeout(() => {
          dispatch(resetErrMessage());
        });
      }
    });
  };

  const handleShareHrClick = () => {
    dispatch(shareWithHr()).then((res) => {
      if (res?.payload?.data?.ok) {
        setTimeout(() => {
          dispatch(resetMessage());
          dispatch(getAssesmentManagerButton());
          dispatch(getAssesmentHrButton());
        }, 2000);
      }
      if (res?.payload?.error) {
        setTimeout(() => {
          dispatch(resetErrMessage());
        });
      }
    });
  };

  const handleShareManagerClick = () => {
    dispatch(shareWithManager()).then((res) => {
      if (res?.payload?.data?.ok) {
        setTimeout(() => {
          dispatch(resetMessage());
          dispatch(getAssesmentManagerButton());
          dispatch(getAssesmentHrButton());
        }, 2000);
      }
      if (res?.payload?.error) {
        setTimeout(() => {
          dispatch(resetErrMessage());
        });
      }
    });
  };

  const getStatus = (params) => {
    return `${params.row.isPrivate ? "Private" : "Public"}`;
  };

  const getApprovedStatus = (params) => {
    if (params.row.status === "MENTOR::SUBMITTED") {
      return "SUBMITED";
    } else if (params.row.status === "NO::ACTION") {
      return "CREATED";
    } else {
      return "APPROVED";
    }
  };
  const style = {
    position: "absolute",
    top: "50%",
    left: "60%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    borderRadius: "5px",
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
    display: "flex",

    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  };

  const onImprovementSubmit = (e) => {
    const isTrue = isPrivateImprovement == "true";
    e.preventDefault();
    dispatch(
      updatePrivateImprovement({
        isPrivate: isPrivateImprovement === "true" ? true : false,
        areaOfImprovementId: rowId,
        // detailedDesc:selectedData?.detailedDesc
      })
    ).then((res) => {
      if (res?.payload?.data?.ok) {
        setTimeout(() => {
          dispatch(resetMessage());
          closeImprovementModel();
          dispatch(getAssesmentImprovement());
          dispatch(getAssesmentManagerButton());
          dispatch(getAssesmentHrButton());
        }, 2000);
      }
      if (res?.payload?.error) {
        setTimeout(() => {
          dispatch(resetErrMessage());
        }, 3000);
      }
    });
  };

  const clickPdfRenderer = () => {
    const win = window.open();
    if (prinatbleArea.current?.innerHTML) {
      win?.document.write(prinatbleArea.current.innerHTML);
      win?.print();
    }
    win?.close();
  };

  // enableReinitialize: true,
  const onStrengthSubmit = (e) => {
    const isTrue = isPrivateStrength == "true";
    e.preventDefault();
    dispatch(
      updatePrivateStrength({
        isPrivate: isPrivateStrength === "true" ? true : false,
        strengthId: rowId,
        // detailedDesc:selectedData?.detailedDesc
      })
    ).then((res) => {
      if (res?.payload?.data?.ok) {
        setTimeout(() => {
          dispatch(resetMessage());
          closeStrengthModel();
          dispatch(getAssesmentStrength());
          dispatch(getAssesmentManagerButton());
          dispatch(getAssesmentHrButton());
        }, 2000);
      }
      if (res?.payload?.error) {
        setTimeout(() => {
          dispatch(resetErrMessage());
        }, 3000);
      }
    });
  };

  const onCoreValuesSubmit = (e) => {
    const isTrue = isPrivateCoreValues == "true";
    e.preventDefault();
    dispatch(
      updatePrivateCoreValues({
        isPrivate: isPrivateCoreValues === "true" ? true : false,
        leadershipCoreValueId: rowId,
        // detailedDesc:selectedData?.detailedDesc
      })
    ).then((res) => {
      if (res?.payload?.data?.ok) {
        setTimeout(() => {
          dispatch(resetMessage());
          closeCoreValuesModel();
          dispatch(getCoreValues());
          dispatch(getAssesmentManagerButton());
          dispatch(getAssesmentHrButton());
        }, 2000);
      }
      if (res?.payload?.error) {
        setTimeout(() => {
          dispatch(resetErrMessage());
        }, 3000);
      }
    });
  };

  const useStylesTwo = makeStyles((theme) => ({
    customTooltip: {
      backgroundColor: "white",
      color: "black",
      fontWeight: "bold",
    },
    customArrow: {},
    responsive_width: {
      [theme.breakpoints.down("md")]: {
        width: "19.875rem !important",
        overflowX: "scroll !important",
      },
    },
    reponsive_textbox: {
      [theme.breakpoints.down("md")]: {
        width: "17rem !important",
      },
    },
  }));
  const classesTwo = useStylesTwo();
  const infoItems = [
    "Public items will be shareable with your Manager and HR once you have approved and submitted them.",
    "Private items will only be visible by you and your coach and will not be shared with any other stakeholders.",
  ];

  const tip = infoItems.join("\n");

  function getValueStregthIcon(params) {

    return (
      <div>
        <LoadingButton
          disabled={params.row.isClientApproved ? "disabled" : null}
          loadingIndicator={<CircularProgress size={20} />}
          loading={strengthRowSelectedData?.length > 0 && strengthRowSelectedData.some((data) => data === params.row.id) && strengthApproveLoading}
          // loading={strengthApproveLoading}
          type="submit"
          variant="contained"
          onClick={() => handleStrengthApproveClick(params.row.id)}
        //   className={classes.btnSubmit}
        >
          {params.row.isClientApproved ? "Approved" : "Approve"}
        </LoadingButton>


        {/* <Button
        
          variant="contained"
          color="primary"
          size="small"
     
        >
          {params.row.isClientApproved ? "Approved" : "Approve"}
        </Button> */}
      </div>
    );
  }

  function getValueIcon(params) {

    return (
      <div>
        <LoadingButton
          disabled={params.row.isClientApproved ? "disabled" : null}
          loadingIndicator={<CircularProgress size={20} />}
          // loading={improvementApproveLoading}

          loading={improvementRowSelectedData?.length > 0 && improvementRowSelectedData.some((data) => data === params.row.id) && improvementApproveLoading}
          type="submit"
          variant="contained"
          onClick={() => handleImproventApproveClick(params.row.id)}
        //   className={classes.btnSubmit}
        >
          {params.row.isClientApproved ? "Approved" : "Approve"}
        </LoadingButton>


        {/* <Button
          disabled={params.row.isClientApproved ? "disabled" : null}
          variant="contained"
          color="primary"
          size="small"
          onClick={() => handleImproventApproveClick(params.row.id)}
        >
          {params.row.isClientApproved ? "Approved" : "Approve"}
        </Button> */}
      </div>
    );
  }

  function getCoreValuesIcon(params) {

    return (
      <div>
        <Button
          disabled={params.row.isClientApproved ? "disabled" : null}
          variant="contained"
          color="primary"
          size="small"
          onClick={() => handleCoreValuesApproveClick(params.row.id)}
        >
          {params.row.isClientApproved ? "Approved" : "Approve"}
        </Button>
      </div>
    );
  }
  const openPublicModel = (data) => {
    setIsPrivateStrength(data.isPrivate ? "true" : "false");
    setShowStrengthEdit(true);
    setRowId(data.id);
  };

  const openPublicImprovementModel = (data) => {
    setIsPrivateImprovement(data.isPrivate ? "true" : "false");
    setShowAssessmentEdit(true);
    setRowId(data.id);
  };

  const openPublicCoreModel = (data) => {
    setIsPrivateCoreValues(data.isPrivate ? "true" : "false");
    setShowCoreValueEdit(true);
    setRowId(data.id);
  };

  function getPublicPrivateButton(params) {

    return (
      <div style={{ display: "flex", alignItems: "center" }}>
        {params.row.isPrivate ? (
          <LockIcon color="primary" style={{ marginRight: 8, }} />
        ) : (
          <VisibilityIcon color="primary" style={{ marginRight: 8, }} />
        )}

        <Button
          sx={{ width: "6rem" }}
          disabled={params.row.isClientApproved ? "disabled" : null}
          variant="contained"
          color="primary"
          size="small"
          onClick={() => openPublicModel(params.row)}
        >
          {!params.row.isPrivate ? "Public" : "Private"}
        </Button>
      </div>
    );
  }

  function getPublicPrivateImprovementButton(params) {

    return (
      <div style={{ display: "flex", alignItems: "flex-start" }}>
        {params.row.isPrivate ? (
          <LockIcon color="primary" style={{ marginRight: 8, }} />
        ) : (
          <VisibilityIcon color="primary" style={{ marginRight: 8, }} />
        )}
        <Button
          sx={{ width: "6rem" }}
          disabled={params.row.isClientApproved ? "disabled" : null}
          variant="contained"
          color="primary"
          size="small"
          onClick={() => openPublicImprovementModel(params.row)}
        >
          {!params.row.isPrivate ? "Public" : "Private"}
        </Button>
      </div>
    );
  }

  function getPublicPrivateCoreValuesButton(params) {

    return (
      <div>
        {params.row.isPrivate ? (
          <LockIcon style={{ marginRight: 8, color: "gray" }} />
        ) : (
          <VisibilityIcon style={{ marginRight: 8, color: "green" }} />
        )}
        <Button
          sx={{ width: "6rem" }}
          disabled={params.row.isClientApproved ? "disabled" : null}
          variant="contained"
          color="primary"
          size="small"
          onClick={() => openPublicCoreModel(params.row)}
        >
          {!params.row.isPrivate ? "Public" : "Private"}
        </Button>
      </div>
    );
  }

  function renderCellExpand(params) {

    return (
      <GridCellExpand
        value={params.value || ""}
        width={params.colDef.computedWidth}
      />
    );
  }
  const getCreatedOnDate = (params) => {
    return params.row.createdOn
      ? format(new Date(params.row.createdOn), "MM-dd-yyyy")
      : "";
  };

  const getUpdatedOnDate = (params) => {
    return params.row.updatedOn
      ? format(new Date(params.row.updatedOn), "MM-dd-yyyy")
      : ""

  };
  const data = {
    rows: assesmentStrengthData,
    columns: [
      {
        field: "strength",
        headerName: "Strength Identified ",
        width: 300,
        renderCell: renderCellExpand,
      },
      {
        field: "detailedDesc",
        headerName: "Description",
        width: 380,
        renderCell: renderCellExpand,
      },
      // {
      //   field: "ui",
      //   headerName: "Private/Public",
      //   renderCell: getPublicPrivateButton,
      //   valueGetter: (params) => (params.row.isPrivate ? "Private" : "Public"),
      //   width: 130,
      // },
      {
        field: "ui",
        headerName: "Private/Public",
        fontWeight: "bold",
        width: 150,

        valueGetter: (params) => (params.row.isPrivate ? "Private" : "Public"),

        renderCell: getPublicPrivateButton

        ,

        renderHeader: (params) => (
          <Tooltip
            classes={{
              tooltip: classesTwo.customTooltip,
              arrow: classesTwo.customArrow,
            }}
            title={<div style={{ whiteSpace: "pre-line" }}>{tip}</div>}
          >
            <div style={{ fontWeight: 500 }}>
              Private/Public
              {/* {console.log("params", params)}  */}
            </div>
          </Tooltip>
        ),
      },

      {
        field: "id",
        headerName: "Status",
        renderCell: getValueStregthIcon,
        valueGetter: (params) =>
          params.row.isClientApproved ? "Approved" : "Approve",
        width: 130,
      },
      {
        field: "date",
        headerName: "Date Added",
        width: 150,

        valueGetter: getCreatedOnDate,
      },
      {
        field: "dateUpdated",
        headerName: "Date Updated",
        width: 150,

        valueGetter: getUpdatedOnDate,
      },
    ],
  };

  const dataTwo = {
    rows: assesmentImprovementData,
    columns: [
      {
        field: "areaOfImprovement",
        headerName: "Dev Opp Identified",
        width: 300,
        renderCell: renderCellExpand,
      },
      {
        field: "detailedDesc",
        headerName: "Description",
        width: 380,
        renderCell: renderCellExpand,
      },
      // {
      //   field: "ui",
      //   headerName: "Private/Public",
      //   renderCell: getPublicPrivateImprovementButton,
      //   valueGetter: (params) => (params.row.isPrivate ? "Private" : "Public"),
      //   width: 130,
      // },
      {
        field: "ui",
        headerName: "Private/Public",
        fontWeight: "bold",
        width: 150,

        valueGetter: (params) => (params.row.isPrivate ? "Private" : "Public"),

        renderCell: getPublicPrivateImprovementButton,


        renderHeader: (params) => (
          <Tooltip
            classes={{
              tooltip: classesTwo.customTooltip,
              arrow: classesTwo.customArrow,
            }}
            title={<div style={{ whiteSpace: "pre-line" }}>{tip}</div>}
          >
            <div style={{ fontWeight: 500 }}>
              Private/Public
              {/* {console.log("params", params)}  */}
            </div>
          </Tooltip>
        ),
      },

      {
        field: "id",
        headerName: "Status",
        renderCell: getValueIcon,
        valueGetter: (params) =>
          params.row.isClientApproved ? "Approved" : "Approve",
        width: 130,
      },
      {
        field: "date",
        headerName: "Date Added",
        width: 150,

        valueGetter: getCreatedOnDate,
      },
      {
        field: "dateUpdated",
        headerName: "Date Updated",
        width: 150,

        valueGetter: getUpdatedOnDate,
      },
    ],
  };

  const dataThree = {
    rows: coreValuesData,
    columns: [
      {
        field: "coreValues",
        headerName: "Core Values",
        width: 300,
        renderCell: renderCellExpand,
      },
      {
        field: "detailedDesc",
        headerName: "Description",
        width: 380,
        renderCell: renderCellExpand,
      },
      {
        field: "ui",
        headerName: "Private/Public",
        renderCell: getPublicPrivateCoreValuesButton,
        valueGetter: (params) => (params.row.isPrivate ? "Private" : "Public"),
        width: 130,
      },
      {
        field: "id",
        headerName: "Status",
        renderCell: getCoreValuesIcon,
        valueGetter: (params) =>
          params.row.isClientApproved ? "Approved" : "Approve",
        width: 130,
      },
    ],
  };

  const isOpenLeadershipView = () => {
    setIsLeaderShipView(!isLeaderShipView);
  };

  const closeFileModel = () => {

    setIsViewShow(false);
  };

  const closeLeaderView = () => {
    setIsLeaderShipView(false);
  };
  const getStatusPdfView = (params) => {
    return `${params ? "Private" : "Public"}`;
  };
  const getApprovedStatusPdfView = (params) => {
    if (params === "CLIENT::APPROVED") {
      return "APPROVED";
    } else {
      return "APPROVE";
    }
  };

  useEffect(() => {
    dispatch(getAssesmentImprovement());
    dispatch(getAssesmentStrength());
    dispatch(getCoreValues());
    dispatch(getAssesmentManagerButton());
    dispatch(getAssesmentHrButton());
    dispatch(getLvaFileId());
  }, []);

  const classes = useStyles();

  const SubmitByHr = () => {
    return (
      <Button
        sx={{ marginLeft: "1rem" }}
        variant="contained"
        color="primary"
        size="small"
        onClick={handleShareHrClick}
        disabled={
          assesmentButtonHrToggle?.name === "Share To HR" ? null : "disabled"
        }
      >
        {assesmentButtonHrToggle?.name === "Share To HR"
          ? "Share With HR"
          : "Share With Hr"}
      </Button>
    );
  };

  const SubmitByManager = () => {
    return (
      <Button
        sx={{ marginLeft: "1rem" }}
        variant="contained"
        color="primary"
        size="small"
        onClick={handleShareManagerClick}
        disabled={
          assesmentButtonManagerToggle?.name === "Share To Manager"
            ? null
            : "disabled"
        }
      >
        {assesmentButtonManagerToggle?.name === "Share To Manager"
          ? "Share With Manager"
          : "Share With Manager"}
      </Button>
    );
  };

  const showStrengthUpdateModel = () => {

    setShowStrengthUpdate(!showStrengthUpdate);
  };

  const closeStrengthUpdateModel = () => {
    setShowStrengthUpdate(false);

  };



  const showUpdateImproventModel = () => {
    setShowImprovementUpdate(!showImprovementUpdate);
  };

  const closeImprovementUpdateModel = () => {
    setShowImprovementUpdate(false);

  };

  const closeStrengthModel = () => {
    setShowStrengthEdit(false);
  };

  const closeCoreValuesModel = () => {
    setShowCoreValueEdit(false);
  };

  const closeImprovementModel = () => {
    setShowAssessmentEdit(false);
  };

  const handleCheckChange = (event) => {
    setIsPrivateStrength(event.target.value);
  };

  const handleCoreValuesCheckChange = (event) => {
    setIsPrivateCoreValues(event.target.value);
  };

  const handleAssessCheckChange = (event) => {
    setIsPrivateImprovement(event.target.value);
  };

  const handleViewClick = () => {
    setIsViewShow(!isViewShow);
  };

  const onStrengthMultiSelectPrivate = (e) => {
    e.preventDefault();
    dispatch(
      updateStrengthMultiSelectPrivate({
        strengthId: strengthRowSelectedData,
        // detailedDesc:selectedData?.detailedDesc
      })
    ).then((res) => {
      if (res?.payload?.data?.ok) {
        setTimeout(() => {
          dispatch(resetMessage());

          dispatch(getAssesmentStrength());
          dispatch(getAssesmentManagerButton());
          dispatch(getAssesmentHrButton());
        }, 2000);
      }
      if (res?.payload?.error) {
        setTimeout(() => {
          dispatch(resetErrMessage());
        }, 3000);
      }
    });
  };

  const onStrengthMultiSelectPublic = (e) => {
    e.preventDefault();
    dispatch(
      updateStrengthMultiSelectPublic({
        strengthId: strengthRowSelectedData,
        // detailedDesc:selectedData?.detailedDesc
      })
    ).then((res) => {
      if (res?.payload?.data?.ok) {
        setTimeout(() => {
          dispatch(resetMessage());

          dispatch(getAssesmentStrength());
          dispatch(getAssesmentManagerButton());
          dispatch(getAssesmentHrButton());
        }, 2000);
      }
      if (res?.payload?.error) {
        setTimeout(() => {
          dispatch(resetErrMessage());
        }, 3000);
      }
    });
  };

  const onStrengthMultiSelectApprove = (e) => {
    e.preventDefault();
    dispatch(
      updateStrengthMultiSelectApprove({
        strengthId: strengthRowSelectedData,
        // detailedDesc:selectedData?.detailedDesc
      })
    ).then((res) => {
      if (res?.payload?.data?.ok) {
        setTimeout(() => {
          dispatch(resetMessage());

          dispatch(getAssesmentStrength());
          dispatch(getAssesmentManagerButton());
          dispatch(getAssesmentHrButton());
        }, 2000);
      }
      if (res?.payload?.error) {
        setTimeout(() => {
          dispatch(resetErrMessage());
        }, 3000);
      }
    });
  };

  //improvement


  const onImprovementMultiSelectPrivate = (e) => {
    e.preventDefault();
    dispatch(
      updateImprovementMultiSelectPrivate({
        areaOfImprovementId: improvementRowSelectedData,
        // detailedDesc:selectedData?.detailedDesc
      })
    ).then((res) => {
      if (res?.payload?.data?.ok) {
        setTimeout(() => {
          dispatch(resetMessage());

          dispatch(getAssesmentImprovement());
          dispatch(getAssesmentManagerButton());
          dispatch(getAssesmentHrButton());
        }, 2000);
      }
      if (res?.payload?.error) {
        setTimeout(() => {
          dispatch(resetErrMessage());
        }, 3000);
      }
    });
  };

  const onImprovementMultiSelectPublic = (e) => {
    e.preventDefault();
    dispatch(
      updateImprovementMultiSelectPublic({
        areaOfImprovementId: improvementRowSelectedData,
        // detailedDesc:selectedData?.detailedDesc
      })
    ).then((res) => {
      if (res?.payload?.data?.ok) {
        setTimeout(() => {
          dispatch(resetMessage());

          dispatch(getAssesmentImprovement());
          dispatch(getAssesmentManagerButton());
          dispatch(getAssesmentHrButton());
        }, 2000);
      }
      if (res?.payload?.error) {
        setTimeout(() => {
          dispatch(resetErrMessage());
        }, 3000);
      }
    });
  };

  const onImprovementMultiSelectApprove = (e) => {
    e.preventDefault();
    dispatch(
      updateImprovementMultiSelectApprove({
        areaOfImprovementId: improvementRowSelectedData,
        // detailedDesc:selectedData?.detailedDesc
      })
    ).then((res) => {
      if (res?.payload?.data?.ok) {
        setTimeout(() => {
          dispatch(resetMessage());

          dispatch(getAssesmentImprovement());
          dispatch(getAssesmentManagerButton());
          dispatch(getAssesmentHrButton());
        }, 2000);
      }
      if (res?.payload?.error) {
        setTimeout(() => {
          dispatch(resetErrMessage());
        }, 3000);
      }
    });
  };

  const HowToUseContent = `
Your Mentor/Coach can use this page to document key themes identified from the due diligence process and any stakeholder
 interviews they have conducted. When your Mentor/Coach sends you new themes for review, you will be able to categorize each
  as either "Private" or "Public" and give final approval for themes to be added to your Leadership Profile.`;

  const PrivateAndPublicContent = [
    { title: 'Private', description: 'Visible only to you and your Mentor/Coach; remains confidential.' },
    { title: 'Public', description: 'Data Can be shared with your Manager and HR partner(s) upon your approval.' },
  ];

  return (
    <Layout isClient={true}>
      {!isViewShow && !isLeaderShipView && (
        <Stack>
          <Stack
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              paddingTop: "1rem",
              marginLeft: "5rem",
              // width: "70vw"
            }}
          >
            <div style={{ width: "300px" }}></div>
            <Box sx={{ marginTop: "25px" }}> <PageHeader variant="pageHeader">Leadership Profile</PageHeader></Box>
            <Stack
              sx={{ marginLeft: "3rem" }}
            >
              {/* <MuiTooltip title="Leadership Profile">
                <ListItemIcon>
                  <PermIdentityOutlinedIcon
                    onClick={isOpenLeadershipView}
                    sx={{ cursor: "pointer" }}
                    fontSize="large"
                    color="primary"
                  />
                </ListItemIcon>
              </MuiTooltip> */}


              {/* <MuiTooltip title="View LVA"> */}
              <ListItemIcon sx={{ display: "flex", flexDirection: "column", alignItems: "center", width: "340px" }}>
                <MuiTooltip title="View LVA">
                  <PreviewIcon
                    onClick={handleViewClick}
                    sx={{ cursor: "pointer" }}
                    fontSize="large"
                    color="primary"
                    style={{ width: "70px", marginBottom: "10px" }}
                  />
                </MuiTooltip>
                <Typography sx={{ display: "flex", flexDirection: "column", alignItems: "center" }} variant="caption" color="textSecondary" >
                  <span> View LVA</span>
                  <span> (Confidential)</span>
                </Typography>
              </ListItemIcon>
              {/* </MuiTooltip> */}
            </Stack>
          </Stack>

          {/* <Box sx={{marginTop:"30px"}}> */}
          <HowToUse
            HowToUseContent={HowToUseContent}
            // HowToUseDescription={HowToUseDescription}
            PrivateAndPublicContent={PrivateAndPublicContent}
          />
          {/* </Box> */}

          <Stack
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "row",
              fontFamily: "arial",
              marginTop: "1rem",
              // paddingBottom: "1rem",
            }}
          >
            <Typography sx={{ fontSize: "1.5rem", fontWeight: "bold" }}>
              Strengths Identified
            </Typography>
          </Stack>
          <PopUpModel
            open={showStrengthEdit}
            handleClose={closeStrengthModel}
            closeModel={closeStrengthModel}
          >
            <Box
              sx={{ ...style, width: 700 }}
              className={classes.responsive_width}
              component="form"
              noValidate
            // onSubmit={onStrengthSubmit}
            >
              <ModalCloseBtn close={closeStrengthModel} />
              {/* <div  style={{width:"100%",display:"flex",justifyContent:"center"}}> */}
              {/* <div style={{width:"70%"}}>  */}
              <Stack sx={{ width: "100%" }}>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={isPrivateStrength}
                  onChange={handleCheckChange}
                >
                  <Grid container>
                    <Grid item xs={12} sm={12} md={5}>
                      <FormControlLabel
                        style={{ color: "black" }}
                        value={false}
                        control={<Radio />}
                        label="Public"
                      />
                      <Typography
                        sx={{ fontSize: "0.75rem" }}
                        gutterBottom
                        variant="h9"
                        component="div"
                      >
                        Public items will be shareable with your Manager and HR
                        once you have approved and submitted them.
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={5}
                      style={{ marginLeft: "2rem" }}
                      className={classes.private_margin}
                    >
                      <FormControlLabel
                        style={{ color: "black" }}
                        value={true}
                        control={<Radio />}
                        label="Private"
                      />
                      <Typography
                        sx={{ fontSize: "0.75rem" }}
                        gutterBottom
                        variant="h9"
                        component="div"
                      >
                        Private items will only be visible by you and your coach
                        and will not be shared with any other stakeholders.
                      </Typography>
                    </Grid>
                  </Grid>
                </RadioGroup>
              </Stack>
              {/* <Stack
          sx={{
            display: "flex",
            flexDirection: "column",
            fontFamily: "arial",
            marginTop:"1rem"
          }}
        > */}

              <Stack sx={{ display: "flex", flexDirection: "row" }}>
                <Stack></Stack>
                <Stack sx={{ marginLeft: "1rem" }}></Stack>
              </Stack>
              {/* </Stack> */}
              {/* <TextField
            sx={{ width: "100%", height: "5rem" }}
            label="Description"
            type="text"
            name="Improvedescription"
  
            margin="normal"
            variant="filled"
            id="Improvedescription"
          
          /> */}
              {/* </div> */}

              <LoadingButton
                loadingIndicator={<CircularProgress size={20} />}
                loading={loading}
                type="submit"
                variant="contained"
                onClick={onStrengthSubmit}
              //   className={classes.btnSubmit}
              >
                Submit
              </LoadingButton>

              {/* <FormSubmitButton
                type="submit"
                variant="contained"
                onClick={onStrengthSubmit}
              
              >
                Submit
              </FormSubmitButton> */}
            </Box>
          </PopUpModel>



          <MuiDataGridLeadership
            loading={publicPrivateStrengthLoading}
            showButton={true}
            selectArr={(data) => dispatch(strengthRowFunc(data))}
            // setShowEdit={() => setShowStrengthEdit(true)}
            data={data}
            sx={{ marginTop: "1rem" }}
            onMultiPublicClick={onStrengthMultiSelectPublic}
            onMultiPrivateClick={onStrengthMultiSelectPrivate}
            onMultiApproveClick={onStrengthMultiSelectApprove}
            select={(data) => dispatch(strengthRowFunc(data))}
            setShowEdit={!strengthRowSelectedData?.isClientApproved ?
              showStrengthUpdateModel : false}
          />



          <PopUpModel
            open={showAssessmentEdit}
            handleClose={closeImprovementModel}
            closeModel={closeImprovementModel}
          >
            <Box
              sx={{ ...style, width: 700 }}
              component="form"
              noValidate
            // onSubmit={formikImprovementEdit.handlSubmit}
            >
              <ModalCloseBtn close={closeImprovementModel} />
              {/* <div  style={{width:"100%",display:"flex",justifyContent:"space-around"}}>
      <div style={{width:"70%"}}> */}
              <Stack sx={{ width: "100%" }}>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={isPrivateImprovement}
                  onChange={handleAssessCheckChange}
                >
                  <FormControlLabel
                    style={{ color: "black" }}
                    value={false}
                    control={<Radio />}
                    label="Public"
                  />
                  <FormControlLabel
                    style={{ color: "black", marginLeft: "13.5rem" }}
                    value={true}
                    control={<Radio />}
                    label="Private"
                  />
                </RadioGroup>
              </Stack>

              <Stack sx={{ display: "flex", flexDirection: "row" }}>
                <Stack>
                  <Typography
                    sx={{ fontSize: "0.75rem" }}
                    gutterBottom
                    variant="h9"
                    component="div"
                  >
                    Public items will be shareable with your Manager and HR once
                    you have approved and submitted them.
                  </Typography>
                </Stack>
                <Stack sx={{ marginLeft: "1rem" }}>
                  <Typography
                    sx={{ fontSize: "0.75rem" }}
                    gutterBottom
                    variant="h9"
                    component="div"
                  >
                    Private items will only be visible by you and your coach and
                    will not be shared with any other stakeholders.
                  </Typography>
                </Stack>
              </Stack>
              {/* <TextField
            sx={{ width: "100%", height: "5rem" }}
            label="Description"
            type="text"
            name="Improvedescription"
          
            margin="normal"
            variant="filled"
            id="Improvedescription"
           
          /> */}

              <LoadingButton
                loadingIndicator={<CircularProgress size={20} />}
                loading={loading}
                type="submit"
                variant="contained"
                onClick={onImprovementSubmit}
              //   className={classes.btnSubmit}
              >
                Submit
              </LoadingButton>


              {/* </div>
      </div> */}
            </Box>
          </PopUpModel>

          <Formik
            enableReinitialize={true}
            initialValues={{
              strength: strengthRowSelectedData.strength,
              description: strengthRowSelectedData.detailedDesc,
              strengthStatus: strengthRowSelectedData.status,
            }}
            validationSchema={validationStrengthSchema}
            onSubmit={(values, { resetForm }) => {
              console.log(values);
              let data = {
                id: strengthRowSelectedData?.id,
                // excoCustomerUserId: clientName?.id,
                strength: values.strength,
                detailedDesc: values.description,
                // status: values?.strengthStatus,
              };
              dispatch(updateStrength(data)).then((res) => {
                if (res?.payload?.data?.ok) {
                  setTimeout(() => {
                    dispatch(resetMessage());
                    closeStrengthUpdateModel();
                    dispatch(getAssesmentStrength());
                  }, 2000);
                }
                if (res?.payload?.error) {
                  setTimeout(() => {
                    dispatch(resetErrMessage());
                    // closeModel();
                  }, 3000);
                }
                // resetForm({values:""})
              });
            }}
          >
            {({
              handleChange,
              handleSubmit,
              values,
              errors,
              touched,
              resetForm,
            }) => (
              <>


                {showStrengthUpdate && <CommonCurdPopUp
                  heading={`Update Strength`}
                  mediumScreen={true}
                  close={
                    () => setShowStrengthUpdate(false)
                  }
                >
                  <Grid container >
                    <Grid item xs={12} sm={12} md={0.5} lg={0.5}></Grid>
                    <Grid item xs={12} sm={12} md={11} >

                      <TextField
                        sx={{ width: "100%", height: "5rem" }}
                        label="Strengths Identified"
                        type="text"
                        name="strength"
                        required={true}
                        margin="normal"
                        variant="filled"
                        id="strength"
                        value={values?.strength}
                        onChange={handleChange("strength")}
                        error={touched.strength && Boolean(errors.strength)}
                        helperText={touched.strength && errors.strength}
                      />

                      <Stack sx={{ width: "100%" }}>
                        <Stack sx={{ fontSize: "0.8rem", alignSelf: "flex-end" }}>
                          {" "}
                          {values?.description?.length
                            ? 500 - values?.description?.length
                            : 500}{" "}
                          Characters remaining
                        </Stack>
                        <TextField
                          sx={{ width: "100%", height: "5rem" }}
                          label="Description"
                          type="text"
                          name="description"
                          required={true}
                          margin="normal"
                          variant="filled"
                          id="description"
                          value={values?.description}
                          onChange={handleChange("description")}
                          error={touched.description && Boolean(errors.description)}
                          helperText={
                            (touched.description && errors.description) ||
                            (values?.description?.length > 500 && (
                              <FormHelperText error={true} sx={{ color: "red" }}>
                                Description should have less than 500 characters
                              </FormHelperText>
                            ))
                          }
                        />


                      </Stack>

                      <div style={{ display: 'flex', flexDirection: "row", justifyContent: 'center', alignItems: 'center', width: "100%" }}>

                        <LoadingButton
                          onClick={handleSubmit}
                          loadingIndicator={<CircularProgress size={20} />}
                          loading={loading}
                          type="submit"
                          variant="contained"
                        //   className={classes.btnSubmit}
                        >
                          Update
                        </LoadingButton>
                      </div>

                    </Grid>
                  </Grid>

                </CommonCurdPopUp>}
              </>




              // <PopUpModel
              //   open={showStrengthUpdate}
              //   handleClose={() => {
              //     setShowStrengthUpdate(false);
              //     resetForm({
              //       values: "",
              //     });
              //   }}
              //   closeModel={() => {
              //     setShowStrengthUpdate(false);
              //     resetForm({
              //       values: "",
              //     });
              //   }}
              // >
              //   <Box
              //     sx={{ ...style, width: 700 }}
              //     className={classesTwo.responsive_width}
              //     component="form"
              //     noValidate
              //     onSubmit={handleSubmit}
              //   >
              //     <ModalCloseBtn
              //       close={() => {
              //         setShowStrengthUpdate(false);
              //         resetForm({
              //           values: "",
              //         });
              //       }}
              //     />
              //     <TextField
              //       sx={{ width: "100%", height: "5rem" }}
              //       label="Strengths Identified"
              //       type="text"
              //       name="strength"
              //       required={true}
              //       margin="normal"
              //       variant="filled"
              //       id="strength"
              //       value={values?.strength}
              //       onChange={handleChange("strength")}
              //       error={touched.strength && Boolean(errors.strength)}
              //       helperText={touched.strength && errors.strength}
              //     />

              //     <Stack sx={{ width: "100%" }}>
              //       <Stack sx={{ fontSize: "0.8rem", alignSelf: "flex-end" }}>
              //         {" "}
              //         {values?.description?.length
              //           ? 500 - values?.description?.length
              //           : 500}{" "}
              //         Characters remaining
              //       </Stack>
              //       <TextField
              //         sx={{ width: "100%", height: "5rem" }}
              //         label="Description"
              //         type="text"
              //         name="description"
              //         required={true}
              //         margin="normal"
              //         variant="filled"
              //         id="description"
              //         value={values?.description}
              //         onChange={handleChange("description")}
              //         error={touched.description && Boolean(errors.description)}
              //         helperText={
              //           (touched.description && errors.description) ||
              //           (values?.description?.length > 500 && (
              //             <FormHelperText error={true} sx={{ color: "red" }}>
              //               Description should have less than 500 characters
              //             </FormHelperText>
              //           ))
              //         }
              //       />


              //     </Stack>

              //     <LoadingButton
              //       loadingIndicator={<CircularProgress size={20} />}
              //       loading={loading}
              //       type="submit"
              //       variant="contained"
              //     //   className={classes.btnSubmit}
              //     >
              //       Update
              //     </LoadingButton>
              //   </Box>
              // </PopUpModel>
            )}
          </Formik>


          <Formik
            enableReinitialize={true}
            initialValues={{
              areasOfimprovement: improvementRowSelectedData?.areaOfImprovement,
              Improvedescription: improvementRowSelectedData?.detailedDesc,
              improvedStatus: improvementRowSelectedData?.status,
            }}
            validationSchema={validationImprovementSchema}
            onSubmit={(values, { resetForm }) => {
              let data = {
                id: improvementRowSelectedData?.id,
                // excoCustomerUserId: clientName?.id,
                areaOfImprovement: values.areasOfimprovement,
                detailedDesc: values.Improvedescription,
                // status: values?.improvedStatus,
              };
              dispatch(updateImprovement(data)).then((res) => {
                if (res?.payload?.data?.ok) {
                  setTimeout(() => {
                    dispatch(resetMessage());
                    closeImprovementUpdateModel();
                    dispatch(getAssesmentImprovement());
                    // resetForm({values:""})
                  }, 2000);
                }
                if (res?.payload?.error) {
                  setTimeout(() => {
                    dispatch(resetErrMessage());
                    // closeImprovementModel();
                    // resetForm({values:""})
                  }, 3000);
                }
              });
            }}
          >
            {({
              handleChange,
              handleSubmit,
              errors,
              touched,
              values,
              resetForm,
            }) => (

              <>



                {showImprovementUpdate && <CommonCurdPopUp
                  heading={`Update Opportunities`}
                  mediumScreen={true}
                  close={
                    closeImprovementUpdateModel
                  }
                >
                  <Grid container >
                    <Grid item xs={12} sm={12} md={0.5} lg={0.5}></Grid>
                    <Grid item xs={12} sm={12} md={11} >

                      <TextField
                        sx={{ width: "100%", height: "5rem" }}
                        label=" Opportunities for Development"
                        type="text"
                        name="areasOfimprovement"
                        required={true}
                        margin="normal"
                        variant="filled"
                        id="areasOfimprovement"
                        value={values.areasOfimprovement}
                        onChange={handleChange("areasOfimprovement")}
                        error={
                          touched.areasOfimprovement &&
                          Boolean(errors.areasOfimprovement)
                        }
                        helperText={
                          touched.areasOfimprovement && errors.areasOfimprovement
                        }
                      />
                      <Stack sx={{ width: "100%" }}>
                        <Stack sx={{ fontSize: "0.8rem", alignSelf: "flex-end" }}>
                          {" "}
                          {values?.Improvedescription?.length
                            ? 500 - values?.Improvedescription?.length
                            : 500}{" "}
                          Characters remaining
                        </Stack>
                        <TextField
                          sx={{ width: "100%", height: "5rem" }}
                          label="Description"
                          type="text"
                          name="Improvedescription"
                          required={true}
                          margin="normal"
                          variant="filled"
                          id="Improvedescription"
                          value={values.Improvedescription}
                          onChange={handleChange("Improvedescription")}
                          error={touched.description && Boolean(errors.description)}
                          helperText={
                            (touched.description && errors.description) ||
                            (values?.Improvedescription?.length > 500 && (
                              <FormHelperText error={true} sx={{ color: "red" }}>
                                Description should have less than 500 characters
                              </FormHelperText>
                            ))
                          }
                        />
                        {/* {updateImprovementCount<=500 && <Stack sx={{color:"#002984",fontWeight:"700"}} >{updateImprovementCount}/500 characters</Stack>} */}
                      </Stack>

                    </Grid>

                    <div style={{ display: 'flex', flexDirection: "row", justifyContent: 'center', alignItems: 'center', width: "100%" }}>

                      <LoadingButton

                        onClick={handleSubmit}
                        loadingIndicator={<CircularProgress size={20} />}
                        loading={loading}
                        type="submit"
                        variant="contained"
                        className={classes.btnSubmit}
                      >
                        Update
                      </LoadingButton>

                    </div>



                  </Grid>



                </CommonCurdPopUp>


                }
              </>



              // <PopUpModel
              //   open={showImprovementUpdate}
              //   handleClose={() => {
              //     closeImprovementUpdateModel();
              //     resetForm({ values: "" });
              //   }}
              //   closeModel={() => {
              //     closeImprovementUpdateModel();
              //     resetForm({ values: "" });
              //   }}
              // >
              //   <Box
              //     sx={{ ...style, width: 700 }}
              //     className={classesTwo.responsive_width}
              //     component="form"
              //     noValidate
              //     onSubmit={handleSubmit}
              //   >
              //     <ModalCloseBtn
              //       close={() => {
              //         closeImprovementUpdateModel();
              //         resetForm({ values: "" });
              //       }}
              //     />
              //     <TextField
              //       sx={{ width: "100%", height: "5rem" }}
              //       label=" Opportunities for Development"
              //       type="text"
              //       name="areasOfimprovement"
              //       required={true}
              //       margin="normal"
              //       variant="filled"
              //       id="areasOfimprovement"
              //       value={values.areasOfimprovement}
              //       onChange={handleChange("areasOfimprovement")}
              //       error={
              //         touched.areasOfimprovement &&
              //         Boolean(errors.areasOfimprovement)
              //       }
              //       helperText={
              //         touched.areasOfimprovement && errors.areasOfimprovement
              //       }
              //     />
              //     <Stack sx={{ width: "100%" }}>
              //       <Stack sx={{ fontSize: "0.8rem", alignSelf: "flex-end" }}>
              //         {" "}
              //         {values?.Improvedescription?.length
              //           ? 500 - values?.Improvedescription?.length
              //           : 500}{" "}
              //         Characters remaining
              //       </Stack>
              //       <TextField
              //         sx={{ width: "100%", height: "5rem" }}
              //         label="Description"
              //         type="text"
              //         name="Improvedescription"
              //         required={true}
              //         margin="normal"
              //         variant="filled"
              //         id="Improvedescription"
              //         value={values.Improvedescription}
              //         onChange={handleChange("Improvedescription")}
              //         error={touched.description && Boolean(errors.description)}
              //         helperText={
              //           (touched.description && errors.description) ||
              //           (values?.Improvedescription?.length > 500 && (
              //             <FormHelperText error={true} sx={{ color: "red" }}>
              //               Description should have less than 500 characters
              //             </FormHelperText>
              //           ))
              //         }
              //       />

              //     </Stack>


              //     <LoadingButton
              //       loadingIndicator={<CircularProgress size={20} />}
              //       loading={loading}
              //       type="submit"
              //       variant="contained"

              //     >
              //       Update
              //     </LoadingButton>
              //   </Box>
              // </PopUpModel>
            )}
          </Formik>

          <Stack
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "row",
              fontFamily: "arial",
              marginTop: "1rem",
              // paddingBottom: "1rem",
            }}
          >
            <Typography sx={{ fontSize: "1.5rem", fontWeight: "bold" }}>
              Opportunities for Development
            </Typography>
          </Stack>

          <MuiDataGridLeadership
            loading={publicPrivateImprovementLoading}
            viewAdd={false}
            data={dataTwo}
            selectArr={(data) => dispatch(improvemntRowFunc(data))}
            onMultiPublicClick={onImprovementMultiSelectPublic}
            onMultiPrivateClick={onImprovementMultiSelectPrivate}
            onMultiApproveClick={onImprovementMultiSelectApprove}
            select={(data) => dispatch(improvemntRowFunc(data))}
            setShowEdit={
              !improvementRowSelectedData?.isClientApproved ?
                showUpdateImproventModel : false}
          // select={(dataTwo) => dispatch(rowSelect(dataTwo))}
          />

          <Stack
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              fontFamily: "arial",
              marginTop: "1rem",
              paddingBottom: "1rem",
            }}
          >
            <SubmitByHr />
            <SubmitByManager />
          </Stack>
        </Stack>
      )}




      {isViewShow && (
        <Stack>
          <Stack
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              m: 3,
            }}
          >
            <Button
              sx={{ textTransform: "none" }}
              type="submit"
              variant="contained"
              //   className={classes.btnSubmit}
              onClick={closeFileModel}
            >
              Back to Leadership Profile
            </Button>
          </Stack>
          <iframe
            src={`${BASE_URL}/lva-document/static/${getLvaData?.fileName}`}
            width="95%"
            height="700rem"
          ></iframe>
        </Stack>
      )}

      {isLeaderShipView && (
        <Stack sx={{ marginTop: "3rem", paddingTop: "2rem" }}>
          <Grid sx={{ width: "100%" }}>
            <Paper sx={{ paddingBottom: "2rem" }}>
              <Stack
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                }}
              >
                <MuiTooltip title="Download">
                  <IconButton>
                    <DownloadIcon
                      onClick={() => {

                        if (pdfExportComponent.current) {
                          pdfExportComponent.current.save();
                        }

                      }}
                      sx={{ cursor: "pointer" }}
                      fontSize="large"
                      color="primary"
                    />
                  </IconButton>
                </MuiTooltip>

                <MuiTooltip title="Close">
                  <IconButton aria-label="cancel">
                    <CancelIcon
                      onClick={closeLeaderView}
                      sx={{ cursor: "pointer" }}
                      color="red"
                    />
                  </IconButton>
                </MuiTooltip>
              </Stack>

              <PDFExport
                keepTogether="p"
                paperSize="A1"
                margin="2cm"
                ref={pdfExportComponent}>

                <Stack ref={prinatbleArea} sx={{ width: "100%" }}>
                  <Stack
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "1.5rem",
                        fontWeight: "bold",
                        marginTop: "1rem",
                      }}
                      variant="subtitle1"
                      component="div"
                    >
                      Leadership Profile
                    </Typography>
                  </Stack>

                  {assesmentStrengthData.length > 0 && (
                    <TableContainer component={Paper}>
                      <Table sx={{ minWidth: 650 }} aria-label="a dense table">
                        <TableHead sx={{ background: "#EBECF0" }}>
                          <TableRow>
                            <TableCell width={700}>
                              Strengths Identified
                            </TableCell>
                            <TableCell>Private/Public</TableCell>
                            <TableCell>Status</TableCell>
                            <TableCell>Date Added</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {assesmentStrengthData.length > 0 &&
                            assesmentStrengthData.map((data) => (
                              <TableRow
                                key={data.id}
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                }}
                              >
                                <TableCell width={700} component="th" scope="row">
                                  {data.strength}
                                </TableCell>
                                <TableCell>
                                  {getStatusPdfView(data.isPrivate)}
                                </TableCell>
                                <TableCell>
                                  {getApprovedStatusPdfView(data.status)}
                                </TableCell>
                                <TableCell>
                                  {data?.updatedOn
                                    ? data?.updatedOn
                                      ? format(
                                        new Date(data?.updatedOn),
                                        "MM-dd-yyyy"
                                      )
                                      : ""
                                    : data?.createdOn
                                      ? format(
                                        new Date(data?.createdOn),
                                        "MM-dd-yyyy"
                                      )
                                      : ""}
                                </TableCell>
                              </TableRow>
                            ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  )}

                  {assesmentImprovementData.length > 0 && (
                    <TableContainer sx={{ marginTop: "3rem" }} component={Paper}>
                      <Table sx={{ minWidth: 650 }} aria-label="a dense table">
                        <TableHead>
                          <TableRow sx={{ background: "#EBECF0" }}>
                            <TableCell width={700}>
                              {" "}
                              Opportunities for Development
                            </TableCell>
                            <TableCell>Private/Public</TableCell>
                            <TableCell>Status</TableCell>
                            <TableCell>Date Added</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {assesmentImprovementData.length > 0 &&
                            assesmentImprovementData.map((data) => (
                              <TableRow
                                key={data.id}
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                }}
                              >
                                <TableCell width={700} component="th" scope="row">
                                  {data.areaOfImprovement}
                                </TableCell>
                                <TableCell>
                                  {getStatusPdfView(data.isPrivate)}
                                </TableCell>
                                <TableCell>
                                  {getApprovedStatusPdfView(data.status)}
                                </TableCell>
                                <TableCell>
                                  {data?.updatedOn
                                    ? data?.updatedOn
                                      ? format(
                                        new Date(data?.updatedOn),
                                        "MM-dd-yyyy"
                                      )
                                      : ""
                                    : data?.createdOn
                                      ? format(
                                        new Date(data?.createdOn),
                                        "MM-dd-yyyy"
                                      )
                                      : ""}
                                </TableCell>
                              </TableRow>
                            ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  )}
                </Stack>
              </PDFExport>
            </Paper>
          </Grid>
        </Stack>
      )}
      {/* src={`${BASE_URL}/lva-document/static`} */}
      {errMessage && <ErrorNotifier message={errMessage} />}
      {message && <Notifier message={message} />}
    </Layout>
  );
};

export default Assesment;
