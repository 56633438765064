import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

// routes
import {  GET_REPORTS_AREA_FOCUS, GET_REPORTS_ENGAGEMENT_STATUS,GET_ENGAGEMENT_DETAIL,GET_RATING_BY_CLIENT
 , CLIENT_LATEST_OUTCOMES, GET_ALL_PREVIOUS_OUTCOMES_DATA,CLIENT_PREVIOUS_OUTCOMES_DATA_BY_ID,
 SHARE_WITH_HR_OUTCOMES,SHARE_WITH_MANAGER_OUTCOMES,ACCEPT_BY_CLIENT_OUTCOME ,
 GET_TOGGLE_DATA,UPDATE_IMPACT_UPDATES_DESCRIPTION,UPDATE_IMPACT_UPDATES_ENGAGEMENT_NOTES,
 UPDATE_CLIENT_RATING_SUBMIT,UPDATE_CLIENT_RATING_API
} from "../../utils/routes";

// ---------------------------------------------------------------------



export const getRatingByclientId = createAsyncThunk(
  "rating/getRatingForClients",
  async () => {
    return axios.get(GET_RATING_BY_CLIENT, { withCredentials: true });
  }
);

export const getToogleData = createAsyncThunk(
  "toggle/getToggleForClients",
  async () => {
    return axios.get(GET_TOGGLE_DATA, { withCredentials: true });
  }
);

export const getLatestOutcomesForClient = createAsyncThunk(
  "outcomes/getLatestOutcomes",
  async (data) => {
    return axios.post(CLIENT_LATEST_OUTCOMES ,data, { withCredentials: true });
  }
);

export const getEngagementNotes = createAsyncThunk(
  "reports/getEngagement",
  async () => {
    return axios.get( GET_REPORTS_ENGAGEMENT_STATUS, { withCredentials: true });
  }
);

export const getPreviousOutcomeData = createAsyncThunk(
  "outcome/ratingData",
  async (data) => {
    return axios.post(GET_ALL_PREVIOUS_OUTCOMES_DATA,data, { withCredentials: true });
  }
);

export const updateImpactUpdatesDescription = createAsyncThunk(
  "engagement/updateImpactUpdatesDescription",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.put(UPDATE_IMPACT_UPDATES_DESCRIPTION, data, {
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateEngagementNotes = createAsyncThunk(
  "engagement/updateImpactUpdatesEngagementNotes",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.put(UPDATE_IMPACT_UPDATES_ENGAGEMENT_NOTES, data, {
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);


export const updateEnagagementDetail = createAsyncThunk(
  "engagement/updateEngagement",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.put( GET_ENGAGEMENT_DETAIL, data, {
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateClientRating = createAsyncThunk(
  "ClientUpdate/rating",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.put(UPDATE_CLIENT_RATING_API, data, {
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const submitPreviousOutcomesForClients = createAsyncThunk(
  "rating/clientOutcomes",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(CLIENT_PREVIOUS_OUTCOMES_DATA_BY_ID, data, {
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateCheckinRating = createAsyncThunk(
  "client/updateRating",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(UPDATE_CLIENT_RATING_SUBMIT, data, {
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);



export const shareWithHrOutcomes = createAsyncThunk(
  "clientOutcomes/shareWithHrOutcomes",
  async (data, { rejectWithValue }) => {
    console.log(data)
    try {
      const response = await axios.post(SHARE_WITH_HR_OUTCOMES, data, {
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const shareWithManagerOutcomes = createAsyncThunk(
  "clientOutcomes/shareWithManagerOutcoemes",
  async (data, { rejectWithValue }) => {
    console.log(data)
    try {
      const response = await axios.post(SHARE_WITH_MANAGER_OUTCOMES , data, {
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const acceptByClientHandle = createAsyncThunk(
  "clientOutcomes/acceptOutcome",
  async (data, { rejectWithValue }) => {
    console.log(data)
    try {
      const response = await axios.post(ACCEPT_BY_CLIENT_OUTCOME, data, {
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteEngagementDetail = createAsyncThunk(
  "customer/deleteCustomer",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.delete(GET_ENGAGEMENT_DETAIL, {
        data,
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

const  engagementSlice = createSlice({
  name: "reports",
  initialState: {
    loading: false,
    message: "",
    errMessage: "",
    areaFocusData: [],
    engagementFocusData:[],
    previousOutcomeData:[],
    clientRatingData:[],
    selectedData: {},
    clientToggleData :[]
  },
  reducers: {
    resetMessage: (state) => {
      state.message = "";
    },
    resetErrMessage: (state) => {
      state.errMessage = "";
    },
    rowSelect: (state, action) => {
      state.selectedData = action?.payload.length > 0 && action?.payload[0];
    },
    emptyingArray: (state, action) => {
      state.clientRatingData = [];
    },

  },
  extraReducers: {
    [updateImpactUpdatesDescription.pending]: (state, action) => {
      state.loading = true;
    },
    [updateImpactUpdatesDescription.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [updateImpactUpdatesDescription.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },

    [updateEngagementNotes.pending]: (state, action) => {
      state.loading = true;
    },
    [updateEngagementNotes.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [updateEngagementNotes.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },
    [updateCheckinRating.pending]: (state, action) => {
      state.loading = true;
    },
    [updateCheckinRating.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [updateCheckinRating.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },


    [getRatingByclientId.pending] : (state, action) => {
      state.loading = true;
    },
    [getRatingByclientId.fulfilled]: (state, action) => {
      console.log(action?.payload?.data)
      state.loading = false;
      state.clientRatingData = action?.payload?.data;
    },
    [getRatingByclientId.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.data?.message;
    },

    [getToogleData.pending] : (state, action) => {
      state.loading = true;
    },
    [getToogleData.fulfilled]: (state, action) => {
      console.log(action?.payload?.data)
      state.loading = false;
      state.clientToggleData = action?.payload?.data;
    },
    [getToogleData.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.data?.message;
    },


    [submitPreviousOutcomesForClients.pending] : (state, action) => {
      state.loading = true;
    },
    [submitPreviousOutcomesForClients.fulfilled]: (state, action) => {
     
      state.loading = false;
      state.clientRatingData = action?.payload?.data;
    },
    [submitPreviousOutcomesForClients.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.data?.message;
    },

   
  
    [getPreviousOutcomeData.pending] : (state, action) => {
      state.loading = true;
    },
    [getPreviousOutcomeData.fulfilled]: (state, action) => {
      console.log(action?.payload?.data)
      state.loading = false;
      state.previousOutcomeData = action?.payload?.data;
    },
    [getPreviousOutcomeData.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.data?.message;
    },
    [getLatestOutcomesForClient.pending] : (state, action) => {
      state.loading = true;
    },
    [getLatestOutcomesForClient.fulfilled]: (state, action) => {
      console.log(action?.payload?.data)
      state.loading = false;
      state.clientRatingData = action?.payload?.data;
    },
    [getLatestOutcomesForClient.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.data?.message;
    },
    [getEngagementNotes.pending]: (state, action) => {
      state.loading = true;
    },
    [getEngagementNotes.fulfilled]: (state, action) => {
      console.log(action?.payload?.data)
      state.loading = false;
      state.engagementFocusData = action?.payload?.data;
    },
    [getEngagementNotes.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.data?.message;
    },
    [acceptByClientHandle.pending]: (state, action) => {
      state.loading = true;
    },
    [acceptByClientHandle.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [acceptByClientHandle.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },
    [shareWithHrOutcomes.pending]: (state, action) => {
      state.loading = true;
    },
    [shareWithHrOutcomes.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [shareWithHrOutcomes.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },
    [shareWithManagerOutcomes.pending]: (state, action) => {
      state.loading = true;
    },
    [shareWithManagerOutcomes.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [shareWithManagerOutcomes.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },
    [updateEnagagementDetail.pending]: (state, action) => {
      state.loading = true;
    },
    [updateEnagagementDetail.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [updateEnagagementDetail.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },
    [updateClientRating.pending]: (state, action) => {
      state.loading = true;
    },
    [updateClientRating.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [updateClientRating.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },

    [deleteEngagementDetail.pending]: (state, action) => {
      state.loading = true;
    },
    [deleteEngagementDetail.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [deleteEngagementDetail.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },
  },
});




export const { resetMessage, resetErrMessage, rowSelect,emptyingArray } = engagementSlice.actions;
export default  engagementSlice.reducer;


