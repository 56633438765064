import React from "react";
import { Link } from "react-router-dom";
// material ui
import { Typography, Box, IconButton } from "@mui/material";
// image
import pagenotfoundImage from "../../assets/images/pagenotfound.jpg";

// --------------------------------------------------------

const PageNotFound = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
        minHeight: "100vh", // Full height of the viewport
      }}
    >
      {/* <Typography variant="h4">Oops..! 404 Page Not Found</Typography> */}
      <Typography sx={{ fontSize: "1.3rem" }} variant="body1">
        Access Denied: Your account is not authorized to log in. Please contact your administrator to add your email ID to the system.
      </Typography>
      <Box mt={2}>
        <Typography variant="body1">
          <IconButton onClick={() => window.location.href = "/"} >
            <Link>Back to Home</Link>
          </IconButton>

        </Typography>
      </Box>
      {pagenotfoundImage && (
        <img src={pagenotfoundImage} height="500" width="500" alt="Access Denied - Page Not Found Image" />
      )}
    </Box>

  );
};

export default PageNotFound;
