import React, { useEffect, useState } from "react";

// components
import Layout from "../../layout/Layout";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import { makeStyles, withStyles } from "@mui/styles";
import StepLabel from "@mui/material/StepLabel";
import { MuiDataGrid } from "../../components";
import { PageHeader } from "@excoleadershipui/pionweblib";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CircleIcon from "@mui/icons-material/Circle";
import Typography from "@mui/material/Typography";
import { countries, continents } from "countries-list";
import { styled } from "@mui/material/styles";
import Stack from "@mui/material/Stack";
import { WithStyles } from "@mui/styles";
import { DataGrid, GridToolbar, GridRow } from "@mui/x-data-grid";
import Attachments from "../../components/common/upload_documents/Attachments";
import { getEngagementDocuments,deleteClientDocument, createEngagementDocuments, resetErrMessage, resetMessage } from '../../features/dashboard/engagementDocumentSlice';
import { getEnagagementScopeDetails } from "../../features/hr/engagement/engagementSlice";

import { format } from "date-fns";

//redux-toolkit
import { useDispatch, useSelector } from "react-redux";
import {
  getEngagementDetails,
  getEngagementStage,
  getCurrentMentorName,
} from "../../features/dashboard/dashBoardSlice";
import { formLabelClasses } from "@mui/material";
import { GridCellExpand } from "../../components";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
const steps = [
  "Scoping",
  "Matching",
  "Chemistry",
  "LA Prep",
  "LA",
  "Immediate Follow-Up",
  "LA Debrief",
  "Ongoing Coaching",
  "Limited Interviews",
  "Renewal",
  "Close Out",
];

const useStyles = makeStyles((theme) => ({
  responsive_header_width: {
    [theme.breakpoints.down("md")]: {
      width: "19.5rem !important",
    },
  },
  step: {
    "& $completed": {
      color: "lightgreen",
    },
    "& $active": {
      color: "pink",
    },
    "& $disabled": {
      color: "red",
    },
  },
  icon: {
    fill: "#FFFFFF !important",
  },
  text: {
    fill: "#000000 !important",
  },
}));

const Dashboard = () => {
  const dispatch = useDispatch();

  const {
    
  
    engagementsData,
    engagementStageData,
    mentorCurrentDetails,
    selectedData,
  } = useSelector((state) => ({ ...state.clientEngagement }));

  const {
    message,
    errMessage,
    engagementsDocumentsData,
    loading,
  } = useSelector((state) => ({ ...state.clientDocuments }));

  const { keyClientAllEngagementData, } = useSelector(
    (state) => ({ ...state.hrEngagement })
  );

  const [clientEngagementId, setClientEngagementId] = useState(null);

  const matchingDate = (params) => {
    return (
      params?.row?.
        engagementStartDate
      && format(new Date(params?.row?.
        engagementStartDate), "MM-dd-yyyy")
    );
  };
  const chemistryValue = (params) => {
    let result =
      params.row.keyClientEngagement.length > 0 &&
      params.row.keyClientEngagement.map((data) => data.engagementDate);
    let finalResult = result.length > 0 && result.filter((data) => data);
    return format(new Date(finalResult[0]), "dd-MM-yyyy");
  };

  const AccelaratorValue = (params) => {
    let result =
      params?.row?.keyClientEngagement.length > 0 &&
      params?.row?.keyClientEngagement?.map((data) => data.status);
    return result;
  };

  function renderCellExpand(params) {

    return (
      <GridCellExpand
        value={params.value || ""}
        width={params.colDef.computedWidth}
      />
    );
  }

  

  const data = {
    rows: engagementsData?.data ? engagementsData?.data : [],
    columns: [
      {
        field: "engagementStageName",
        headerName: "Event Name",
        width: 300,
      },
      {
        field: "status",
        headerName: "Status",
        width: 200
      },
      {
        field: "engagementDate",
        headerName: "Date",
        width: 200,
        valueGetter: matchingDate,
      },
     
      {
        field: "comments",
        headerName: "Comments",
        width: 500,
        renderCell: renderCellExpand,
      },
    ],
  };

  let stepperResult =
    engagementStageData.length > 0 &&
    engagementStageData[0]?.clientEngagement?.KeyClientEngagement
      ?.engagementStageName;

  useEffect(() => {
    dispatch(getEngagementDetails());
    dispatch(getEngagementStage());
    dispatch(getCurrentMentorName());
  }, []);

  useEffect(() => {
    if (engagementsData?.clientEngagement?.length > 0) {
      const id = engagementsData?.clientEngagement[0]?.id;
      setClientEngagementId(id);
    }
  }, [engagementsData]);

  console.log(clientEngagementId
  )

  useEffect(() => {
    let enagementDate =
      engagementStageData.length > 0 &&
      engagementStageData[0]?.clientEngagement?.engagementStartDate;
    localStorage.setItem(
      "clientId",
      JSON.stringify({ engagementStartDate: enagementDate })
    );
  }, [engagementStageData]);

  useEffect(() => {
    engagementsData.length > 0 &&
      localStorage.setItem(
        "engagementStartDate",
        engagementsData[0]?.clientEngagement?.engagementStartDate
      );
  }, [engagementsData]);

  const c = useStyles();

  const latestStage = [];
  engagementsData.length > 0 &&
    engagementsData.map((eng) => {
      latestStage.push(eng);
    });
  latestStage.reverse();

  const QontoConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
      top: 10,
      left: "calc(-50% + 16px)",
      right: "calc(50% + 16px)",
    },
  }));

  let classesTwo = useStyles(QontoConnector);

  let check = false;

  let engagementData =
    keyClientAllEngagementData?.length > 0 && keyClientAllEngagementData[0];


  console.log("Data", engagementsData)

  return (
    <Layout isClient={true}>
      <Box sx={{ width: "100%" }}>
        <Stack
          sx={{
            paddingTop: "1rem",
            marginTop: "2rem"
          }}
        >
          <PageHeader variant="pageHeader">
            {" "}
            Your Mentor :{" "}
            {mentorCurrentDetails?.excoMentor?.excoMentorDetail?.firstName?.toUpperCase()}{" "}
            {mentorCurrentDetails?.excoMentor?.excoMentorDetail?.lastName?.toUpperCase()} (
            {mentorCurrentDetails?.excoMentor?.email})
          </PageHeader>
        </Stack>

        <Grid container>
          <Grid item xs={11} sm={11} md={6}>
            <Grid container md={12}>
              <Grid item xs={6} sm={6} md={3.5}>
                <Typography
                  sx={{
                    fontSize: ".75rem",
                    whiteSpace: "nowrap",
                    marginTop: "1rem"
                  }}
                  gutterBottom
                  variant="h9"
                  component="div"
                >
                  <span style={{ fontWeight: "normal", fontSize: "1.5em" }}>
                    Launch/Start Date
                  </span>{" "}
                </Typography>
              </Grid>
              <Grid item xs={6} sm={6} md={3}>
                <Typography
                  sx={{
                    fontSize: "1rem",
                    whiteSpace: "nowrap",
                    marginTop: "1rem"
                  }}
                  gutterBottom
                  variant="h9"
                  component="div"
                >
                  <span style={{ fontWeight: "bold", fontSize: "1rem" }}>
                    :
                  </span>{" "}
                  {engagementsData?.data?.[0]?.clientEngagement?.engagementStartDate
                    ? format(
                      // subtractOneDay(
                      new Date(
                        engagementsData?.data?.[0]?.clientEngagement?.engagementStartDate
                      // )
                      ),
                      "MM-dd-yyyy"
                    )
                    : ""}
                </Typography>
              </Grid>
            </Grid>

            <Grid container md={12}>
              <Grid item xs={6} sm={6} md={3.5}>
                <Typography
                  sx={{
                    fontSize: "0.75rem",
                    whiteSpace: "nowrap",
                  }}
                  gutterBottom
                  variant="h9"
                  component="div"
                >
                  <span style={{ fontWeight: "normal", fontSize: "1.5em" }}>
                    Renewal/End Date
                  </span>{" "}
                </Typography>
              </Grid>
              <Grid item xs={6} sm={6} md={2}>
                <Typography
                  sx={{
                    fontSize: "1rem",
                    whiteSpace: "nowrap",
                  }}
                  gutterBottom
                  variant="h9"
                  component="div"
                >
                  <span style={{ fontWeight: "bold", fontSize: "1rem" }}>
                    :
                  </span>{" "}
                  {engagementsData?.data?.[0]?.clientEngagement?.engagementEndDate
                    ? format(
                      new Date(
                        // subtractOneDay(
                        engagementsData?.data?.[0]?.clientEngagement?.engagementEndDate
                        // )
                      ),
                      "MM-dd-yyyy"
                    )
                    : ""}
                </Typography>
              </Grid>
            </Grid>

            <Grid container md={12}>
              <Grid item xs={6} sm={6} md={3.5}>
                <Typography
                  sx={{
                    fontSize: "0.75rem",
                    whiteSpace: "nowrap",
                  }}
                  gutterBottom
                  variant="h9"
                  component="div"
                >
                  <span style={{ fontWeight: "normal", fontSize: "1.5em" }}>
                    Next Update Due
                  </span>{" "}
                </Typography>
              </Grid>
              <Grid item xs={6} sm={6} md={2}>
                <Typography
                  sx={{
                    fontSize: "1rem",
                    whiteSpace: "nowrap",
                  }}
                  gutterBottom
                  variant="h9"
                  component="div"
                >
                  <span style={{ fontWeight: "bold", fontSize: "1rem" }}>
                    :
                  </span>{" "}
                  {engagementsData?.data?.[0]?.clientEngagement?.nextUpdateDue

                    ? format(
                      new Date(
                        // subtractOneDay(
                        engagementsData?.data?.[0]?.clientEngagement?.nextUpdateDue
                        // )
                      ),
                      "MM-dd-yyyy"
                    )
                    : ""}
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={11} sm={11} md={6}>
            <Grid
              justifyContent="flex-start"
              alignItems="flex-end"
              container
              md={12}
            >
              <Grid item xs={6} sm={6} md={3}>
                <Typography
                  sx={{
                    fontSize: ".75rem",
                    whiteSpace: "nowrap",
                    marginTop: "1rem"
                  }}
                  gutterBottom
                  variant="h9"
                  component="div"
                >
                  <span style={{ fontWeight: "normal", fontSize: "1.5em" }}>
                    Manager
                  </span>{" "}
                </Typography>
              </Grid>
              <Grid item xs={6} sm={6} md={1}>
                <Typography
                  sx={{
                    fontSize: "1rem",
                    whiteSpace: "nowrap",
                    marginTop: "1rem"

                  }}
                  gutterBottom
                  variant="h9"
                  component="div"
                >
                  <span style={{ fontWeight: "bold", fontSize: "1rem", marginLeft: "50px" }}>
                    :
                  </span>{" "}
                  {engagementsData?.data?.length > 0
                    ? engagementsData?.data[0]?.excoCustomerUser
                      ?.clientReportingMapping?.excoCustomerManager
                      ?.excoCustomerUserDetailManager?.firstName
                    : ""}{" "}
                  {engagementsData?.data?.length > 0
                    ? engagementsData?.data[0]?.excoCustomerUser
                      ?.clientReportingMapping?.excoCustomerManager
                      ?.excoCustomerUserDetailManager?.lastName
                    : ""}
                </Typography>
              </Grid>
            </Grid>

            <Grid
              justifyContent="flex-start"
              alignItems="flex-end"
              container
              md={12}
            >
              <Grid item xs={6} sm={6} md={3}>
                <Typography
                  sx={{
                    fontSize: ".75rem",
                    whiteSpace: "nowrap",
                  }}
                  gutterBottom
                  variant="h9"
                  component="div"
                >
                  <span style={{ fontWeight: "normal", fontSize: "1.5em" }}>
                    HR Partner
                  </span>{" "}
                </Typography>
              </Grid>
              <Grid item xs={6} sm={6} md={1}>
                <Typography
                  sx={{
                    fontSize: "1rem",
                    whiteSpace: "nowrap",
                  }}
                  gutterBottom
                  variant="h9"
                  component="div"
                >
                  <span style={{ fontWeight: "bold", fontSize: "1rem", marginLeft: "50px" }}>
                    :
                  </span>{" "}
                  {engagementsData?.data?.length > 0
                    ? engagementsData?.data[0]?.excoCustomerUser
                      ?.clientReportingMapping?.excoCustomerHr
                      ?.excoCustomerUserDetailHr?.firstName
                    : ""}{" "}
                  {engagementsData?.data?.length > 0
                    ? engagementsData?.data[0]?.excoCustomerUser
                      ?.clientReportingMapping?.excoCustomerHr
                      ?.excoCustomerUserDetailHr?.lastName
                    : ""}
                </Typography>
              </Grid>
            </Grid>

            <Grid
              justifyContent="flex-start"
              alignItems="flex-end"
              container
              md={12}
            >
              <Grid item xs={6} sm={6} md={3}>
                <Typography
                  sx={{
                    fontSize: ".75rem",
                    whiteSpace: "nowrap",
                  }}
                  gutterBottom
                  variant="h9"
                  component="div"
                >
                  <span style={{ fontWeight: "normal", fontSize: "1.5em" }}>
                    Additional HR Contacts
                  </span>{" "}
                </Typography>
              </Grid>
              <Grid item xs={6} sm={6} md={1}>
                <Typography
                  sx={{
                    fontSize: "1rem",
                    whiteSpace: "nowrap",
                  }}
                  gutterBottom
                  variant="h9"
                  component="div"
                >
                  <span style={{ fontWeight: "bold", fontSize: "1rem", marginLeft: "50px" }}>
                    :
                  </span>{" "}
                  {engagementsData?.getAllChr?.length > 0
                    ? engagementsData.getAllChr
                      .map(
                        (contact) =>
                          `${contact?.excoCustomerUserDetail?.firstName || ""} ${contact?.excoCustomerUserDetail?.lastName || ""
                            }`.trim() // Handle missing first/last names
                      )
                      .filter((name) => name) // Remove empty names
                      .join(", ") // Join names with a comma
                    : ""}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>

      <Stack
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "row",
          fontFamily: "arial",
          marginTop: "3rem",
        }}
      >
        <PageHeader variant="pageHeader">Engagement Timeline</PageHeader>
      </Stack>

      <MuiDataGrid data={data} />
      <Stack
        sx={{
          paddingTop: "1rem",
        }}
      >
        <PageHeader variant="pageHeader">
          {" "}
          Attachments {" "}

        </PageHeader>
      </Stack>
      <Attachments
       selectedDocumentType="DUE_DILIGENCE_MATERIALS"
       isAttachmentFormat={true}
        loading={loading}
        format=".doc, .docx, .pdf, .ppt, .pptx, .xls, .xlsx, .csv, .jpg, .jpeg, .png"
        typeKey="dueDiligenceMaterials"
        fixedKey="DUE_DILIGENCE_MATERIALS"
        attachmentType="Due Diligence Materials"
        clientEngagementId={clientEngagementId}
        engagementsDocumentsData={engagementsDocumentsData}
        createDocument={createEngagementDocuments}
        getDocuments={getEngagementDocuments}
        deleteDocument={deleteClientDocument}
        disableUpdate={true}
        resetMessage={resetMessage}
        resetErrMessage={resetErrMessage}
        message={message}
        errMessage={errMessage}
        viewData={engagementsDocumentsData}
        dynamicPath="/file-acl/client/view/common/"

      />
    </Layout>
  );
};

export default Dashboard;
