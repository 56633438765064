import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { Grid, ListItem } from "@mui/material";
import { countries, continents } from "countries-list";
// components
import Layout from "../../../layout/Layout";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import Paper from "@mui/material/Paper";
import MyTextInput from "../../../components/form_components/FormTextField";
import { makeStyles } from "@mui/styles";
import StepLabel from "@mui/material/StepLabel";
import { MuiDataGrid, MuiFixedHeightGrid } from "../../../components";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { PageHeader } from "@excoleadershipui/pionweblib";
import { FormSubmitButton } from "../../../components";
import Stack from "@mui/material/Stack";
import { DataGrid, GridToolbar, GridRow } from "@mui/x-data-grid";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { MuiAutoCompleteSecondry, MuiAutocomplete } from "../../../components";
import { GridCellExpand } from "../../../components";
import { useFormik } from "formik";
import * as Yup from "yup";
import ModalCloseBtn from "../../../components/common/ModalCloseBtn";
import { PopUpModel } from "../../../components";
import Notifier from "../../../components/common/Notifier";
import ErrorNotifier from "../../../components/common/ErrorNotifier";
import ConfirmDialog from "../../../components/common/ConfirmDialog";
import CommonCrudPopup from "../../../components/common/CommonCrudPopup";
import GridDataDetails from "../../../components/common/GridDataDetails";
import CommonHeader from "../../../components/common/CommonHeader";
// redux
import {
  createNominationDetail,
  getNominationCHR,
  updateNominationDetail,
  rowSelect,
  resetMessage,
  deleteNominationDetail,
  emptyRowSelect,
  resetErrMessage,
} from "../../../features/chr/nomination/nominationSlice";
import {
  getChrEngagementDocuments,
  createChrEngagementDocuments,
  deleteCHRDocuments,
  resetMessage as resetDocumentMessage,
  resetErrMessage as resetDocumentErrMessage
} from "../../../features/chr/dashboard/engagementChrDocumentSlice";
import { getEngagementDetails } from "../../../features/dashboard/dashBoardSlice";

import {
  uploadNominationDocuments,
  getNominationDocument,
} from "../../../features/chr/nomination/nominationSlice";

import Attachment from "../../../components/common/upload_documents/Attachments";

const steps = ["Na", "Matching", "Chemistry", "Preparation", "Mentoring"];

const style = {
  position: "absolute",
  top: "50%",
  left: "60%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  borderRadius: "5px",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
};
const useStyles = makeStyles((theme) => ({
  responsive_header_width: {
    [theme.breakpoints.down("md")]: {
      width: "19.5rem !important",
    },
  },

  rootbox: {
    position: "absolute !important",
    top: "50% !important",
    left: "50% !important",
    transform: "translate(-50%, -50%) !important",
    width: "56.25rem !important",

    [theme.breakpoints.down("md")]: {
      width: "20rem !important",
      height: "20rem !important",
      display: "block !important",
      overflow: "scroll !important",
    },
    [theme.breakpoints.between("sm", "md")]: {
      width: "33.375rem !important",
      height: "49% !important",
      display: "block !important",
      overflow: "scroll !important",
      // margin: '-3.188rem',
      marginTop: "-3.75rem  !important",
      marginLeft: "-3.563rem",
      padding: "2.125rem !important",
    },

    // overflowY: "scroll !important",
    // height: "20rem !important",
    background: "#fff !important",
    borderRadius: "0.313rem !important",
    boxShadow: "1.5rem !important",
    paddingTop: "1rem !important",
    paddingLeft: "2rem !important",
    paddingRight: "2rem !important",
    paddingBottom: "1.5rem",
    display: "flex !important",
    flexDirection: "column !important",
    justifyContent: "center !important",
    alignItems: "center !important",
  },
  responsiveText: {
    width: "40rem",
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },

  close_btn: {
    [theme.breakpoints.down("md")]: {
      marginLeft: "-2.563rem",
    },
  },
}));
const HrLeader = ({ theme }) => {
  const [showAdd, setShowAdd] = useState(false);
  const [isAttachmentView, setIsAttachmentView] = useState(false);
  const [showUpdate, setShowUpdate] = useState(false);
  const [showView, setShowView] = useState(false);
  const [continentValue, setContinentValue] = useState("");
  const [countryValue, setCountryValue] = useState("");
  const [countryValArr, setCountryValArr] = useState([]);
  const [countryArr, setCountryArr] = useState([]);
  const [execComments, setExecComments] = useState([]);
  const [executiveDialing, setLeaderDialing] = useState("");
  const [managerDialing, setManagerDialing] = useState("");
  const [clientEngagementId, setClientEngagementId] = useState(null);
  const [showIcon, setShowIcon] = useState(true);
  const showAddModel = () => {
    setShowAdd(!showAdd);
  };
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });

  const showUpdateModel = () => {
    setShowUpdate(!showUpdate);
    setLeaderDialing(
      selectedData?.executiveDialingCode && selectedData?.executiveDialingCode
    );
    setManagerDialing(
      selectedData?.managerDialingCode && selectedData?.managerDialingCode
    );
  };

  const dispatch = useDispatch();
  const { message, errMessage, nominationData, selectedData } = useSelector(
    (state) => ({ ...state.CHRNomination })
  );

  const validationSchema = Yup.object({
    leaderName: Yup.string().required("Leader first Name is required"),
    leaderLastName: Yup.string().required("Leader last Name is required"),
    leaderEmail: Yup.string("Enter your email")
      .required("Leader Email is required")
      .matches(
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        "email is not valid"
      ),
    title: Yup.string().required("Title is required"),
    // level: Yup.string().required("Level is required"),
    location: Yup.string().required("location is required"),

    managerEmail: Yup.string("Enter your email")

      .required("Email is required")
      .matches(
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        "email is not valid"
      ),
    managerFirstName: Yup.string().required("Manager First Name is required"),

    managerLastName: Yup.string().required("Manager Last Name is required"),
    hrFirstName: Yup.string().required("HR Partner First Name is required"),
    hrLastName: Yup.string().required("HR Partner Last Name is required"),
    hePartnerEmail: Yup.string("Enter your email")

      .required("Email is required")
      .matches(
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        "email is not valid"
      ),
    reason: Yup.string().required("Reason is required"),

    // reason: Yup.string().required("Reason is required"),
    // comments: Yup.string().required("Comments is required"),

    // execMobileNo: Yup.string()
    //   .required("Contact Number is required ")
    //   .matches(/^[0-9]+$/, "Must be only digits")
    //   .min(10, "Must be exactly 10 digits")
    //   .max(10, "Must be exactly 10 digits"),
    geography: Yup.object().required("Geo Name is required"),
    country: Yup.object().required("Country is required "),
    // execDialingCode: Yup.string().required("Dialing Code is required"),

    // managerDialingCode: Yup.string().required("Dialing Code is required"),
    // managerContactNo: Yup.string()
    //   .required("Contact Number is required ")
    //   .matches(/^[0-9]+$/, "Must be only digits")
    //   .min(10, "Must be exactly 10 digits")
    //   .max(10, "Must be exactly 10 digits"),

    // title: Yup.string().required("title is required"),
    assistentEmail: Yup.string().matches(
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      "email is not valid"
    ),
  });

  const updateValidationSchema = Yup.object({
    leaderName: Yup.string().required("Leader first Name is required"),
    leaderLastName: Yup.string().required("Leader last Name is required"),
    leaderEmail: Yup.string("Enter your email")
      .required("Leader Email is required")
      .matches(
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        "email is not valid"
      ),
    title: Yup.string().required("Title is required"),
    // level: Yup.string().required("Level is required"),
    location: Yup.string().required("location is required"),
    managerFirstName: Yup.string().required("Manager First Name is required"),

    managerLastName: Yup.string().required("Manager Last Name is required"),
    hrFirstName: Yup.string().required("HR Partner First Name is required"),
    hrLastName: Yup.string().required("HR Partner Last Name is required"),
    managerEmail: Yup.string("Enter your email")

      .required("Email is required")
      .matches(
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        "email is not valid"
      ),

    hePartnerEmail: Yup.string("Enter your email")
      .required("Email is required")
      .matches(
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        "email is not valid"
      ),
    geography: Yup.string().required("Geo Name is required"),
    country: Yup.string().required("Country is required "),

    reason: Yup.string().required("Reason is required"),
    // comments: Yup.string().required("Comments is required"),
    assistentEmail: Yup.string()
      .nullable()
      .matches(
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        "email is not valid"
      ),
  });

  const MangerDialingContact = (params) => {
    return `${params?.row?.managerDialingCode}- ${params?.row?.managerMobileNo}`;
  };

  const executiveDialingContact = (params) => {
    return `${params?.row?.executiveDialingCode} -${params?.row?.executiveMobileNo}`;
  };

  const { engagementsDocumentsData, loading,documentMessage,documentErrMessage } = useSelector((state) => ({
    ...state.CHREngagementDocuments,
  }));

  const { keyClientAllEngagementData } = useSelector((state) => ({
    ...state.CHREngagement,
  }));
  const { engagementsData, engagementStageData, mentorCurrentDetails } =
    useSelector((state) => ({ ...state.clientEngagement }));

  function renderCellExpand(params) {
    return (
      <GridCellExpand
        value={params.value || ""}
        width={params.colDef.computedWidth}
      />
    );
  }

  const data = {
    rows: nominationData,
    columns: [
      {
        field: "leaderName",
        headerName: "Leader Name ",
        width: 200,
        valueGetter: (params) =>
          `${params?.row?.leaderFirstName}${" "} ${
            params?.row?.leaderLastName
          }`,
        renderCell: renderCellExpand,
      },
      {
        field: "leaderEmail",
        headerName: "Leader Email",
        width: 300,
      },
      {
        field: "title",
        headerName: "Title/Position",
        width: 280,
        renderCell: renderCellExpand,
      },
      { field: "level", headerName: "Level ", width: 200 },

      {
        field: "managerName",
        headerName: "Manager",
        width: 200,
        valueGetter: (params) =>
          `${params?.row?.managerFirstName}${" "} ${
            params?.row?.managerLastName
          }`,
        renderCell: renderCellExpand,
      },
      {
        field: "managerEmail",
        headerName: "Manager Email",
        width: 200,
      },

      {
        field: "hrPartnerName",
        headerName: "HR Partner",
        width: 280,
        valueGetter: (params) =>
          `${params?.row?.hrPartnerFirstName}${" "} ${
            params?.row?.hrPartnerLastName
          }`,
        renderCell: renderCellExpand,
      },
      {
        field: "hrPartnerEmail",
        headerName: "HR Partner Email",
        width: 280,
        renderCell: renderCellExpand,
      },
      {
        field: "assistantName",
        headerName: "Assistant",
        width: 280,
        renderCell: renderCellExpand,
      },
      {
        field: "assistantEmail",
        headerName: "Assistant Email",
        width: 280,
        renderCell: renderCellExpand,
      },
      { field: "geoName", headerName: "Geography", width: 200 },
      { field: "country", headerName: "Country", width: 200 },
      { field: "location", headerName: "City/Location", width: 200 },
      { field: "offeringType", headerName: "Offering Type", width: 200 },
      {
        field: "reasonForCoaching",
        headerName: "Reason for Coaching/Mentoring",
        width: 280,
        renderCell: renderCellExpand,
      },

      {
        field: "additionalComments",
        headerName: "Comments",
        width: 300,
        renderCell: renderCellExpand,
      },
    ],
  };

  const closeModel = () => {
    setShowAdd(false);
    setCountryValue("");
    setContinentValue("");
    formik.resetForm({
      leaderName: "",
      leaderLastName: "",
      leaderEmail: "",
      execDialingCode: "",
      execMobileNo: "",
      managerName: "",
      managerDialingCode: "",
      managerContactNo: "",
      managerEmail: "",
      department: "",
    });
  };

  const handleDeleteNomination = () => {
    let data = {
      id: selectedData?.id,
    };
    dispatch(deleteNominationDetail(data)).then((res) => {
      if (res?.payload?.data?.ok) {
        setTimeout(() => {
          dispatch(resetMessage());
          setConfirmDialog({
            ...confirmDialog,
            isOpen: false,
          });
          dispatch(getNominationCHR());
        }, 2000);
      }
      if (res?.payload?.error) {
        setTimeout(() => {
          dispatch(resetErrMessage());
        }, 3000);
      }
    });
  };

  const handleSetConfirmDialog = (obj) => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: obj.isOpen,
    });
  };

  // const closeUpdateModel = () => {
  //   setShowUpdate(false);
  //   formik.resetForm({
  //     leaderName: "",
  //     leaderLastName: "",
  //     leaderEmail: "",
  //     title: "",
  //     level: "",
  //     location: "",
  //     managerName: "",
  //     managerEmail: "",
  //     hePartner: "",
  //     hePartnerEmail: "",
  //     assistent: "",
  //     assistentEmail: "",
  //     reason: "",
  //     comments: "",
  //   });
  // };

  const closeUpdateModel = () => {
    //updated the reset form to updateFormik so while closing the data reset based on the selected data on the update form
    setShowUpdate(false);
    updateFormik.resetForm();
  };

  const formik = useFormik({
    reset: true,
    initialValues: {
      leaderName: "",
      leaderLastName: "",
      leaderEmail: "",
      title: "",
      level: "",
      location: "",
      offeringType: "",
      managerFirstName: "",
      managerLastName: "",
      hrFirstName: "",
      hrLastName: "",
      managerEmail: "",
      hePartner: "",
      hePartnerEmail: "",
      assistent: "",
      assistentEmail: "",
      reason: "",
      comments: "",
      geography: "",
      country: "",
    },

    validationSchema: validationSchema,
    onSubmit: (values, onSubmitProps) => {
      dispatch(
        createNominationDetail({
          assistantEmail: values?.assistentEmail
            ? values?.assistentEmail
            : null,
          assistantName: values?.assistent ? values?.assistent : null,
          hrPartnerEmail: values?.hePartnerEmail,
          additionalComments: values?.comments,
          title: values?.title,
          reasonForCoaching: values?.reason,
          managerEmail: values?.managerEmail,
          leaderEmail: values?.leaderEmail,

          hrPartnerFirstName: values?.hrFirstName,
          hrPartnerLastName: values?.hrLastName,
          managerFirstName: values?.managerFirstName,
          managerLastName: values?.managerLastName,

          leaderFirstName: values?.leaderName,
          leaderLastName: values?.leaderLastName,
          location: values?.location,
          offeringType: values?.offeringType,
          level: values?.level ? values?.level : null,
          additionalComments: values?.comments,
          geoName: values.geography?.code,
          country: values.country?.name,
        })
      ).then((res) => {
        if (res?.payload?.data?.ok) {
          dispatch(emptyRowSelect());
          setTimeout(() => {
            dispatch(resetMessage());
            closeModel();
            dispatch(getNominationCHR());

            onSubmitProps.resetForm();
          }, 2000);
        }
        if (res?.payload?.error) {
          setTimeout(() => {
            dispatch(resetErrMessage());
          }, 3000);
        }
      });
    },
  });

  const updateFormik = useFormik({
    enableReinitialize: true,
    initialValues: {
      leaderName: selectedData?.leaderFirstName,
      leaderLastName: selectedData?.leaderLastName,
      leaderEmail: selectedData?.leaderEmail,
      managerFirstName: selectedData?.managerFirstName,
      managerLastName: selectedData?.managerLastName,
      hrFirstName: selectedData?.hrPartnerFirstName,
      hrLastName: selectedData?.hrPartnerLastName,

      managerEmail: selectedData?.managerEmail,
      hePartner: selectedData?.hrPartnerName,
      hePartnerEmail: selectedData?.hrPartnerEmail,
      assistent: selectedData?.assistantName,
      assistentEmail: selectedData?.assistantEmail,
      reason: selectedData?.reasonForCoaching,
      comment: selectedData?.additionalComments,
      title: selectedData?.title,
      location: selectedData?.location,
      level: selectedData?.level,
      geography: selectedData?.geoName,
      country: selectedData?.country,
      offeringType: selectedData?.offeringType,
    },

    validationSchema: updateValidationSchema,

    onSubmit: (values, onSubmitProps) => {
      dispatch(
        updateNominationDetail({
          id: selectedData?.id,
          assistantEmail: values?.assistentEmail
            ? values?.assistentEmail
            : null,
          assistantName: values?.assistent ? values?.assistent : null,
          hrPartnerEmail: values?.hePartnerEmail,
          hrPartnerFirstName: values?.hrFirstName,
          hrPartnerLastName: values?.hrLastName,
          managerFirstName: values?.managerFirstName,
          managerLastName: values?.managerLastName,

          title: values?.title,
          reasonForCoaching: values?.reason,
          managerEmail: values?.managerEmail,
          leaderEmail: values?.leaderEmail,

          leaderFirstName: values?.leaderName,
          leaderLastName: values?.leaderLastName,
          location: values?.location,
          level: values?.level ? values?.level : null,
          additionalComments: values?.comment ? values?.comment : null,
          geoName: values.geography,
          country: values.country,
          offeringType: values.offeringType,
        })
      ).then((res) => {
        if (res?.payload?.data?.ok) {
          setTimeout(() => {
            dispatch(resetMessage());
            closeUpdateModel();
            // window.location.reload()
            dispatch(getNominationCHR());
            // onSubmitProps.resetForm();
          }, 2000);
        }
        if (res?.payload?.error) {
          setTimeout(() => {
            dispatch(resetErrMessage());
          }, 3000);
        }
      });
    },
  });

  let options = Object.values(countries);
  let conti = Object.keys(continents);

  // const geographyArr = [
  //   { key: "AF", value: "AF", name: "Africa" },
  //   { key: "AN", value: "AN", name: "Antarctica" },
  //   { key: "AS", value: "AS", name: "Asia" },
  //   { key: "EU", value: "EU", name: "Europe" },
  //   { key: "NA", value: "NA", name: "North America" },
  //   { key: "OC", value: "OC", name: "Oceania" },
  //   { key: "SA", value: "SA", name: "South America" },
  // ];

  const geographyArr = [
    { code: "AF", name: "Africa" },
    { code: "AN", name: "Antarctica" },
    { code: "AS", name: "Asia" },
    { code: "EU", name: "Europe" },
    { code: "NA", name: "North America" },
    { code: "OC", name: "Oceania" },
    { code: "SA", name: "South America" },
  ];

  const handleView = () => {
    setShowView(true);
  };
  const closeViewModal = () => {
    setShowView(false);
  };

  useEffect(() => {
    dispatch(getNominationCHR());
    dispatch(getEngagementDetails());
  }, []);

  useEffect(() => {
    if (engagementsData?.data?.length > 0) {
      const id = engagementsData?.data?.[0]?.clientEngagement?.id;
      setClientEngagementId(id);
    }
  }, [engagementsData]);

  useEffect(() => {
    return options.map((item) => {
      if (item.continent === continentValue?.value) {
        return countryValArr.push(item);
      }
      setCountryValArr(countryValArr);
    });
  }, [continentValue]);

  useEffect(() => {
    let countries = [];
    options.map((option) => {
      if (option.continent === formik?.values?.geography?.code) {
        return countries.push(option);
      }
    });
    setCountryArr(countries);
  }, [formik?.values?.geography]);

  useEffect(() => {
    options.map((option) => {
      if (option.name === formik?.values?.country?.name) {
        return formik?.setFieldValue("execDialingCode", option?.phone);
      }
    });
  }, [formik?.values?.country]);

  useEffect(() => {
    options.map((option) => {
      if (option.name === formik?.values?.country?.name) {
        return formik?.setFieldValue("managerDialingCode", option?.phone);
      }
    });
  }, [formik?.values?.country]);

  useEffect(() => {
    options.map((option) => {
      if (option.name === updateFormik?.values?.country) {
        return updateFormik?.setFieldValue("execDialingCode", option?.phone);
      }
    });
  }, [updateFormik?.values?.country]);

  useEffect(() => {
    options.map((option) => {
      if (option.name === updateFormik?.values?.country) {
        return updateFormik?.setFieldValue("managerDialingCode", option?.phone);
      }
    });
  }, [updateFormik?.values?.country]);

  // useEffect(() => {

  // }, [updateFormik.]);
  const classes = useStyles();

  console.log(documentMessage);

  return (
    <Layout show={true}>
      <Stack
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",
          // marginBottom: "1rem",
        }}
      >
        <Link to="/exco/chr/dashboard">
          <Button sx={{ textTransform: "none" }} variant="contained">
            Back to All Engagements
          </Button>
        </Link>
      </Stack>

      <Stack
        sx={{
          paddingTop: "1rem",
        }}
      >
        <PageHeader variant="pageHeader">Leader Nominations</PageHeader>
      </Stack>

      <Stack>
        <h4 style={{ fontWeight: "normal" }}>
          Please utilize the table below to nominate leaders for mentoring /
          coaching with The ExCo Group.
        </h4>
      </Stack>

      <MuiFixedHeightGrid
        viewAdd={true}
        setShowAdd={showAddModel}
        setShowEdit={showUpdateModel}
        setShowView={true}
        handleView={handleView}
        data={data}
        select={(data) => dispatch(rowSelect(data))}
        setShowDelete={() =>
          setConfirmDialog({
            isOpen: true,
            title: "Are you sure to delete this record?",
            subTitle: "You can't undo this operation",
            onConfirm: () => handleDeleteNomination(),
          })
        }
      />

      {showView && (
        <CommonCrudPopup
          heading1={
            !isAttachmentView
              ? `View Leader Nomination for  : ${
                  selectedData?.leaderFirstName || ""
                } ${selectedData?.leaderLastName || ""}`
              : "Documents"
          }
          largeScreen={true}
          upload={() => {
            setIsAttachmentView(true);
            setShowIcon(false);
          }}
          // close={()=> setShowView(false)}
          close={
            !isAttachmentView
              ? closeViewModal
              : () => {
                  setIsAttachmentView(false);
                  setShowIcon(true);
                }
          }
          isUpload={showIcon}
          iconType="folder"
        >
          {!isAttachmentView && (
            <Grid container>
              <Grid sm={12} xs={12} md={5} sx={{ marginLeft: "2rem" }}>
                <GridDataDetails
                  title="Leader Name"
                  data={`${selectedData?.leaderFirstName || ""} ${
                    selectedData?.leaderLastName || ""
                  }`}
                  mdt={3.2}
                  mdc={0.3}
                  mdd={8.5}
                />
                <GridDataDetails
                  title="Leader Email"
                  data={selectedData?.leaderEmail}
                  mdt={3.2}
                  mdc={0.3}
                  mdd={8.5}
                />
                <GridDataDetails
                  title=" Title/Position"
                  data={selectedData?.title}
                  mdt={3.2}
                  mdc={0.3}
                  mdd={8.5}
                />
                <GridDataDetails
                  title="Level"
                  data={selectedData?.level}
                  mdt={3.2}
                  mdc={0.3}
                  mdd={8.5}
                />
                <GridDataDetails
                  title="Manager"
                  data={`${selectedData?.managerFirstName || ""} ${
                    selectedData?.managerLastName || ""
                  }`}
                  mdt={3.2}
                  mdc={0.3}
                  mdd={8.5}
                />
                <GridDataDetails
                  title="Manager Email"
                  data={selectedData?.managerEmail}
                  mdt={3.2}
                  mdc={0.3}
                  mdd={8.5}
                />
                <GridDataDetails
                  title="HR Partner"
                  data={`${selectedData?.hrPartnerFirstName || ""} ${
                    selectedData?.hrPartnerLastName || ""
                  }`}
                  mdt={3.2}
                  mdc={0.3}
                  mdd={8.5}
                />
                <GridDataDetails
                  title="HR Partner Email"
                  data={selectedData?.hrPartnerEmail}
                  mdt={3.2}
                  mdc={0.3}
                  mdd={8.5}
                />
              </Grid>

              <Grid sm={12} xs={12} md={5} sx={{ marginLeft: "5rem" }}>
                <GridDataDetails
                  title="Assistant"
                  data={selectedData?.assistantName}
                  mdt={3.7}
                  mdc={0.3}
                  mdd={8}
                />
                <GridDataDetails
                  title="Assistant Email"
                  data={selectedData?.assistantEmail}
                  mdt={3.7}
                  mdc={0.3}
                  mdd={8}
                />
                <GridDataDetails
                  title="Geography"
                  data={selectedData?.geoName}
                  mdt={3.7}
                  mdc={0.3}
                  mdd={8}
                />
                <GridDataDetails
                  title="Country"
                  data={selectedData?.country}
                  mdt={3.7}
                  mdc={0.3}
                  mdd={8}
                />
                <GridDataDetails
                  title="City/Location"
                  data={selectedData?.location}
                  mdt={3.7}
                  mdc={0.3}
                  mdd={8}
                />
                <GridDataDetails
                  title="Offering Type"
                  data={selectedData?.offeringType}
                  mdt={3.7}
                  mdc={0.3}
                  mdd={8}
                />
                <GridDataDetails
                  title="Comments"
                  data={selectedData?.additionalComments}
                  mdt={3.7}
                  mdc={0.3}
                  mdd={8}
                />
                <GridDataDetails
                  title="Reason for Coaching/Mentoring"
                  data={selectedData?.reasonForCoaching}
                  mdt={3.7}
                  mdc={0.3}
                  mdd={8}
                />
              </Grid>
            </Grid>
          )}

          {isAttachmentView && (
            <Stack>
              <Attachment
                selectedDocumentType="LEADER_CONTEXT_MATERIALS"
                loading={loading}
                format=".doc, .docx, .pdf, .ppt, .pptx,  .jpg, .jpeg, .png"
                nominationId={selectedData?.id}
                fixedKey="LEADER_CONTEXT_MATERIALS"

                // createDocument={uploadNominationDocuments}
                // attachmentType="Leaders Bio,Org Charts & Context Materials"
                // resetMessage={resetMessage}
                // resetErrMessage={resetErrMessage}
                // getDocuments={getNominationDocument}
                typeKey="leadersContextMaterials"
                attachmentType="Leaders Bio,Org Charts & Context Materials"
                // clientEngagementId={clientEngagementId}
                createDocument={createChrEngagementDocuments}
                getDocuments={getChrEngagementDocuments}
                deleteDocument={deleteCHRDocuments}
                disableUpdate={true}
                resetMessage={resetDocumentMessage}
                resetErrMessage={resetDocumentErrMessage}
                message={documentMessage}
                errMessage={documentErrMessage}
                viewData={engagementsDocumentsData}
                dynamicPath="/file-acl/chr/view/leader-context-materials/"
              />
            </Stack>
          )}
        </CommonCrudPopup>
      )}
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={(obj) => handleSetConfirmDialog(obj)}
      />

      <PopUpModel
        open={showUpdate}
        handleClose={closeUpdateModel}
        closeModel={closeUpdateModel}
      >
        <Box
          // sx={{ ...style, width: 700 }}
          className={classes.rootbox}
          component="form"
          noValidate
          onSubmit={updateFormik.handleSubmit}
        >
          <ModalCloseBtn close={closeUpdateModel} />
          <div className={classes.responsiveText}>
            <Grid
              container
              spacing={1}
              className={classes.responsive_textfield}
            >
              <Grid container item xs={12} sm={12} md={6}>
                <Grid item xs={12} sm={12} md={6}>
                  <MyTextInput
                    tabIndex={1}
                    label="Leader First Name"
                    type="text"
                    name="leaderName"
                    required={true}
                    fullWidth={true}
                    margin="normal"
                    id="leaderName"
                    value={updateFormik.values.leaderName}
                    onChange={updateFormik.handleChange}
                    error={
                      updateFormik.touched.leaderName &&
                      Boolean(updateFormik.errors.leaderName)
                    }
                    helperText={
                      updateFormik.touched.leaderName &&
                      updateFormik.errors.leaderName
                    }
                  />
                </Grid>
                <Grid sx={{ pl: "0.5rem" }} item xs={12} sm={12} md={6}>
                  <MyTextInput
                    tabIndex={1}
                    label="Leader Last Name"
                    type="text"
                    name="leaderLastName"
                    required={true}
                    fullWidth={true}
                    margin="normal"
                    id="leaderLastName"
                    value={updateFormik.values.leaderLastName}
                    onChange={updateFormik.handleChange}
                    error={
                      updateFormik.touched.leaderLastName &&
                      Boolean(updateFormik.errors.leaderLastName)
                    }
                    helperText={
                      updateFormik.touched.leaderLastName &&
                      updateFormik.errors.leaderLastName
                    }
                  />
                </Grid>

                <MyTextInput
                  label="Title/Position"
                  type="text"
                  name="title"
                  required={true}
                  fullWidth={true}
                  margin="normal"
                  id="title"
                  value={updateFormik.values.title}
                  onChange={updateFormik.handleChange}
                  error={
                    updateFormik.touched.title &&
                    Boolean(updateFormik.errors.title)
                  }
                  helperText={
                    updateFormik.touched.title && updateFormik.errors.title
                  }
                />

                <Grid container spacing={2} item xs={12} sm={12} md={12}>
                  <Grid item xs={12} sm={12} md={6}>
                    <MyTextInput
                      tabIndex={1}
                      label="Manager First Name"
                      type="text"
                      name="managerFirstName"
                      required={true}
                      fullWidth={true}
                      margin="normal"
                      id="managerFirstName"
                      value={updateFormik.values.managerFirstName}
                      onChange={updateFormik.handleChange}
                      error={
                        updateFormik.touched.managerFirstName &&
                        Boolean(updateFormik.errors.managerFirstName)
                      }
                      helperText={
                        updateFormik.touched.managerFirstName &&
                        updateFormik.errors.managerFirstName
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6}>
                    <MyTextInput
                      tabIndex={1}
                      label="Manager Last Name"
                      type="text"
                      name="managerLastName"
                      required={true}
                      fullWidth={true}
                      margin="normal"
                      id="managerLastName"
                      value={updateFormik.values.managerLastName}
                      onChange={updateFormik.handleChange}
                      error={
                        updateFormik.touched.managerLastName &&
                        Boolean(updateFormik.errors.managerLastName)
                      }
                      helperText={
                        updateFormik.touched.managerLastName &&
                        updateFormik.errors.managerLastName
                      }
                    />
                  </Grid>
                </Grid>

                {/* <MyTextInput
                  label="Manager Name"
                  type="text"
                  name="managerName"
                  required={true}
                  fullWidth={true}
                  margin="normal"
                  id="managerName"
                  value={updateFormik.values.managerName}
                  onChange={updateFormik.handleChange}
                  error={
                    updateFormik.touched.managerName &&
                    Boolean(updateFormik.errors.managerName)
                  }
                  helperText={
                    updateFormik.touched.managerName &&
                    updateFormik.errors.managerName
                  }
                /> */}

                <Grid container spacing={2} item xs={12} sm={12} md={12}>
                  <Grid item xs={12} sm={12} md={6}>
                    <MyTextInput
                      tabIndex={1}
                      label="HR Partner First Name"
                      type="text"
                      name="hrFirstName"
                      required={true}
                      fullWidth={true}
                      margin="normal"
                      id="hrFirstName"
                      value={updateFormik.values.hrFirstName}
                      onChange={updateFormik.handleChange}
                      error={
                        updateFormik.touched.hrFirstName &&
                        Boolean(updateFormik.errors.hrFirstName)
                      }
                      helperText={
                        updateFormik.touched.hrFirstName &&
                        updateFormik.errors.hrFirstName
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6}>
                    <MyTextInput
                      tabIndex={1}
                      label="HR Partner Last Name"
                      type="text"
                      name="hrLastName"
                      required={true}
                      fullWidth={true}
                      margin="normal"
                      id="hrLastName"
                      value={updateFormik.values.hrLastName}
                      onChange={updateFormik.handleChange}
                      error={
                        updateFormik.touched.hrLastName &&
                        Boolean(updateFormik.errors.hrLastName)
                      }
                      helperText={
                        updateFormik.touched.hrLastName &&
                        updateFormik.errors.hrLastName
                      }
                    />
                  </Grid>
                </Grid>

                {/* <MyTextInput
                  label="HR Partner"
                  type="text"
                  name="hePartner"
                  // required={true}
                  fullWidth={true}
                  margin="normal"
                  id="hePartner"
                  value={updateFormik.values.hePartner}
                  onChange={updateFormik.handleChange}
                  error={
                    updateFormik.touched.hePartner &&
                    Boolean(updateFormik.errors.hePartner)
                  }
                  helperText={
                    updateFormik.touched.hePartner &&
                    updateFormik.errors.hePartner
                  }
                /> */}

                <MyTextInput
                  label="Assistant"
                  type="text"
                  name="assistent"
                  // required={true}
                  fullWidth={true}
                  margin="normal"
                  id="assistent"
                  value={updateFormik.values.assistent}
                  onChange={updateFormik.handleChange}
                  error={
                    updateFormik.touched.assistent &&
                    Boolean(updateFormik.errors.assistent)
                  }
                  helperText={
                    updateFormik.touched.assistent &&
                    updateFormik.errors.assistent
                  }
                />
                <FormControl sx={{ marginTop: "1rem" }} fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Geography <span style={{ color: "red" }}>*</span>
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    name="geography"
                    defaultValue={updateFormik?.values?.geography?.toString()}
                    value={updateFormik.values.geography}
                    error={
                      updateFormik.touched.geography &&
                      Boolean(updateFormik.errors.geography)
                    }
                    label="Geography"
                    onChange={updateFormik.handleChange}
                  >
                    {Object.keys(continents).map((item) => (
                      <MenuItem key={item} value={item}>
                        {item}-{continents[item]}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText error={true} sx={{ color: "red" }}>
                    {updateFormik.touched.geography &&
                      updateFormik.errors.geography}
                  </FormHelperText>
                </FormControl>

                <MyTextInput
                  label="City/Location"
                  type="text"
                  name="location"
                  required={true}
                  fullWidth={true}
                  margin="normal"
                  id="location"
                  value={updateFormik.values.location}
                  onChange={updateFormik.handleChange}
                  tabIndex={1}
                  error={
                    updateFormik.touched.location &&
                    Boolean(updateFormik.errors.location)
                  }
                  helperText={
                    updateFormik.touched.location &&
                    updateFormik.errors.location
                  }
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <MyTextInput
                  label="Leader's Email"
                  type="text"
                  name="leaderEmail"
                  required={true}
                  fullWidth={true}
                  margin="normal"
                  id="leaderEmail"
                  value={updateFormik.values.leaderEmail}
                  onChange={updateFormik.handleChange}
                  tabIndex={1}
                  error={
                    updateFormik.touched.leaderEmail &&
                    Boolean(updateFormik.errors.leaderEmail)
                  }
                  helperText={
                    updateFormik.touched.leaderEmail &&
                    updateFormik.errors.leaderEmail
                  }
                />
                <MyTextInput
                  label="Level"
                  type="text"
                  name="level"
                  // required={true}
                  fullWidth={true}
                  margin="normal"
                  id="level"
                  value={updateFormik.values.level}
                  onChange={updateFormik.handleChange}
                  error={
                    updateFormik.touched.level &&
                    Boolean(updateFormik.errors.level)
                  }
                  helperText={
                    updateFormik.touched.level && updateFormik.errors.level
                  }
                />

                <MyTextInput
                  label="Manager Email"
                  type="text"
                  name="managerEmail"
                  required={true}
                  fullWidth={true}
                  margin="normal"
                  id="managerEmail"
                  value={updateFormik.values.managerEmail}
                  onChange={updateFormik.handleChange}
                  error={
                    updateFormik.touched.managerEmail &&
                    Boolean(updateFormik.errors.managerEmail)
                  }
                  helperText={
                    updateFormik.touched.managerEmail &&
                    updateFormik.errors.managerEmail
                  }
                />

                <MyTextInput
                  label="HR Partner Email"
                  type="text"
                  name="hePartnerEmail"
                  required={true}
                  fullWidth={true}
                  margin="normal"
                  id="hePartnerEmail"
                  value={updateFormik.values.hePartnerEmail}
                  onChange={updateFormik.handleChange}
                  error={
                    updateFormik.touched.hePartnerEmail &&
                    Boolean(updateFormik.errors.hePartnerEmail)
                  }
                  helperText={
                    updateFormik.touched.hePartnerEmail &&
                    updateFormik.errors.hePartnerEmail
                  }
                />

                <MyTextInput
                  label="Assistant Email"
                  type="text"
                  name="assistentEmail"
                  fullWidth={true}
                  margin="normal"
                  id="assistentEmail"
                  value={updateFormik.values.assistentEmail}
                  onChange={updateFormik.handleChange}
                  error={
                    updateFormik.touched.assistentEmail &&
                    Boolean(updateFormik.errors.assistentEmail)
                  }
                  helperText={
                    updateFormik.touched.assistentEmail &&
                    updateFormik.errors.assistentEmail
                  }
                />
                <FormControl sx={{ marginTop: "1rem" }} fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Country <span style={{ color: "red" }}>*</span>
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    name="country"
                    defaultValue={updateFormik.values.country}
                    value={updateFormik.values.country}
                    label="Country"
                    onChange={updateFormik.handleChange}
                    error={
                      updateFormik.touched.country &&
                      Boolean(updateFormik.errors.country)
                    }
                  >
                    {options.map((option) => {
                      if (updateFormik.values.geography === option.continent) {
                        return (
                          <MenuItem key={option.name} value={option.name}>
                            {option.name}
                          </MenuItem>
                        );
                      }
                    })}
                  </Select>
                  <FormHelperText error={true} sx={{ color: "red" }}>
                    {updateFormik.touched.country &&
                      updateFormik.errors.country}
                  </FormHelperText>
                </FormControl>

                <MyTextInput
                  label="Offering Type"
                  type="text"
                  name="offeringType"
                  // required={true}
                  fullWidth={true}
                  margin="normal"
                  id="offeringType"
                  value={updateFormik.values.offeringType}
                  onChange={updateFormik.handleChange}
                  error={
                    formik.touched.offeringType &&
                    Boolean(formik.errors.offeringType)
                  }
                  helperText={
                    formik.touched.offeringType && formik.errors.offeringType
                  }
                />
              </Grid>

              <MyTextInput
                label="Reason for Coaching/Mentoring"
                type="text"
                name="reason"
                required={true}
                fullWidth={true}
                margin="normal"
                id="reason"
                value={updateFormik.values.reason}
                onChange={updateFormik.handleChange}
                tabIndex={1}
                error={
                  updateFormik.touched.reason &&
                  Boolean(updateFormik.errors.reason)
                }
                helperText={
                  updateFormik.touched.reason && updateFormik.errors.reason
                }
              />
              <MyTextInput
                label="Comments"
                type="text"
                name="comment"
                fullWidth={true}
                margin="normal"
                id="comment"
                value={updateFormik.values.comment}
                onChange={updateFormik.handleChange}
              />
            </Grid>
          </div>
          <Stack sx={{ marginTop: "0.5rem" }}>
            <FormSubmitButton type="submit" variant="contained">
              Submit
            </FormSubmitButton>
          </Stack>
        </Box>
      </PopUpModel>

      <PopUpModel
        open={showAdd}
        handleClose={closeModel}
        closeModel={closeModel}
      >
        <Box
          sx={{ background: "red" }}
          // sx={{ ...style, width: 700 }}
          // sx={{ ...style, width: 700 }}
          className={classes.rootbox}
          component="form"
          // style={{ marginTop: "3.5rem" }}
          noValidate
          onSubmit={formik.handleSubmit}
        >
          <ModalCloseBtn close={closeModel} />
          <div className={classes.responsiveText}>
            <Grid
              container
              spacing={1}
              className={classes.responsive_textfield}
            >
              <Grid container spacing={2} item xs={12} sm={12} md={6}>
                <Grid item xs={12} sm={12} md={6}>
                  <MyTextInput
                    tabIndex={1}
                    label="Leader First Name"
                    type="text"
                    name="leaderName"
                    required={true}
                    fullWidth={true}
                    margin="normal"
                    id="leaderName"
                    value={formik.values.leaderName}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.leaderName &&
                      Boolean(formik.errors.leaderName)
                    }
                    helperText={
                      formik.touched.leaderName && formik.errors.leaderName
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <MyTextInput
                    tabIndex={1}
                    label="Leader Last Name"
                    type="text"
                    name="leaderLastName"
                    required={true}
                    fullWidth={true}
                    margin="normal"
                    id="leaderLastName"
                    value={formik.values.leaderLastName}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.leaderLastName &&
                      Boolean(formik.errors.leaderLastName)
                    }
                    helperText={
                      formik.touched.leaderLastName &&
                      formik.errors.leaderLastName
                    }
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <MyTextInput
                  label="Leader's Email"
                  type="text"
                  name="leaderEmail"
                  required={true}
                  fullWidth={true}
                  margin="normal"
                  id="leaderEmail"
                  value={formik.values.leaderEmail}
                  onChange={formik.handleChange}
                  tabIndex={2}
                  error={
                    formik.touched.leaderEmail &&
                    Boolean(formik.errors.leaderEmail)
                  }
                  helperText={
                    formik.touched.leaderEmail && formik.errors.leaderEmail
                  }
                />
              </Grid>

              <Grid item xs={12} sm={12} md={6}>
                <MyTextInput
                  label="Title/Position"
                  type="text"
                  name="title"
                  required={true}
                  fullWidth={true}
                  margin="normal"
                  id="department"
                  value={formik.values.title}
                  onChange={formik.handleChange}
                  tabIndex={1}
                  error={formik.touched.title && Boolean(formik.errors.title)}
                  helperText={formik.touched.title && formik.errors.title}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={6}>
                <MyTextInput
                  label="Level"
                  type="text"
                  name="level"
                  fullWidth={true}
                  margin="normal"
                  id="level"
                  value={formik.values.level}
                  onChange={formik.handleChange}
                  error={formik.touched.level && Boolean(formik.errors.level)}
                  helperText={formik.touched.level && formik.errors.level}
                />
              </Grid>

              <Grid container spacing={2} item xs={12} sm={12} md={6}>
                <Grid item xs={12} sm={12} md={6}>
                  <MyTextInput
                    tabIndex={1}
                    label="Manager First Name"
                    type="text"
                    name="managerFirstName"
                    required={true}
                    fullWidth={true}
                    margin="normal"
                    id="managerFirstName"
                    value={formik.values.managerFirstName}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.managerFirstName &&
                      Boolean(formik.errors.managerFirstName)
                    }
                    helperText={
                      formik.touched.managerFirstName &&
                      formik.errors.managerFirstName
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <MyTextInput
                    tabIndex={1}
                    label="Manager Last Name"
                    type="text"
                    name="managerLastName"
                    required={true}
                    fullWidth={true}
                    margin="normal"
                    id="managerLastName"
                    value={formik.values.managerLastName}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.managerLastName &&
                      Boolean(formik.errors.managerLastName)
                    }
                    helperText={
                      formik.touched.managerLastName &&
                      formik.errors.managerLastName
                    }
                  />
                </Grid>
              </Grid>

              {/* <Grid item xs={12} sm={12} md={6}>
                <MyTextInput
                  label="Manager Name"
                  type="text"
                  name="managerName"
                  required={true}
                  fullWidth={true}
                  margin="normal"
                  id="managerName"
                  value={formik.values.managerName}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.managerName &&
                    Boolean(formik.errors.managerName)
                  }
                  helperText={
                    formik.touched.managerName && formik.errors.managerName
                  }
                />
              </Grid> */}

              <Grid item xs={12} sm={12} md={6}>
                <MyTextInput
                  label="Manager Email"
                  type="text"
                  name="managerEmail"
                  required={true}
                  fullWidth={true}
                  margin="normal"
                  id="managerEmail"
                  value={formik.values.managerEmail}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.managerEmail &&
                    Boolean(formik.errors.managerEmail)
                  }
                  helperText={
                    formik.touched.managerEmail && formik.errors.managerEmail
                  }
                />
              </Grid>

              <Grid container spacing={2} item xs={12} sm={12} md={6}>
                <Grid item xs={12} sm={12} md={6}>
                  <MyTextInput
                    tabIndex={1}
                    label="HR Partner First Name"
                    type="text"
                    name="hrFirstName"
                    required={true}
                    fullWidth={true}
                    margin="normal"
                    id="hrFirstName"
                    value={formik.values.hrFirstName}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.hrFirstName &&
                      Boolean(formik.errors.hrFirstName)
                    }
                    helperText={
                      formik.touched.hrFirstName && formik.errors.hrFirstName
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <MyTextInput
                    tabIndex={1}
                    label="HR Partner Last Name"
                    type="text"
                    name="hrLastName"
                    required={true}
                    fullWidth={true}
                    margin="normal"
                    id="hrLastName"
                    value={formik.values.hrLastName}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.hrLastName &&
                      Boolean(formik.errors.hrLastName)
                    }
                    helperText={
                      formik.touched.hrLastName && formik.errors.hrLastName
                    }
                  />
                </Grid>
              </Grid>

              {/* <Grid item xs={12} sm={12} md={6}>
                <MyTextInput
                  label="HR Partner"
                  type="text"
                  name="hePartner"
                  required={true}
                  fullWidth={true}
                  margin="normal"
                  id="hePartner"
                  value={formik.values.hePartner}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.hePartner && Boolean(formik.errors.hePartner)
                  }
                  helperText={
                    formik.touched.hePartner && formik.errors.hePartner
                  }
                />
              </Grid> */}

              <Grid item xs={12} sm={12} md={6}>
                <MyTextInput
                  label="HR Partner Email"
                  type="text"
                  name="hePartnerEmail"
                  required={true}
                  fullWidth={true}
                  margin="normal"
                  id="hePartnerEmail"
                  value={formik.values.hePartnerEmail}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.hePartnerEmail &&
                    Boolean(formik.errors.hePartnerEmail)
                  }
                  helperText={
                    formik.touched.hePartnerEmail &&
                    formik.errors.hePartnerEmail
                  }
                />
              </Grid>

              <Grid item xs={12} sm={12} md={6}>
                <MyTextInput
                  label="Assistant"
                  type="text"
                  name="assistent"
                  fullWidth={true}
                  margin="normal"
                  id="assistent"
                  value={formik.values.assistent}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.assistent && Boolean(formik.errors.assistent)
                  }
                  helperText={
                    formik.touched.assistent && formik.errors.assistent
                  }
                />
              </Grid>

              <Grid item xs={12} sm={12} md={6}>
                <MyTextInput
                  label="Assistant Email"
                  type="text"
                  name="assistentEmail"
                  fullWidth={true}
                  margin="normal"
                  id="assistentEmail"
                  value={formik.values.assistentEmail}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.assistentEmail &&
                    Boolean(formik.errors.assistentEmail)
                  }
                  helperText={
                    formik.touched.assistentEmail &&
                    formik.errors.assistentEmail
                  }
                />
              </Grid>

              <Grid item xs={12} sm={12} md={6}>
                <MuiAutocomplete
                  name="geography"
                  options={geographyArr}
                  setValue={(name, newValue) =>
                    formik.setFieldValue(name, newValue)
                  }
                  value={formik.values.geography}
                  optionValue="code"
                  optionSecValue="name"
                  label="Geography"
                  required={true}
                  error={
                    formik.touched.geography && Boolean(formik.errors.geography)
                  }
                  helperText={
                    formik.touched.geography && formik.errors.geography
                  }
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <MuiAutocomplete
                  name="country"
                  options={countryArr}
                  setValue={(name, newValue) =>
                    formik.setFieldValue(name, newValue)
                  }
                  value={formik.values.country}
                  optionValue="name"
                  label="Country"
                  required={true}
                  error={
                    formik.touched.country && Boolean(formik.errors.country)
                  }
                  helperText={formik.touched.country && formik.errors.country}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <MyTextInput
                  label="City/Location"
                  type="text"
                  name="location"
                  fullWidth={true}
                  margin="normal"
                  required={true}
                  id="location"
                  value={formik.values.location}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.location && Boolean(formik.errors.location)
                  }
                  helperText={formik.touched.location && formik.errors.location}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={6}>
                <MyTextInput
                  label="Offering Type"
                  type="text"
                  name="offeringType"
                  fullWidth={true}
                  margin="normal"
                  // required={true}
                  id="offeringType"
                  value={formik.values.offeringType}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.offeringType &&
                    Boolean(formik.errors.offeringType)
                  }
                  helperText={
                    formik.touched.offeringType && formik.errors.offeringType
                  }
                />
              </Grid>

              <Grid item xs={12} sm={12} md={12}>
                <MyTextInput
                  label="Reason for Coaching/Mentoring"
                  type="text"
                  name="reason"
                  required={true}
                  fullWidth={true}
                  margin="normal"
                  id="reason"
                  value={formik.values.reason}
                  onChange={formik.handleChange}
                  error={formik.touched.reason && Boolean(formik.errors.reason)}
                  helperText={formik.touched.reason && formik.errors.reason}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={12}>
                <MyTextInput
                  label="Comments"
                  type="text"
                  name="comments"
                  fullWidth={true}
                  margin="normal"
                  id="comments"
                  value={formik.values.comments}
                  onChange={formik.handleChange}
                />
              </Grid>
            </Grid>
          </div>
          <Stack sx={{ marginTop: "0.5rem" }}>
            <FormSubmitButton type="submit" variant="contained">
              Submit
            </FormSubmitButton>
          </Stack>
        </Box>
        {/* <Box
          sx={{ ...style, width: 700 }}
          component="form"
          noValidate
          onSubmit={formik.handleSubmit}
        >
          <ModalCloseBtn close={closeModel} />
          <div style={{ width: "40rem" }}>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={12} md={6}>
                <MyTextInput
                  label="Leader Name"
                  type="text"
                  name="leaderName"
                  required={true}
                  fullWidth={true}
                  margin="normal"
                  id="leaderName"
                  value={formik.values.leaderName}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.leaderName && Boolean(formik.errors.leaderName)
                  }
                  helperText={formik.touched.leaderName && formik.errors.leaderName}
                />
                <FormControl  fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Geography
                  </InputLabel>
                  <MuiAutoComplete
                    name="continentValue"
                    options={geographyArr}
                    setValue={(name, newValue) => setContinentValue(newValue)}
                    value={continentValue}
                    optionValue="name"

                  
                  />
                

                  <MuiAutoComplete
                    name="countryValue"
                    options={countryValArr}
                    setValue={(name, newValue) => setCountryValue(newValue)}
                    value={countryValue}
                    optionValue="name"

                   
                  />
                 
                </FormControl>
                <Stack  sx={{marginTop:"0.5rem"}}>
                <MyTextInput
                 
                  label="Leader Email Id"
                  type="text"
                  name="leaderEmail"
                  required={true}
                  fullWidth={true}
                  margin="normal"
                  id="leaderEmail"
                  value={formik.values.leaderEmail}
                  onChange={formik.handleChange}
                  tabIndex={1}
                  error={
                    formik.touched.leaderEmail && Boolean(formik.errors.leaderEmail)
                  }
                  helperText={
                    formik.touched.leaderEmail && formik.errors.leaderEmail
                  }
                />
                </Stack>
                <div style={{ display: "flex" }}>
                  <div style={{ width: "35%", paddingRight: "0.5rem" }}>
                    <FormControl fullWidth>
                      <MyTextInput
                         InputLabelProps={{ shrink: true }}
                        label="Dialing Code"
                        type="text"
                        name="execDialing"
                        required={true}
                        fullWidth={true}
                        margin="normal"
                        id="execDialing"
                        value={countryValue?.phone}
                      
                        tabIndex={1}
                      />
                     
                    </FormControl>
                  </div>
                  <div style={{ width: "65%" }}>
                    <MyTextInput
                      label="Leader Mobile No."
                      type="number"
                      name="execMobileNo"
                      required={true}
                      fullWidth={true}
                      margin="normal"
                      id="execMobileNo"
                      value={formik.values.execMobileNo}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.execMobileNo &&
                        Boolean(formik.errors.execMobileNo)
                      }
                      helperText={
                        formik.touched.execMobileNo &&
                        formik.errors.execMobileNo
                      }
                    />
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <MyTextInput
                  label="Manager Name"
                  type="text"
                  name="managerName"
                  required={true}
                  fullWidth={true}
                  margin="normal"
                  id="managerName"
                  value={formik.values.managerName}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.managerName &&
                    Boolean(formik.errors.managerName)
                  }
                  helperText={
                    formik.touched.managerName && formik.errors.managerName
                  }
                />
                <MyTextInput
                  label="Manager Email"
                  type="text"
                  name="managerEmail"
                  required={true}
                  fullWidth={true}
                  margin="normal"
                  id="managerEmail"
                  value={formik.values.managerEmail}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.managerEmail && Boolean(formik.errors.managerEmail)
                  }
                  helperText={
                    formik.touched.managerEmail && formik.errors.managerEmail
                  }
                />

                <div style={{ display: "flex",marginTop:"0.5rem" }}>
                  <div style={{ width: "35%", paddingRight: "0.5rem", }}>
                    <FormControl fullWidth>
                      <MyTextInput
                         InputLabelProps={{ shrink: true }}
                        label="Dialing Code"
                        type="text"
                        name="managerDialingCode"
                        required={true}
                        fullWidth={true}
                        margin="normal"
                        id="managerDialingCode"
                        value={countryValue?.phone}
                      
                      />
                     
                    </FormControl>
                  </div>
                  <div style={{ width: "65%" }}>
                    <MyTextInput
                      label="Manager Mobile No."
                      type="number"
                      name="managerContactNo"
                      required={true}
                      fullWidth={true}
                      margin="normal"
                      id="managerContactNo"
                      value={formik.values.managerContactNo}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.managerContactNo &&
                        Boolean(formik.errors.managerContactNo)
                      }
                      helperText={
                        formik.touched.managerContactNo &&
                        formik.errors.managerContactNo
                      }
                    />
                  </div>
                </div>

                <MyTextInput
                 
                  label="Department"
                  type="text"
                  name="department"
                  required={true}
                  fullWidth={true}
                  margin="normal"
                  id="department"
                  value={formik.values.department}
                  onChange={formik.handleChange}
                  tabIndex={1}
                  error={
                    formik.touched.department &&
                    Boolean(formik.errors.department)
                  }
                  helperText={
                    formik.touched.department && formik.errors.department
                  }
                />
                 <MyTextInput
                  label="Comments"
                  type="text"
                  name="comment"
                 
                  fullWidth={true}
                  margin="normal"
                  id="comment"
                  value={formik.values.comment}
                  onChange={formik.handleChange}
                 
                />
              </Grid>
            </Grid>
          </div>

          <FormSubmitButton
            type="submit"
            variant="contained"
            // className={classes.btnSubmit}
          >
            Submit
          </FormSubmitButton>
        </Box> */}
      </PopUpModel>

      {errMessage && <ErrorNotifier message={errMessage} />}
      {message && <Notifier message={message} />}
    </Layout>
  );
};

export default HrLeader;
