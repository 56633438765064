import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { Box, Grid, Stack, Divider, Typography, Button } from "@mui/material";
import { makeStyles } from "@mui/styles";

// components

import Layout from "../../../layout/Layout";
import { MuiDataGrid, GridCellExpand } from "../../../components";
import { PageHeader } from "@excoleadershipui/pionweblib";
import EngagementDetails from "./keyEngagementDetails";
import { format } from "date-fns";
import { useNavigate } from "react-router-dom";

//redux
import {
  getClientForCHR,
  rowSelect,
  getAllEngHistory,
  getActiveEngOnly,
  getActiveLastSixMo,
  getActiveLastTwelveMo,
} from "../../../features/chr/assesment/ChrAssesmentSlice";
// import {
//   getEngagementAccount,
//   getMentorMappedAccount,
//   getMentorMappedLatestClients,
//   rowSelect,
// } from "../../features/dashboard/dashBoardSlice";
// import { getAllKeyClientEngagementStages } from "../../features/engagement/engagementSlice";
import { useSelector, useDispatch } from "react-redux";
import {ListItemButton}from "@mui/material";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
    padding: "0px",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      flexDirection: "column",
    },
  },
  subChildOne: {
    width: "100%",
  },

  subChildTwo: {
    width: "100%",
    height: "25rem",
    marginLeft: "1rem",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      height: "25rem",
      margin: "1rem 0 1rem 0",
    },
  },
}));
export default function Dashboard() {
  const [showDetails, setShowDetails] = useState(false);
  const [showTopBar, setShowTopBar] = useState(true);
  const [clientId, setClientId] = useState(localStorage.getItem("clientid"));
  const {
    loading,
    message,
    errMessage,
    mentorEngagementData,
    mentorAccountData,
    latestTopClients,
  } = useSelector((state) => ({
    ...state.dashboardMappedMentor,
  }));

  const { getAllClientData, selectedData } = useSelector((state) => ({
    ...state.CHrClientLeadership,
  }));

  const dispatch = useDispatch();
  const navigate = useNavigate();

  function getCustomerAccount(params) {
    return `${params.row.excoCustomerUser.excoAccount.accountName}`;
  }

  function getClientAccount(params) {
    return `${params.row.clientMentorMapping?.excoCustomerUser?.excoCustomerUserDetail?.firstName}
    ${params.row.clientMentorMapping?.excoCustomerUser?.excoCustomerUserDetail?.lastName}
    `;
  }

  const getEngagementDate = (params) => {
    return params.row.engagementStartDate
      ? format(new Date(params.row.engagementStartDate), "MM-dd-yyyy")
      : "";
  };

  const getEngagementEndDate = (params) => {
    return params.row.engagementEndDate
      ? format(new Date(params.row.engagementEndDate), "MM-dd-yyyy")
      : "";
  };

  const getEngagementUpdatedDue = (params) => {
    return params.row.nextUpdateDue
      ? format(new Date(params.row.nextUpdateDue), "MM-dd-yyyy")
      : "";
  };
  const getMentorCoach =(params) => {
    return `${params.row.clientMentorMapping?.excoMentor?.excoMentorDetail?.firstName}
    ${params.row.clientMentorMapping?.excoMentor?.excoMentorDetail?.lastName}`;
  }

const getOfferingType =(params)=> {
  return `${params.row.engTierName}`;
}

  function renderCellExpand(params) {
    return (
      <GridCellExpand
        value={params.value || ""}
        width={params.colDef.computedWidth}
      />
    );
  }

  const dataTwo = {
    rows: getAllClientData,
    columns: [
      {
        field: "client",
        headerName: "Leader Name",
        width: 180,
        valueGetter: getClientAccount,
        renderCell: (params) => {
          return (
            <>
              <span
                style={{
                  textDecoration: "underline",
                  color: "blue",
                  cursor: "pointer",
                }}
                onClick={() => {
                  localStorage.setItem(
                    "clientid",
                    JSON.stringify({
                      id: params.row.clientMentorMapping?.excoCustomerUser?.id,
                      mentorId: params.row.clientMentorMapping?.excoMentor?.id,
                      engagementId : params?.row?.id,
                      mentorName: `${params.row.clientMentorMapping?.excoMentor?.excoMentorDetail?.firstName}${" "}${params.row.clientMentorMapping?.excoMentor?.excoMentorDetail?.lastName}`,
                      firstName:
                        params.row.clientMentorMapping?.excoCustomerUser
                          ?.excoCustomerUserDetail?.firstName,
                      lastName:
                        params.row.clientMentorMapping?.excoCustomerUser
                          ?.excoCustomerUserDetail?.lastName,
                      accountName:
                        params.row.clientMentorMapping?.excoCustomerUser
                          ?.excoAccount?.accountName,
                      engagementStartDate: params.row.engagementStartDate,
                    })
                  );
                  navigate("/exco/chr/engagement-details");
                }}
              >
                {
                  params.row.clientMentorMapping?.excoCustomerUser
                    ?.excoCustomerUserDetail?.firstName
                }{" "}
                {
                  params.row.clientMentorMapping?.excoCustomerUser
                    ?.excoCustomerUserDetail?.lastName
                }
              </span>
            </>
          );
        },
      },

      { 
        field: "excoMentorDetail", 
        headerName: "Mentor/Coach", 
        width: 200,
        valueGetter: getMentorCoach,
      },
      { 
        field: "engTierName", 
        headerName: "Offering Type", 
        width: 200,
        valueGetter: getOfferingType,
      },

      // { field: "engagementStatus", 
      //   headerName: "Stage", 
      //   width: 180 
      // },
      { 
        field: "engagementStatus",
        headerName: "Stage",
        width: 180,
        valueGetter: (params) => {
          // Check if engagementStatus contains "Pending Start"
          const status = params.row.engagementStatus || "";
          return status.startsWith("Pending Start") ? "Pending Start" : status;
        },
      },
      {
        field: "engagementStartDate",
        headerName: "Launch/Start Date",
        width: 180,
        valueGetter: getEngagementDate,
      },

      {
        field: "engagementEndDate",
        headerName: "Renewal/End Date",
        width: 180,
        valueGetter: getEngagementEndDate,
      },

      {
        field: "nextUpdateDue",
        headerName: "Next Update Due",
        width: 200,
        valueGetter: getEngagementUpdatedDue,
      },
    ],
  };

  const data = {
    rows: mentorAccountData,
    columns: [
      {
        field: "accountName",
        headerName: "Account Name",
        width: 200,
        valueGetter: getCustomerAccount,
      },
    ],
  };

  // useEffect(() => {
  //   dispatch(getEngagementAccount());
  //   dispatch(getMentorMappedAccount());
  //   dispatch(getAllKeyClientEngagementStages());

  // }, []);

  // useEffect(() => {
  //   clientId && setShowDetails(true);

  // }, []);

  const classes = useStyles();

  const fetchAllEngHistory = () => {dispatch(getClientForCHR())};
  const fetchActiveEngOnly = () => {dispatch(getActiveEngOnly())};
  const fetchActiveSixMo = () => {dispatch(getActiveLastSixMo())};
  const fetchActiveTwelveMo = () => {dispatch(getActiveLastTwelveMo())};

  useEffect(() => {
    dispatch(getClientForCHR());
  }, []);

     const {
       getAllRolesData,
       loggedIn,
       rolesData,
       rolesApiData,
       localStoreRoleData,
     } = useSelector((state) => ({
       ...state.auth,
     }));
     
     const [selected, setSelected] = useState("allEngHistory");

     const handleClick = (key, action) => {
       setSelected(key); // Update the selected key
       action(); // Call the associated action
     };
   
     const boxes = [
       { key: "allEngHistory", label: "All Eng History", action: fetchAllEngHistory },
       { key: "activeEngOnly", label: "Active Eng Only", action: fetchActiveEngOnly },
       { key: "activeSixMo", label: "Active Last 6 Mo", action: fetchActiveSixMo },
       { key: "activeTwelveMo", label: "Active Last 12 Mo", action: fetchActiveTwelveMo },
     ];
   
  return (
    <Layout show={showTopBar}>
      <Stack sx={{ marginTop: "6rem" }}>
        <PageHeader variant="pageHeader">All Engagements</PageHeader>
        <Stack
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            marginBottom: "1rem",
          }}
        >
          <Link to="/exco/chr/nomination">
            <Button sx={{ textTransform: "none", }} variant="contained">Manage Nominations</Button>
          </Link>
        </Stack>
        <Stack >
        <span style={{fontWeight:"bold",fontSize:"1.625rem"}}>Hi {rolesApiData?.getExcoCustomerUserDetail?.firstName},</span>
        <span style={{marginTop:"1.25rem",marginBottom:"1.875rem",fontSize:"1.125rem"}}>Welcome to your ExCo engagements overview! Click on a leader's name to view details about their engagement.</span>
        </Stack>

        <Stack >
        <Grid sx={{marginBottom:"2rem"}} container spacing={3}>
          <Grid sx={{marginTop:"0.5rem"}} item xs={12} sm={12} md={0.8}> 
        <span style={{fontWeight:"bold",fontSize:"1.125rem"}}>Filter: </span>
        </Grid>
            {/* <Grid item xs={12} sm={12} md={2}>
                <ListItemButton
                  style={{
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: "0.4rem",
                    fontWeight:"bold",
                    fontSize:"1rem",
                    border: "1px solid black",
                  }}
                  onClick={fetchAllEngHistory}
                >
                   All Eng History
                </ListItemButton>
            </Grid>

            <Grid item xs={12} sm={12} md={2}>
                <ListItemButton
                  style={{
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: "0.4rem",
                    fontWeight:"bold",
                    fontSize:"1rem",
                    border: "1px solid black",
                  }}
                  onClick={fetchActiveEngOnly}

                >
                  Active Eng Only
                </ListItemButton>
            </Grid>

               <Grid item xs={12} sm={12} md={2}>
                  <ListItemButton
                    style={{
                      justifyContent: "center",
                      alignItems: "center",
                      borderRadius: "0.4rem",
                      fontWeight:"bold",
                      fontSize:"1rem",
                      border: "1px solid black",
                    }}
                    onClick={fetchActiveSixMo}
                  >
                    Active Last 6 Mo
                  </ListItemButton>
              </Grid>

              <Grid item xs={12} sm={12} md={2}>
                  <ListItemButton
                    style={{
                      justifyContent: "center",
                      alignItems: "center",
                      borderRadius: "0.4rem",
                      fontWeight:"bold",
                      fontSize:"1rem",
                      border: "1px solid black",
                    }}
                    onClick={fetchActiveTwelveMo}
                  >
                    Active Last 12 Mo
                    </ListItemButton>
              </Grid>
          </Grid> */}
             {boxes.map((box) => (
               <Grid item xs={12} sm={12} md={2} key={box.key}>
                 <ListItemButton
                    style={{
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: "0.3rem",
                    fontWeight: "bold",
                    fontSize: "1rem",
                    border: "1px solid black",
                    backgroundColor: selected === box.key ? "#696969" : "white",
                    color: selected === box.key ? "white" : "black",
                  }}
                    onClick={() => handleClick(box.key, box.action)}
                    >
                    {box.label}
                 </ListItemButton>
               </Grid>
              ))}
             </Grid>
        </Stack>

        <MuiDataGrid
          // select={(data) => {
          //   dispatch(rowSelect(data));
          // }}
          viewAdd={false}
          data={dataTwo}
        />
      </Stack>
    </Layout>
  );
}
