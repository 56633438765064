import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

// routes
import {ADD_NOMINATION_CREATION_CHR, GET_NOMINATION_CHR ,  GET_REPORTS_AREA_FOCUS, GET_REPORTS_ENGAGEMENT_STATUS,GET_ENGAGEMENT_DETAIL,DELETE_NOMINATION_CREATION_CHR} from "../../../utils/routes";

// ---------------------------------------------------------------------



export const getAreaOfFocus = createAsyncThunk(
  "reports/getAreaOfFocus",
  async () => {
    return axios.get(GET_REPORTS_AREA_FOCUS, { withCredentials: true });
  }
);


export const getNominationCHR = createAsyncThunk(
  "hr/getNomination",
  async () => {
    return axios.get(GET_NOMINATION_CHR, { withCredentials: true });
  }
);

export const createNominationDetail = createAsyncThunk(
  "hr/nomination",
  async (data, {rejectWithValue}) =>{
    console.log("inside")
 try{
   const response = await axios.post(ADD_NOMINATION_CREATION_CHR,data,{withCredentials:true})
   return response
 }
 catch(err){
  console.log(err.response.data)
  return rejectWithValue(err.response.data)
 }
  }
)

export const uploadNominationDocuments = createAsyncThunk(
  "hr/nominationDocumentsUpload",
  async (data, {rejectWithValue}) =>{
    console.log("inside")
 try{
   const response = await axios.post(ADD_NOMINATION_CREATION_CHR,data,{withCredentials:true})
   return response
 }
 catch(err){
  console.log(err.response.data)
  return rejectWithValue(err.response.data)
 }
  }
)

export const getNominationDocument = createAsyncThunk(
  "hr/getNominationDocuments",
  async () => {
    return axios.get(GET_NOMINATION_CHR, { withCredentials: true });
  }
);


export const updateNominationDetail = createAsyncThunk(
  "hr/updateNomination",
async (data, {rejectWithValue}) =>{
    console.log("inside")
 try{
   const response = await axios.put(ADD_NOMINATION_CREATION_CHR,data,{withCredentials:true})
   return response
 }
 catch(err){
  return rejectWithValue(err.response.data)
 }
  }
)


export const deleteNominationDetail = createAsyncThunk(
  "nomination/deleteDetail",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.delete(DELETE_NOMINATION_CREATION_CHR, {
        data,
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);


export const updateEnagagementDetail = createAsyncThunk(
  "engagement/updateEngagement",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.put( GET_ENGAGEMENT_DETAIL, data, {
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);





export const deleteEngagementDetail = createAsyncThunk(
  "customer/deleteCustomer",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.delete(GET_ENGAGEMENT_DETAIL, {
        data,
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

const  nominationSlice = createSlice({
  name: "chrNomination",
  initialState: {
    loading: false,
    message: "",
    errMessage: "",
    nominationData: [],
    nominationDocumentData:[],
    engagementFocusData:[],
    selectedData: {},
  },
  reducers: {
    resetMessage: (state) => {
      state.message = "";
    },
    resetErrMessage: (state) => {
      state.errMessage = "";
    },
    rowSelect: (state, action) => {
      state.selectedData = action?.payload.length > 0 && action?.payload[0];
    },

    emptyRowSelect:(state,action) =>{
      state.selectedData = {}
    }
  
  },
  extraReducers: {

    [deleteNominationDetail.pending]: (state, action) => {
      state.loading = true;
    },
    [deleteNominationDetail.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [deleteNominationDetail.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },
    [createNominationDetail.pending]: (state, action) => {
      state.loading = true;
    },
    [createNominationDetail.fulfilled]: (state, action) => {
      console.log(action?.payload?.data?.message)
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [createNominationDetail.rejected]: (state, action) => {
      console.log(action)
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },

    [uploadNominationDocuments.pending]: (state, action) => {
      state.loading = true;
    },
    [uploadNominationDocuments.fulfilled]: (state, action) => {
      console.log(action?.payload?.data?.message)
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [uploadNominationDocuments.rejected]: (state, action) => {
      console.log(action)
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },

    [getNominationDocument.pending]: (state, action) => {
    
      state.loading = true;
    },
    [getNominationDocument.fulfilled]: (state, action) => {
    
      state.loading = false;
      state.nominationData = action?.payload?.data;
    },
    [getNominationDocument.rejected]: (state, action) => {
      
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },


    [updateNominationDetail.pending]: (state, action) => {
      state.loading = true;
    },
    [updateNominationDetail.fulfilled]: (state, action) => {
      console.log(action?.payload?.data?.message)
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [updateNominationDetail.rejected]: (state, action) => {
      console.log(action?.payload?.message)
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },
    [getNominationCHR.pending]: (state, action) => {
      console.log("No")
      state.loading = true;
    },
    [getNominationCHR.fulfilled]: (state, action) => {
      console.log(action?.payload?.data)
      state.loading = false;
      state.nominationData = action?.payload?.data;
    },
    [getNominationCHR.rejected]: (state, action) => {
      console.log("yes")
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },
  
  },
});




export const { resetMessage, resetErrMessage, rowSelect, emptyRowSelect } = nominationSlice.actions;
export default  nominationSlice.reducer;


