import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

// routes
import {  GET_ASSESMENT_IMPROVEMENT,
  GET_ASSESMENT_STRENGTH,
  GET_ENGAGEMENT_DETAIL,
  APPROVE_ASSESSMENT_STRENGTH,
  APPROVE_ASSESSMENT_IMPROVEMENT,
  GET_APPROVE_BUTTON_HR ,
  GET_APPROVE_BUTTON_MANAGER,
  UPDATE_PRIVATE_LEADERSHIP_IMPROVEMENT,
  UPDATE_LEADERSHIP_IMPROVEMENT,
  UPDATE_LEADERSHIP_STRENGTH,
  UPDATE_PRIVATE_LEADERSHIP_STRENGTH,
  SHARE_WITH_HR,
  SHARE_WITH_MANAGER,
  GET_CLIENT_CORE_VALUES,
  APPROVE_LEADERSHIP_CORE_VALUES,
  UPDATE_PRIVATE_CORE_VALUES,
  GET_LVA,


  UPDATE_PRIVATE_STRENGTH_MULTI_SELECT,
  UPDATE_PUBLIC_STRENGTH_MULTI_SELECT,
  UPDATE_APPROVE_STRENGTH_MULTI_SELECT,


  UPDATE_PRIVATE_IMPROVEMENT_MULTI_SELECT,
  UPDATE_PUBLIC_IMPROVEMENT_MULTI_SELECT,
  UPDATE_APPROVE_IMPROVEMENT_MULTI_SELECT

} from "../../utils/routes";

// ---------------------------------------------------------------------



export const getAssesmentStrength = createAsyncThunk(
  "assesment/getAssesmentStrength",
  async () => {
    return axios.get(GET_ASSESMENT_STRENGTH, { withCredentials: true });
  }
);

export const getAssesmentImprovement = createAsyncThunk(
  "assesment/getImprovement",
  async () => {
    return axios.get( GET_ASSESMENT_IMPROVEMENT, { withCredentials: true });
  }
);

export const getCoreValues = createAsyncThunk(
  "assesment/getCoreValues",
  async () => {
    return axios.get(GET_CLIENT_CORE_VALUES, { withCredentials: true });
  }
);

export const getAssesmentHrButton = createAsyncThunk(
  "assesment/hrButton",
  async () => {
    return axios.get( GET_APPROVE_BUTTON_HR, { withCredentials: true });
  }
);


export const getAssesmentManagerButton = createAsyncThunk(
  "assesment/managerButton",
  async () => {
    return axios.get(GET_APPROVE_BUTTON_MANAGER, { withCredentials: true });
  }
);

export const updateApproveStrength = createAsyncThunk(
  "assessment/approveStrength",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.put(APPROVE_ASSESSMENT_STRENGTH, data, {
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateApproveImprovement = createAsyncThunk(
  "assessment/approveImprovent",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.put( APPROVE_ASSESSMENT_IMPROVEMENT, data, {
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateApproveCoreValues = createAsyncThunk(
  "assessment/updateCoreValues",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.put( APPROVE_LEADERSHIP_CORE_VALUES, data, {
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);


export const updatePrivateStrength = createAsyncThunk(
  "assessment/updatePrivateStrength",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.put( UPDATE_PRIVATE_LEADERSHIP_STRENGTH , data, {
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateStrength = createAsyncThunk(
  "assessment/updateStrength",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.put(UPDATE_LEADERSHIP_STRENGTH , data, {
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);


export const getLvaFileId = createAsyncThunk(
  "assesment/getLvaFile",
  async (data) => {
    return axios.get(`${GET_LVA}`, { withCredentials: true });
  }
);

export const updatePrivateImprovement = createAsyncThunk(
  "assessment/updatePrivateImprovement",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.put(UPDATE_PRIVATE_LEADERSHIP_IMPROVEMENT, data, {
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateImprovement = createAsyncThunk(
  "assessment/updateImprovement",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.put(UPDATE_LEADERSHIP_IMPROVEMENT, data, {
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);


export const updatePrivateCoreValues = createAsyncThunk(
  "assessment/updatePrivateCoreValues",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.put(UPDATE_PRIVATE_CORE_VALUES, data, {
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const shareWithHr = createAsyncThunk(
  "leadership/shareWithHr",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(SHARE_WITH_HR, data, {
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const shareWithManager = createAsyncThunk(
  "leadership/shareWithManager",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(SHARE_WITH_MANAGER, data, {
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);


export const deleteEngagementDetail = createAsyncThunk(
  "customer/deleteCustomer",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.delete(GET_ENGAGEMENT_DETAIL, {
        data,
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
//MULTI SELECET ACTIONS
export const updateStrengthMultiSelectPublic = createAsyncThunk(
  "assessment/updateStrengthMultiSelectPublic",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.put(UPDATE_PUBLIC_STRENGTH_MULTI_SELECT, data, {
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateStrengthMultiSelectPrivate = createAsyncThunk(
  "assessment/updateStrengthMultiSelectPrivate",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.put(UPDATE_PRIVATE_STRENGTH_MULTI_SELECT, data, {
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateStrengthMultiSelectApprove= createAsyncThunk(
  "assessment/updateStrengthMultiSelectPrivate",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.put(UPDATE_APPROVE_STRENGTH_MULTI_SELECT, data, {
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

//

export const updateImprovementMultiSelectPublic = createAsyncThunk(
  "assessment/updateImprovementMultiSelectPublic",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.put(UPDATE_PUBLIC_IMPROVEMENT_MULTI_SELECT, data, {
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateImprovementMultiSelectPrivate = createAsyncThunk(
  "assessment/updateImprovementMultiSelectPrivate",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.put(UPDATE_PRIVATE_IMPROVEMENT_MULTI_SELECT, data, {
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateImprovementMultiSelectApprove= createAsyncThunk(
  "assessment/updateImprovementMultiSelectApprove",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.put(UPDATE_APPROVE_IMPROVEMENT_MULTI_SELECT, data, {
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);





const  engagementSlice = createSlice({
  name: "engagement",
  initialState: {
    loading: false,
    publicPrivateStrengthLoading : false,
    publicPrivateImprovementLoading : false,
    strengthApproveLoading : false,
    improvementApproveLoading : false,
    message: "",
    errMessage: "",
    assesmentButtonHrToggle:{},
    assesmentButtonManagerToggle:{},
    assesmentStrengthData: [],
    assesmentImprovementData:[],
    coreValuesData:[],

    strengthRowSelectedData:[],
    improvementRowSelectedData:[],
    getLvaData:{},
    selectedData: {},
  },
  reducers: {
    resetMessage: (state) => {
      state.message = "";
    },
    resetErrMessage: (state) => {
      state.errMessage = "";
    },
    rowSelect: (state, action) => {
      state.selectedData = action?.payload.length > 0 && action?.payload[0];
    },

    strengthRowFunc : (state,action) =>{
      state.strengthRowSelectedData = action?.payload.length > 0 && action?.payload
    },
    improvemntRowFunc : (state,action) =>{
      state.improvementRowSelectedData = action?.payload.length > 0 && action?.payload
    }
  },
  extraReducers: {

    [updateStrengthMultiSelectPublic.pending]: (state, action) => {
      state.publicPrivateStrengthLoading  = true;
    },
    [updateStrengthMultiSelectPublic.fulfilled]: (state, action) => {
      state.publicPrivateStrengthLoading  = false;
      state.message = action?.payload?.data?.message;
    },
    [updateStrengthMultiSelectPublic.rejected]: (state, action) => {
      state.publicPrivateStrengthLoading  = false;
      state.errMessage = action?.payload?.message;
    },
    [updateStrengthMultiSelectPrivate.pending]: (state, action) => {
      state.publicPrivateStrengthLoading  = true;
    },
    [updateStrengthMultiSelectPrivate.fulfilled]: (state, action) => {
      state.publicPrivateStrengthLoading  = false;
      state.message = action?.payload?.data?.message;
    },
    [updateStrengthMultiSelectPrivate.rejected]: (state, action) => {
      state.publicPrivateStrengthLoading  = false;
      state.errMessage = action?.payload?.message;
    },
    [updateStrengthMultiSelectApprove.pending]: (state, action) => {
      state.publicPrivateStrengthLoading  = true;
    },
    [updateStrengthMultiSelectApprove.fulfilled]: (state, action) => {
      state.publicPrivateStrengthLoading  = false;
      state.message = action?.payload?.data?.message;
    },
    [updateStrengthMultiSelectApprove.rejected]: (state, action) => {
      state.publicPrivateStrengthLoading  = false;
      state.errMessage = action?.payload?.message;
    },

    //
    [updateImprovementMultiSelectPublic.pending]: (state, action) => {
      state.publicPrivateImprovementLoading = true;
    },
    [updateImprovementMultiSelectPublic.fulfilled]: (state, action) => {
      state.publicPrivateImprovementLoading = false;
      state.message = action?.payload?.data?.message;
    },
    [updateImprovementMultiSelectPublic.rejected]: (state, action) => {
      state.publicPrivateImprovementLoading = false;
      state.errMessage = action?.payload?.message;
    },
    [updateImprovementMultiSelectPrivate.pending]: (state, action) => {
      state.publicPrivateImprovementLoading = true;
    },
    [updateImprovementMultiSelectPrivate.fulfilled]: (state, action) => {
      state.publicPrivateImprovementLoading = false;
      state.message = action?.payload?.data?.message;
    },
    [updateImprovementMultiSelectPrivate.rejected]: (state, action) => {
      state.publicPrivateImprovementLoading = false;
      state.errMessage = action?.payload?.message;
    },

    [updateImprovementMultiSelectApprove.pending]: (state, action) => {
      state.publicPrivateImprovementLoading = true;
    },
    [updateImprovementMultiSelectApprove.fulfilled]: (state, action) => {
      state.publicPrivateImprovementLoading= false;
      state.message = action?.payload?.data?.message;
    },
    [updateImprovementMultiSelectApprove.rejected]: (state, action) => {
      state.publicPrivateImprovementLoading = false;
      state.errMessage = action?.payload?.message;
    },


   
    [getAssesmentStrength.pending]: (state, action) => {
      state.loading = true;
    },
    [getAssesmentStrength.fulfilled]: (state, action) => {
     
      state.loading = false;
      state.assesmentStrengthData = action?.payload?.data;
    },
    [getAssesmentStrength.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.data?.message;
    },
    [getLvaFileId.pending]:(state,action)=>{
    state.loading = true
    },
    [getLvaFileId.fulfilled]: (state, action) => {
    
      state.loading = false;
      state.getLvaData = action?.payload?.data;
    },
    [getLvaFileId.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.data?.message;
    },
    [getCoreValues.pending]: (state, action) => {
      state.loading = true;
    },
    [getCoreValues.fulfilled]: (state, action) => {
      
      state.loading = false;
      state.coreValuesData = action?.payload?.data;
    },
    [getCoreValues.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.data?.message;
    },
   
    [getAssesmentHrButton.pending]: (state, action) => {
      state.loading = true;
    },
    [getAssesmentHrButton.fulfilled]: (state, action) => {
     
      state.loading = false;
      state.assesmentButtonHrToggle = action?.payload?.data;
    },
    [getAssesmentHrButton.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.data?.message;
    },
    [getAssesmentImprovement.pending]: (state, action) => {
      state.loading = true;
    },
    [getAssesmentImprovement.fulfilled]: (state, action) => {
    
      state.loading = false;
      state.assesmentImprovementData = action?.payload?.data;
    },
    [getAssesmentImprovement.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.data?.message;
    },
    [getAssesmentManagerButton.pending]: (state, action) => {
      state.loading = true;
    },
    [getAssesmentManagerButton.fulfilled]: (state, action) => {
     
      state.loading = false;
      state.assesmentButtonManagerToggle = action?.payload?.data;
    },
    [getAssesmentManagerButton.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.data?.message;
    },
    [updateApproveStrength.pending]: (state, action) => {
      state.strengthApproveLoading  = true;
    },
    [updateApproveStrength.fulfilled]: (state, action) => {
      state.strengthApproveLoading  = false;
      state.message = action?.payload?.data?.message;
    },
    [updateApproveStrength.rejected]: (state, action) => {
      state.strengthApproveLoading  = false;
      state.errMessage = action?.payload?.message;
    },

    [updateStrength.pending]: (state, action) => {
      state.strengthApproveLoading  = true;
    },
    [updateStrength.fulfilled]: (state, action) => {
      state.strengthApproveLoading  = false;
      state.message = action?.payload?.data?.message;
    },
    [updateStrength.rejected]: (state, action) => {
      state.strengthApproveLoading  = false;
      state.errMessage = action?.payload?.message;
    },


    [updateApproveCoreValues.pending]: (state, action) => {
      state.loading = true;
    },
    [updateApproveCoreValues.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [updateApproveCoreValues.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },
    [updateApproveImprovement.pending]: (state, action) => {
      state.improvementApproveLoading = true;
    },
    [updateApproveImprovement.fulfilled]: (state, action) => {
      state.improvementApproveLoading = false;
      state.message = action?.payload?.data?.message;
    },
    [updateApproveImprovement.rejected]: (state, action) => {
      state.improvementApproveLoading = false;
      state.errMessage = action?.payload?.message;
    },
    [updatePrivateImprovement.pending]: (state, action) => {
      state.improvementApproveLoading = true;
    },
    [updatePrivateImprovement.fulfilled]: (state, action) => {
      state.improvementApproveLoading = false;
      state.message = action?.payload?.data?.message;
    },
    [updatePrivateImprovement.rejected]: (state, action) => {
      state.improvementApproveLoading = false;
      state.errMessage = action?.payload?.message;
    },
    [updatePrivateStrength.pending]: (state, action) => {
      state.loading = true;
    },

    [updateImprovement.fulfilled]: (state, action) => {
      state.improvementApproveLoading = false;
      state.message = action?.payload?.data?.message;
    },
    [updateImprovement.rejected]: (state, action) => {
      state.improvementApproveLoading = false;
      state.errMessage = action?.payload?.message;
    },
    [updateImprovement.pending]: (state, action) => {
      state.loading = true;
    },


    [updatePrivateStrength.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [updatePrivateStrength.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },
    [updatePrivateCoreValues.pending]: (state, action) => {
      state.loading = true;
    },
    [updatePrivateCoreValues.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [updatePrivateCoreValues.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },
    [shareWithHr.pending]: (state, action) => {
      state.loading = true;
    },
    [shareWithHr.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [shareWithHr.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },
    [shareWithManager.pending]: (state, action) => {
      state.loading = true;
    },
    [shareWithManager.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [shareWithManager.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },
    [deleteEngagementDetail.pending]: (state, action) => {
      state.loading = true;
    },
    [deleteEngagementDetail.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [deleteEngagementDetail.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },
  },
});




export const { resetMessage, resetErrMessage, strengthRowFunc ,improvemntRowFunc, rowSelect } = engagementSlice.actions;
export default  engagementSlice.reducer;


