import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

// routes
import {  
  GET_ASSESMENT_IMPROVEMENT,
  GET_ASSESMENT_STRENGTH,
  GET_ENGAGEMENT_DETAIL,
  APPROVE_ASSESSMENT_STRENGTH,
  APPROVE_ASSESSMENT_IMPROVEMENT,
  GET_APPROVE_BUTTON_HR ,
  GET_APPROVE_BUTTON_MANAGER,
  GET_ALL_CLIENTS_CHR,
  GET_CHR_CLIENT_STRENGTS_BY_ID,
  GET_CHR_CLIENT_IMPROVEMENT_BY_ID ,
  GET_CHR_CORE_VALUES_BY_ID,
  All_Eng_History,
  Active_Eng_Only,
  Active_Last_Six_Mo,
  Active_Last_Twelve_Mo,
  GET_ALL_ENG_HISTORY_CLIENTS_CHR
} from "../../../utils/routes";

// ---------------------------------------------------------------------



export const getCHRLeadershipStrength = createAsyncThunk(
  "leadership/getCHRLeadershipStrength",
  async (data) => {
    return axios.get(`${GET_CHR_CLIENT_STRENGTS_BY_ID}/${data}`, { withCredentials: true });
  }
);

export const getCHRLeadershipImprovement = createAsyncThunk(
  "assesment/getImprovement",
  async (data) => {
    return axios.get(`${GET_CHR_CLIENT_IMPROVEMENT_BY_ID}/${data}` , { withCredentials: true });
  }
);

export const getCHRLeadershipCoreValues = createAsyncThunk(
  "leadership/getCoreValues",
  async (data) => {
   
    return axios.get(`${GET_CHR_CORE_VALUES_BY_ID}/${data}` , { withCredentials: true });
  }
);


export const getClientForCHR = createAsyncThunk(
  "leadership/getCHRClient",
  async () => {
    return axios.get(GET_ALL_ENG_HISTORY_CLIENTS_CHR, { withCredentials: true });
  }
);


export const getCHRAssesmentCHRButton = createAsyncThunk(
  "assesment/hrButton",
  async () => {
    return axios.get( GET_APPROVE_BUTTON_HR, { withCredentials: true });
  }
);

export const getAssesmentManagerButton = createAsyncThunk(
  "assesment/managerButton",
  async () => {
    return axios.get(GET_APPROVE_BUTTON_MANAGER, { withCredentials: true });
  }
);

export const getAllEngHistory = createAsyncThunk(
  "leadership/getAllEngHistory",
  async () => {
    return axios.get(All_Eng_History, { withCredentials: true });
  }
);
export const getActiveEngOnly = createAsyncThunk(
  "leadership/getActiveEngOnly",
  async () => {
    return axios.get(Active_Eng_Only, { withCredentials: true });
  }
);
export const getActiveLastSixMo = createAsyncThunk(
  "leadership/getActiveLastSixMo",
  async () => {
    return axios.get(Active_Last_Six_Mo, { withCredentials: true });
  }
);
export const getActiveLastTwelveMo = createAsyncThunk(
  "leadership/getActiveLastSixMo",
  async () => {
    return axios.get(Active_Last_Twelve_Mo, { withCredentials: true });
  }
);

export const updateApproveStrength = createAsyncThunk(
  "assessment/approveStrength",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.put(APPROVE_ASSESSMENT_STRENGTH, data, {
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateApproveImprovement = createAsyncThunk(
  "assessment/approveImprovent",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.put( APPROVE_ASSESSMENT_IMPROVEMENT, data, {
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);


export const updateEnagagementDetail = createAsyncThunk(
  "engagement/updateEngagement",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.put( GET_ENGAGEMENT_DETAIL, data, {
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteEngagementDetail = createAsyncThunk(
  "customer/deleteCustomer",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.delete(GET_ENGAGEMENT_DETAIL, {
        data,
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

const  engagementSlice = createSlice({
  name: "engagement",
  initialState: {
    loading: false,
    message: "",
    errMessage: "",
    assesmentButtonCHRToggle:null,
    assesmentButtonManagerToggle:null,
    leadershipStrengthData: [],
    leadershipImprovementData:[],
    leadershipCoreValuesData:[],
    getAllClientData:[],
    getAllEngHistoryData:[],
    getActiveEngOnlyData:[],
    getActiveLastSixMoData:[],
    getActiveLastTwelveMoData:[],
    selectedData: {},
  },
  reducers: {
    resetMessage: (state) => {
      state.message = "";
    },
    resetErrMessage: (state) => {
      state.errMessage = "";
    },
    rowSelect: (state, action) => {
     
      state.selectedData = action?.payload.length > 0 && action?.payload[0];
    },
  },
  extraReducers: {
   
     [ getClientForCHR.pending]: (state, action) => {
      state.loading = true;
    },
    [ getClientForCHR.fulfilled]: (state, action) => {
     
      state.loading = false;
      state.getAllClientData = action?.payload?.data;
    },
    [ getClientForCHR.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.data?.message;
    },
    [ getCHRAssesmentCHRButton.pending]: (state, action) => {
      state.loading = true;
    },
    [getCHRAssesmentCHRButton.fulfilled]: (state, action) => {
     
      state.loading = false;
      state.assesmentButtonCHRToggle = action?.payload?.data;
    },
    [getCHRAssesmentCHRButton.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.data?.message;
    },
    [ getCHRLeadershipStrength.pending]: (state, action) => {
      state.loading = true;
    },
    [ getCHRLeadershipStrength.fulfilled]: (state, action) => {
      
      state.loading = false;
      state.leadershipStrengthData = action?.payload?.data;
    },
    [getCHRLeadershipStrength.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.data?.message;
    },
    [ getCHRLeadershipImprovement.pending]: (state, action) => {
      state.loading = true;
    },
  [getCHRLeadershipImprovement.fulfilled]: (state, action) => {
     
      state.loading = false;
      state.leadershipImprovementData = action?.payload?.data;
    },
    [getCHRLeadershipImprovement.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.data?.message;
    },
    [ getCHRLeadershipCoreValues.pending]: (state, action) => {
      state.loading = true;
    },
    [getCHRLeadershipCoreValues.fulfilled]: (state, action) => {
      
      state.loading = false;
      state.leadershipCoreValuesData = action?.payload?.data;
    },
    [getCHRLeadershipCoreValues.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.data?.message;
    },
    [ getCHRLeadershipImprovement.pending]: (state, action) => {
      state.loading = true;
    },
    [getAssesmentManagerButton.pending]: (state, action) => {
      state.loading = true;
    },
    [getAssesmentManagerButton.fulfilled]: (state, action) => {
     
      state.loading = false;
      state.assesmentButtonManagerToggle = action?.payload?.data;
    },
    [getAssesmentManagerButton.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.data?.message;
    },

    [ getAllEngHistory.pending]: (state, action) => {
      state.loading = true;
    },
    [ getAllEngHistory.fulfilled]: (state, action) => {
     
      state.loading = false;
      state.getAllClientData = action?.payload?.data;
    },
    [ getAllEngHistory.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.data?.message;
    },
    
    [ getActiveEngOnly.pending]: (state, action) => {
      state.loading = true;
    },
    [ getActiveEngOnly.fulfilled]: (state, action) => {
     
      state.loading = false;
      state.getAllClientData = action?.payload?.data;
    },
    [ getActiveEngOnly.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.data?.message;
    },

    [ getActiveLastSixMo.pending]: (state, action) => {
      state.loading = true;
    },
    [ getActiveLastSixMo.fulfilled]: (state, action) => {
     
      state.loading = false;
      state.getAllClientData = action?.payload?.data;
    },
    [ getActiveLastSixMo.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.data?.message;
    },

    [ getActiveLastTwelveMo.pending]: (state, action) => {
      state.loading = true;
    },
    [ getActiveLastTwelveMo.fulfilled]: (state, action) => {
     
      state.loading = false;
      state.getAllClientData = action?.payload?.data;
    },
    [ getActiveLastTwelveMo.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.data?.message;
    },


    [updateApproveStrength.pending]: (state, action) => {
      state.loading = true;
    },
    [updateApproveStrength.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [updateApproveStrength.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },
    [updateApproveImprovement.pending]: (state, action) => {
      state.loading = true;
    },
    [updateApproveImprovement.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [updateApproveImprovement.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },
    [deleteEngagementDetail.pending]: (state, action) => {
      state.loading = true;
    },
    [deleteEngagementDetail.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [deleteEngagementDetail.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },
  },
});




export const { resetMessage, resetErrMessage, rowSelect } = engagementSlice.actions;
export default  engagementSlice.reducer;


