import { Grid } from "@mui/material";
import React from "react";
 
const GridDataDetails = ({
  title,
  data,
  marginLeft,
  mdt,
  mdc,
  mdd,
  ...others
}) => {
  return (
    <div>
      <Grid spacing={0.5}
        container
        style={{
          // marginLeft: marginLeft ? marginLeft : "2.8rem",
          marginTop: "0.2rem",
          width: "100%",
        }}
      >
        <Grid
          item
          md={mdt ? mdt : 1.6}
          sx={{ width: "100%" }}
          sm={4}
          xs={4}
          className="no_width"
        >
          <span
            style={{
              // fontSize: ".875rem",
              fontSize: "0.92vw",
              fontWeight: "500",
            //   marginLeft: "20%",
            }}
            className="data-inside-content"
          >
            {title}
          </span>
        </Grid>
        <Grid
          item
          md={mdc ? mdc : 0.16}
          sx={{ width: "100%" }}
          sm={1}
          xs={1}
          className="semiColon"
        >
          <span className="label-sm">:</span>
        </Grid>
        <Grid
          item
          md={mdd ? mdd : 9.24}
          sx={{ width: "100%" }}
          sm={5}
          xs={5}
          className="no_width"
        >
          <div style={{ textAlign: "justify" }}>
            <span
              style={{
                // fontSize: ".75rem ",
                fontSize: "0.8vw",
                fontWeight: "400",
                wordWrap: "break-word",
                whiteSpace: "pre-wrap",
              }}
              className="data-inside-content"
            >
              {data}
            </span>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};
 
export default GridDataDetails;