import React, { useEffect, useState } from "react";
import { Outlet, Link } from "react-router-dom";
import io from "socket.io-client";
// materail ui
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import { styled } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import { useNavigate } from "react-router-dom";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import { MICRO_SITE_CUSTOM_TEXT } from "../../utils/config";
import useMediaQuery from "@mui/material/useMediaQuery";
import CloseIcon from "@mui/icons-material/Close";
import moment from "moment";

import {
  Box,
  Toolbar,
  List,
  Divider,
  IconButton,
  FormControl,
  Select,
  Badge,
  MenuItem,
  Menu,
  ListItem,
  Avatar,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  InputLabel,
  Popover,
  Typography,
  Stack,
  Fab,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import DialogContentText from "@mui/material/DialogContentText";
// icons
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import MailIcon from "@mui/icons-material/Mail";
import NotificationsIcon from "@mui/icons-material/Notifications";
import MarkAsUnreadIcon from "@mui/icons-material/MarkAsUnread";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import LogoutIcon from "@mui/icons-material/Logout";

import VerifiedIcon from '@mui/icons-material/Verified';
// components
import logo from "../../assets/images/exco-white-logo-one.png";
import shortLogo from "../../assets/images/signin-logo.png";
import { MuiTooltip } from "../../components";
import Notifier from "../../components/common/Notifier";
import ErrorNotifier from "../../components/common/ErrorNotifier";
import { MainListItems } from "./ListItems";
import { BASE_URL, CLIENT_SERVER_URL } from "../../utils/config";

// redux
import { useSelector, useDispatch } from "react-redux";
import {
  getAllRoles,
  isCheckingRoles,
  logout,
  resetErrMessage,
  resetMessage,
  isLoggedIn,
  localStoreRoleFunc,
  saveToLocalStorage,
  getClientIsApprpved
} from "../../features/auth/authSlice";
import {
  getNotification,
  updateNotification,
  updateNotificationAll,
  resetErrMessage as noticeErrMessage,
  resetMessage as noticeMessage,
} from "../../features/notificationSlice";

// --------------------------------------------------------------------------------

const useStyles = makeStyles((theme) => ({
  MuiLinks: {
    textDecoration: "none",
    color: "inherit",
  },
  closeMenu: {
    [theme.breakpoints.up("md")]: {
      display: "none !important",
      position: "absolute !important",
    },
  },
  MuiFabBtn: {
    position: "fixed !important",
    [theme.breakpoints.down("md")]: {
      display: "none !important",
    },
    top: "1rem",
    left: (open) => (open ? "14rem" : "4rem"),
    background: `${theme.palette.primary.light} !important`,
    "&:hover": {
      background: `${theme.palette.primary.main} !important`,
    },
    color: "white",
    zIndex: "1500 !important",
    width: "1.563rem !important",
    height: "1.563rem !important",
    minHeight: "0.625rem !important",
    transition: (open) =>
      open
        ? theme.transitions.create(["left"], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        })
        : theme.transitions.create(["left"], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.enteringScreen,
        }),
  },
  MuiToolbar: {
    paddingRight: "3rem !important",
  },
}));

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  backgroundColor: "white",
  // width: `calc(100% - ${theme.spacing(7)})`,
  // [theme.breakpoints.up("sm")]: {
  //   width: `calc(100% - ${theme.spacing(10)})`,
  // },
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    // width: `calc(100% - ${drawerWidth}px) !important`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    backgroundColor: theme.palette.sidebarBg.main,
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    height: "100vh",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    ...(!open && {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(10),
      },
    }),
  },
}))

const useStylesTwo = makeStyles((theme) => ({
  menuPaper: {
    overflowX: "scroll"
  },
  menuItem: {
    '&:hover': {
      backgroundColor: 'lightblue',
    },
  },
}));;

const BasicMenu = ({ anchorEl, handleClose, open, data, handleTwoOpen, handleReadAll }) => {
  return (



    <Menu
      PaperProps={{
        style: {
          maxHeight: "80vh", // Example max height, adjust as needed
          // Example width, adjust as needed
          overflowX: "auto", // Enable horizontal scrolling
        },
      }}

      id="basic-menu"
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
    >
      {/* <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <MuiTooltip title="Read All">
          <IconButton onClick={handleReadAll}>
            <VerifiedIcon color="primary" sx={{ fontSize: 26 }} />
          </IconButton>

        </MuiTooltip>
      </div> */}
      {data.length > 0 &&
        data
          .filter((item) => item.isRead === false)
          .map((notif) => {
            const timestamp = new Date(notif.createdOn).toString();
            const formattedDate = moment(timestamp)
              .local()
              .format("DD MMM YYYY HH:mm:ss Z");
            return (
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <MenuItem title={formattedDate}>{notif.title}</MenuItem>
                <div>
                  <MuiTooltip title="Read">
                    <IconButton onClick={() => handleTwoOpen(notif)}>
                      <MarkAsUnreadIcon color="primary" sx={{ fontSize: 26 }} />
                    </IconButton>

                  </MuiTooltip>

                </div>
              </div>

            );
          })}
    </Menu>

  );
};

const SecondBasicMenu = ({
  anchorEl,
  handleClose,
  open,
  data,
  handleIsReadClick,
  handleOpen,
}) => {
  const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialogContent-root": {
      padding: theme.spacing(2),
    },
    "& .MuiDialogActions-root": {
      padding: theme.spacing(1),
    },
  }));

  function BootstrapDialogTitle(props) {
    const { children, onClose, ...other } = props;

    return (
      <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
        {children}
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: "red",
            }}
          >
            <CloseIcon color="red" />
          </IconButton>
        ) : null}
      </DialogTitle>
    );
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
        Notification
      </BootstrapDialogTitle>
      <DialogContent dividers>
        <DialogContentText id="alert-dialog-description">
          {data?.title}
        </DialogContentText>
      </DialogContent>
      {/* <DialogActions>
        <Button onClick={handleClose}  >Disagree</Button>
        <Button onClick={handleIsReadClick} autoFocus>Agree</Button>
      </DialogActions> */}
    </Dialog>
  );
};

function DashboardContent() {
  const [open, setOpen] = React.useState(true);
  const [roleOpen, setRoleOpen] = React.useState(true);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorE2, setAnchorE2] = React.useState(null);
  const [notifyData, setNotifyData] = React.useState({});
  const [notifyTwoOpen, setNotifyTwoOpen] = React.useState(false);
  const [blink, setBlink] = React.useState(false);
  const [notificationOpen, setNotificationOpen] = React.useState(false);
  const [notifyTwoAnchor, setNotifyTwoAnchor] = React.useState(null);
  const [notificationAnchorEl, setNotificationAnchorEl] = React.useState(null);
  const [roleData, setRoleData] = useState("");
  const [roleHoverOpen, setRoleHoverOpen] = React.useState(false);
  const [roleAnchorE1, setRoleAnchorE1] = React.useState(null);
  const [menuToggle, setmenuToggle] = React.useState(false);
  const [time, setTime] = useState(null);

  const classes = useStyles(open);
  const dispatch = useDispatch();

  const {
    getAllRolesData,
    message,
    errMessage,
    loggedIn,
    rolesData,
    rolesApiData,
    localStoreRoleData,
  } = useSelector((state) => ({
    ...state.auth,
  }));

  const { notificationData, messageNot } = useSelector((state) => ({
    ...state.notification,
  }));


  const navigate = useNavigate();

  const toggleDrawer = () => {
    setOpen(!open);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleRoleClick = (event) => {
    setRoleAnchorE1(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleRoleClose = () => {
    setRoleAnchorE1(null);
  };

  const handleIsReadClick = (data) => {
    console.log(notifyData);
    let body = {
      id: data?.id,
    };
    dispatch(updateNotification(body)).then((res) => {
      if (res?.payload?.data?.ok) {
        setTimeout(() => {
          dispatch(noticeMessage());
          // dispatch(getNotification())
          // navigate("/");
        }, 2000);
      }
      if (res?.payload?.error) {
        setTimeout(() => {
          dispatch(noticeErrMessage());
        }, 2000);
      }
    });
  };


  const handleIsReadClickAll = () => {

    dispatch(updateNotificationAll()).then((res) => {
      if (res?.payload?.data?.ok) {
        setTimeout(() => {
          dispatch(noticeMessage());
          dispatch(getNotification())
          // navigate("/");
        }, 2000);
      }
      if (res?.payload?.error) {
        setTimeout(() => {
          dispatch(noticeErrMessage());
        }, 2000);
      }
    });
  };


  const handleClickMenu = (data) => {
    setNotificationOpen(false);
    setNotificationAnchorEl(null);
    setNotifyTwoOpen(true);
    setNotifyData(data);
    // let body = {
    //   id
    // }
    // dispatch(updateNotification(body))
  };

  const handleNotificationTwoClick = (data) => {
    console.log(data);
    setNotifyTwoOpen(true);
    setNotifyData(data);
    handleIsReadClick(data);
  };

  const handleNotificationTwoClose = () => {
    setNotifyTwoOpen(false);
  };

  React.useEffect(() => {
    dispatch(getNotification());
  }, [notifyTwoOpen]);

  const handleMouseOverClose = () => {
    setAnchorE2(null);
  };

  const handleNotificationClick = (event) => {
    setNotificationAnchorEl(event.currentTarget);
    setNotificationOpen(true);
  };

  const handleNotificationClose = () => {
    setNotificationOpen(false);
  };

  const handleLogout = () => {
    dispatch(logout()).then((res) => {
      if (res?.payload?.data?.ok) {
        dispatch(isLoggedIn());
        setTimeout(() => {
          dispatch(resetMessage());
          navigate("/");
          window.location.reload();
        }, 2000);
      }
      if (res?.payload?.error) {
        setTimeout(() => {
          dispatch(resetErrMessage());
        }, 3000);
      }
    });
  };

  useEffect(() => {
    const imageUrl = `${BASE_URL}/authentication/profile-pic`;
    fetch(imageUrl, { credentials: "include" })
      .then((response) => {
        if (response?.ok) {
          setBlink(true);
        } else if (!response?.ok) {
          setBlink(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [rolesApiData]);

  const ProfileAvatar = () => {
    if (blink) {
      return (
        <Avatar
          src={`${BASE_URL}/authentication/profile-pic`}
          sx={{ fontSize: 34 }}
        />
      );
    } else {
      return <Avatar sx={{ fontSize: 34 }} />;
    }
  };

  useEffect(() => {
    localStorage.getItem("state");
  }, [roleData]);

  React.useEffect(() => {
    return <ProfileAvatar />;
  }, []);

  useEffect(() => {
    dispatch(isCheckingRoles());
    dispatch(isLoggedIn());
    dispatch(getNotification());
    dispatch(getClientIsApprpved())
  }, []);

  const handleRoleChange = (e) => {
    if (e === "CLIENT") {
      navigate("/exco/dashboard");
    }
    if (e === "HR") {
      navigate("/exco/hr/dashboard");
    }
    if (e === "CHR") {
      navigate("/exco/chr/dashboard");
    }
    if (e === "MANAGER") {
      navigate("/exco/manager/dashboard");
    }
    localStorage.setItem("state", e);
    setRoleAnchorE1(null);
    dispatch(localStoreRoleFunc(e));
  };

  const matches = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const matches2 = useMediaQuery((theme) => theme.breakpoints.up("sm"));

  React.useEffect(() => {
    if (matches) {
      console.log("big screen not comming");
      setOpen(false);

      setmenuToggle(true);
    } else if (matches2) {
      console.log("big screen");
      setOpen(true);
      setmenuToggle(false);
    }
  }, [matches, matches2]);

  const fullSidebar = () => {
    setmenuToggle(false);
    setOpen(true);
  };

  const clickSideMenu = () => {
    if (matches) {
      setmenuToggle(true);
      setOpen(false);
    }
  };

  useEffect(() => {
    return loggedIn === false && navigate("/");
  }, [loggedIn]);

  React.useEffect(() => {
    const socket = io(CLIENT_SERVER_URL, {
      path: "/socket.io",
      autoConnect: true,
      transports: ["websocket"],
    });

    socket.on("connect", () => {
      console.log("Connected!");
    });

    socket.on("serverTime", (timestamp) => {
      const formattedDate = moment(timestamp)
        .local()
        .format("DD MMM YYYY HH:mm:ss Z");
      setTime(formattedDate);
    });

    socket.on("connect_error", (err) => {
      console.log("Connection Failed", err);
    });

    return () => {
      socket.disconnect();
    };
  }, []);

  const popoverOpen = Boolean(anchorEl);

  const popoverRoleOpen = Boolean(roleAnchorE1);

  const id = open ? "simple-popover" : undefined;

  const roleId = roleOpen ? "simple-popover" : undefined;

  return (
    <Box sx={{ display: "flex" }}>
      <AppBar position="absolute" open={open} sx={{ boxShadow: "none" }}>
        <Toolbar className={classes.MuiToolbar}>
          <Box sx={{ flexGrow: 1 }}>
            <Typography
              sx={{
                color: "#000000",
                textAlign: "center",
                fontSize: "1.5rem",
                marginLeft: "2rem",
              }}
            >
              {MICRO_SITE_CUSTOM_TEXT}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: matches ? "row" : "column",
              marginRight: "1rem",
            }}
          >
            <span style={{ color: "grey", alignSelf: "flex-end" }}>
              PLATFORM TIME
            </span>
            <span style={{ color: "black" }}>{time}</span>
          </Box>
          <MuiTooltip title="Notifications">
            <IconButton
              onClick={handleNotificationClick}
              anchorEl={notificationAnchorEl}
              sx={{ marginRight: "1.5rem" }}
              disabled={
                notificationData.filter((data) => data.isRead === false)
                  .length === 0
              }
            >
              <Badge
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                sx={{ fontSize: 30 }}
                badgeContent={
                  notificationData.filter((data) => data.isRead === false)
                    .length
                }
                color="error"
              >
                <NotificationsIcon sx={{ fontSize: 36 }} />
              </Badge>
            </IconButton>
          </MuiTooltip>
          {notificationData.length && (
            <BasicMenu
              handleOpen={(data) => handleClickMenu(data)}
              handleTwoOpen={(data) => handleNotificationTwoClick(data)}
              handleReadAll={handleIsReadClickAll}
              open={notificationOpen}
              anchorEl={notificationAnchorEl}
              handleClose={handleNotificationClose}
              data={notificationData}
            />
          )}
          <SecondBasicMenu
            handleIsReadClick={handleIsReadClick}
            open={notifyTwoOpen}
            anchorEl={notifyTwoAnchor}
            handleClose={handleNotificationTwoClose}
            data={notifyData}
          />
          <Stack sx={{ display: "flex", flexDirection: "column" }}>
            <MuiTooltip title="User Profile">
              <Box>
                <IconButton
                  sx={{
                    marginRight: "2rem",
                    marginTop: "1rem",
                    paddingBottom: 0,
                    // paddingTop: "0.5rem",
                  }}
                  aria-describedby={id}
                  // onMouseOut={()=> setAnchorEl(null)}
                  onClick={handleClick}
                >
                  <ProfileAvatar />
                </IconButton>
              </Box>
            </MuiTooltip>
            <Typography
              onMouseEnter={
                rolesApiData?.getExcoCustomerUserRoles?.length > 1 &&
                handleRoleClick
              }
              varient="body2"
              sx={{
                color: "black !important",
                cursor: "pointer",
                // marginLeft:"1rem"
              }}
            >
              {Object.keys(rolesApiData).length > 0 &&
                rolesApiData?.getExcoCustomerUserDetail?.firstName[0]?.toUpperCase()}
              {Object.keys(rolesApiData).length > 0 &&
                rolesApiData?.getExcoCustomerUserDetail?.lastName[0]?.toUpperCase()}
              {","}{" "}
              {rolesApiData?.getExcoCustomerUserRoles?.length === 1
                ? (rolesApiData.getExcoCustomerUserRoles[0].role === "CLIENT" &&
                  "Client") ||
                (rolesApiData.getExcoCustomerUserRoles[0].role === "HR" &&
                  "HR") ||
                (rolesApiData.getExcoCustomerUserRoles[0].role === "MANAGER" &&
                  "Manager") ||
                (rolesApiData.getExcoCustomerUserRoles[0].role === "CHR" &&
                  "CHR")
                : localStorage.getItem("state")}
            </Typography>
          </Stack>
        </Toolbar>
      </AppBar>
      {/* {!menuToggle && (
        <Drawer variant="permanent" open={open}>
          <Toolbar
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              px: [1],
            }}
          >
            <CloseIcon
              style={{ color: "white" }}
              onClick={clickSideMenu}
              className={classes.closeMenu}
            />
            <Box
              edge="start"
              aria-label="open drawer"
              sx={{
                flexGrow: 1,
                textAlign: "start",
                ...(open && { display: "none" }),
              }}
            >
              <img alt="shortLogo" src={shortLogo} width="40" height="40" />
            </Box>
            <Box
              sx={{
                flexGrow: 1,
                textAlign: "center",
                ...(!open && { display: "none" }),
              }}
            >
              <img src={logo} alt="nav logo" width="120" height="40" />
            </Box>
          </Toolbar>

          <Divider />
          <List component="nav">
            <MainListItems
              clickSideMenu={clickSideMenu}
              roleData={getAllRolesData}
              particularRole={rolesData}
              rolesFinalData={rolesApiData}
              show={open}
            />
          </List>
        </Drawer>
      )}

      {menuToggle && (
        <Drawer variant="permanent" open={open}>
          <Toolbar
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              px: [1],
            }}
          >
            <Box>
              <MenuIcon style={{ color: "white" }} onClick={fullSidebar} />
            </Box>
          </Toolbar>
        </Drawer>
      )} */}
      <Outlet />
      <Popover
        id={roleId}
        open={popoverRoleOpen}
        anchorEl={roleAnchorE1}
        onClose={handleRoleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "start",
        }}
      >
        {Object.keys(rolesApiData).length > 0 &&
          rolesApiData?.getExcoCustomerUserRoles?.length > 0 &&
          rolesApiData?.getExcoCustomerUserRoles.map((data) => {
            return (
              <ListItem sx={{ width: "150px" }} disablePadding>
                <ListItemButton onClick={() => handleRoleChange(data.role)}>
                  <ListItemText primary={data.role} />
                </ListItemButton>
              </ListItem>
            );
          })}

        {/* <FormControl sx={{ width: "10rem",marginTop:"0.5rem" }} fullWidth>
                            <InputLabel id="demo-simple-select-label">
                              Roles
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              name="country"
                              value={roleData}
                              label="ROLES"
                              onChange={handleRoleChange}
                            >
                               { Object.keys(rolesApiData).length > 0   && rolesApiData?.getExcoCustomerUserRoles?.length > 0 &&
                                 rolesApiData?.getExcoCustomerUserRoles.map((data)=>{
                                return(
                                  <MenuItem key={data.id} value={data.role}>
                                  {data.role}
                                  </MenuItem>
                                )
                              })}
                            </Select>
            </FormControl> */}
      </Popover>

      <Popover
        id={id}
        open={popoverOpen}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
      >
        <Link to="/exco/my-profile" className={classes.MuiLinks}>
          <ListItem disablePadding>
            <ListItemButton onClick={() => handleClose()}>
              <ListItemIcon>
                <AssignmentIndIcon />
              </ListItemIcon>
              <ListItemText primary="My Profile" />
            </ListItemButton>
          </ListItem>
        </Link>
        <List>
          {/* <Link className={classes.MuiLinks}> */}
          <ListItem disablePadding>
            <ListItemButton onClick={() => handleLogout()}>
              <ListItemIcon>
                <LogoutIcon />
              </ListItemIcon>
              <ListItemText primary="Logout" />
            </ListItemButton>
          </ListItem>
          {/* </Link> */}
        </List>
      </Popover>
      {/* {open ? (
       
        <Fab onClick={toggleDrawer} className={classes.MuiFabBtn}>
          <ChevronLeftIcon />
        </Fab>
      ) : (
        
        <Fab onClick={toggleDrawer} className={classes.MuiFabBtn}>
          <ChevronRightIcon />
        </Fab>
        
      )} */}

      {message && <Notifier message={message} />}
      {/* {messageNot && <Notifier message={messageNot}/>} */}
      {errMessage && <Notifier message={errMessage} />}
    </Box>
  );
}

export default function Sidebar() {
  return <DashboardContent />;
}
